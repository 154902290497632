import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import GoodsStockManager from "./GoodsStockManager.vue";
import HotelStockManager from "./HotelStockManager.vue";
import TravelStockManager from "./TravelStockManager.vue";
import { ref, reactive, watch } from "vue";
import useRoute from "@util/router";
export default {
  __name: 'StockManager',

  setup(__props) {
    const route = useRoute();
    const activeName = ref(route.query().tab || "goods");
    watch(() => activeName.value, val => {
      route.clearQuerys("tab", val);
    });
    return (_ctx, _cache) => {
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");

      const _component_el_tabs = _resolveComponent("el-tabs");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_tabs, {
        modelValue: activeName.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => activeName.value = $event)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
          label: "商品库存",
          name: "goods"
        }, {
          default: _withCtx(() => [_createVNode(GoodsStockManager)]),
          _: 1
        }), _createVNode(_component_el_tab_pane, {
          label: "旅行库存",
          name: "travel"
        }, {
          default: _withCtx(() => [_createVNode(TravelStockManager)]),
          _: 1
        }), _createVNode(_component_el_tab_pane, {
          label: "酒店库存",
          name: "hotel"
        }, {
          default: _withCtx(() => [_createVNode(HotelStockManager)]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }

};