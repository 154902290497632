import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "base_screen_header"
};
const _hoisted_2 = {
  class: "base_main_header"
};
import StatusBar from "@com/StatusBar.vue";
import { $msg, $box } from "@ui/msg";
import { ref, reactive } from "vue";
import { Search } from "@element-plus/icons-vue";
import useRoute from "@util/router";
import DataCollectTable from "@com/table/DataCollectTable.vue";
import { OP_KEY, COLUMN_TYPE, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import $ from "@util/base";
import api from "@api/message";
export default {
  __name: 'MessageManage',

  setup(__props) {
    const route = useRoute();
    route.watchRouter("messagemanage", () => {
      getData();
    });
    const statusList = reactive([{
      label: "全部",
      value: "all",
      total: 0
    }, {
      label: "未发送",
      value: "1055001",
      total: 0
    }, {
      label: "已发送",
      value: "1055003",
      total: 0
    }]);
    const data = ref([]);
    const total = ref(0);

    function getOperations(data) {
      switch (data.sendStatus) {
        // 1055001 未发送
        case "1055001":
          return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.push, OPERATIONS.del];

        default:
          return [OPERATIONS.check];
      }
    }

    const config = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: getOperations
      },
      column: [{
        label: "名称",
        prop: "name",
        width: 100
      }, {
        label: "短信类容",
        prop: "templeteName"
      }, {
        label: "发送对象",
        prop: "targetType",
        width: 100
      }, {
        label: "状态",
        prop: "sendStatusName",
        width: 100
      }, {
        label: "创建时间",
        prop: "createTime",
        width: 200
      }, {
        label: "发送时间",
        prop: "startTime",
        width: 200
      }]
    };
    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      route.push({
        name: "messagedetails",
        query: {
          type: "show",
          code: data.id
        }
      });
    });
    opHander.install(OP_KEY.modify, data => {
      route.push({
        name: "messagedetails",
        query: {
          type: "modify",
          code: data.id
        }
      });
    });
    opHander.install(OP_KEY.delete, data => {
      $box.open("删除确认", `是否删除${data.name}短信`, () => {
        api.delete(data.id, () => {
          $msg.succ("删除成功");
          getData();
        });
      });
    });
    opHander.install(OP_KEY.push, data => {
      $box.open("发送确认", `是否发送${data.name}活动`, () => {
        api.send(data.id, () => {
          $msg.succ("发送成功");
          getData();
        });
      });
    });

    function add() {
      route.push({
        name: "messagedetails",
        query: {
          type: "add"
        }
      });
    } // const typeOptions = [
    //     {
    //         label: "商品",
    //         value: "1003001",
    //     },
    //     {
    //         label: "旅行",
    //         value: "1003002",
    //     },
    //     {
    //         label: "酒店",
    //         value: "1003003",
    //     },
    // ];


    const status = ref(route.query().status || "");
    const name = ref(route.query().name || "");

    function searchName(val) {
      route.replaceQuery("name", val);
    }

    getData();

    function getData() {
      getList();
      getNum();
    }

    function getList() {
      let routeQuery = route.query();
      console.log(routeQuery.status);
      api.list({
        name: routeQuery.name,
        status: routeQuery.status,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        total.value = val.total;
        data.value = val.list;
      });
    }

    function getNum() {
      api.num(route.query().name, val => {
        statusList.forEach((e, i) => {
          e.total = val[i];
        });
      });
    }

    function getExport() {
      let routeQuery = route.query();
      api.export({
        name: routeQuery.name,
        status: routeQuery.status,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        $.download(val.url, "短信导出");
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_main = _resolveComponent("el-main");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createBlock(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          onClick: add
        }, {
          default: _withCtx(() => [_createTextVNode("新增营销短信")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('ADD')]]), _createVNode(_component_el_form, {
          inline: true,
          class: "base_search_form",
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: name.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => name.value = $event),
              "onKeyup[native]": searchName,
              onChange: searchName,
              placeholder: "短信名称",
              clearable: "",
              "suffix-icon": _unref(Search)
            }, null, 8, ["modelValue", "suffix-icon"])]),
            _: 1
          })]),
          _: 1
        })]), _createVNode(StatusBar, {
          data: statusList,
          default: "all"
        }, null, 8, ["data"]), _createElementVNode("div", _hoisted_2, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          text: "",
          onClick: getExport
        }, {
          default: _withCtx(() => [_createTextVNode("导出")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('EXPORT')]])]), _createVNode(DataCollectTable, {
          data: data.value,
          config: config,
          total: total.value,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"])]),
        _: 1
      });
    };
  }

};