import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "map_content"
};
import { ref, watch } from "vue";
import $ from "@util/base";
import box from "@ui/messageBox";
export default {
  __name: 'MapSelectAddress',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    position: {
      type: Array
    }
  },
  emits: ['select'],

  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const zoom = ref(18);
    const markerVisible = ref(true);
    const defaultPosition = [121.499718, 31.239703];
    const props = __props;
    const position = ref(defaultPosition);
    watch(() => props.position, val => {
      if (val[0] != null && val[1] != null) {
        position.value = val;
      }
    });

    function clickMarker() {
      if (!props.disabled) {
        box.open("确认选择", curPOI.address || '', () => {
          emit('select', curPOI);
        });
      }
    }

    function clickMap(evt) {
      if (!props.disabled) {
        curPOI = {
          lng: evt.lnglat.lng,
          lat: evt.lnglat.lat,
          address: null
        };
        position.value = [evt.lnglat.lng, evt.lnglat.lat]; // eslint-disable-next-line no-undef

        let geocoder = new AMap.Geocoder({
          city: '全国'
        });
        geocoder.getAddress(position.value, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            console.log(result);
            curPOI.address = result.regeocode.formattedAddress;
          }
        });
      }
    }

    var curPOI = null;

    function movePoi(data) {
      if ($.isNotEmpty(data.poi.location)) {
        markerVisible.value = true;
        position.value = [data.poi.location.lng, data.poi.location.lat];
        curPOI = {
          lng: data.poi.location.lng,
          lat: data.poi.location.lat,
          address: data.poi.district + data.poi.address
        };
      }
    }

    return (_ctx, _cache) => {
      const _component_el_amap_search_box = _resolveComponent("el-amap-search-box");

      const _component_el_amap_marker = _resolveComponent("el-amap-marker");

      const _component_el_amap = _resolveComponent("el-amap");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_amap, {
        center: position.value,
        zoom: zoom.value,
        onClick: clickMap
      }, {
        default: _withCtx(() => [_createVNode(_component_el_amap_search_box, {
          onSelect: movePoi,
          onChoose: movePoi,
          datatype: "poi"
        }), _createVNode(_component_el_amap_marker, {
          visible: markerVisible.value,
          position: position.value,
          onClick: clickMarker
        }, null, 8, ["visible", "position"])]),
        _: 1
      }, 8, ["center", "zoom"])]);
    };
  }

};