// 审批
import GoodsExamine from '@/views/examine/GoodsExamine.vue'
import TravelExamine from '@/views/examine/TravelExamine.vue'
import HotelExamine from '@/views/examine/HotelExamine.vue'
import DepositExamine from '@/views/examine/DepositExamine.vue'
import PointsExamine from '@/views/examine/PointsExamine.vue'
import LineExamine from '@/views/examine/LineExamine.vue'


export default [{
    path: '/goodsexamine',
    name: 'goodsexamine',
    component: GoodsExamine,
    meta: {
        activeMainMenu: 'examine',
        activeSubMenu: '/goodsexamine'
    }
}, {
    path: '/travelexamine',
    name: 'travelexamine',
    component: TravelExamine,
    meta: {
        activeMainMenu: 'examine',
        activeSubMenu: '/travelexamine'
    }
}, {
    path: '/hotelexamine',
    name: 'hotelexamine',
    component: HotelExamine,
    meta: {
        activeMainMenu: 'examine',
        activeSubMenu: '/hotelexamine'
    }
}, {
    path: '/depositexamine',
    name: 'depositexamine',
    component: DepositExamine,
    meta: {
        activeMainMenu: 'examine',
        activeSubMenu: '/depositexamine'
    }
}, {
    path: '/pointsexamine',
    name: 'pointsexamine',
    component: PointsExamine,
    meta: {
        activeMainMenu: 'examine',
        activeSubMenu: '/pointsexamine'
    }
},
    {
        path: '/lineexamine',
        name: 'lineexamine',
        component: LineExamine,
        meta: {
            activeMainMenu: 'examine',
            activeSubMenu: '/lineexamine'
        }
    }
]