import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-4b0ac360"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "gift_code_manager"
};
const _hoisted_2 = {
  class: "base_details_content"
};
const _hoisted_3 = {
  class: "base_screen_header",
  style: {
    "margin-top": "15px"
  }
};
const _hoisted_4 = {
  class: "base_main_header"
};
const _hoisted_5 = {
  class: "dialog-footer"
};
import StatusBar from "@com/StatusBar.vue";
import DataCollectTable from "@com/table/DataCollectTable.vue";
import { $msg, $box } from "@ui/msg";
import { ref, reactive } from "vue";
import api from "@api/giftCode";
import $ from "@util/base";
import useRoute from "@util/router";
export default {
  __name: 'GiftCodeManager',

  setup(__props) {
    const route = useRoute();
    route.watchRouter("giftcodemanager", () => {
      getData();
    }); // 1013001 未核销、1013002 已核销

    const statusList = reactive([{
      label: "全部",
      value: "all",
      total: 0
    }, {
      label: "未核销",
      value: "1013001",
      total: 0
    }, {
      label: "已核销",
      value: "1013002",
      total: 0
    }]);
    const config = {
      showIndex: false,
      column: [{
        label: "编号",
        prop: "itemCode"
      }, {
        label: "礼包码",
        prop: "giftExchangeCode"
      }, {
        label: "发放人员",
        prop: "salesman"
      }, {
        label: "状态",
        prop: "giftStatusVal",
        width: 100
      }, {
        label: "核销人",
        prop: "exchangeBy",
        width: 120
      }, {
        label: "核销时间",
        prop: "exchangeTime",
        width: 150
      }, {
        label: "操作人",
        prop: "creator",
        width: 120
      }, {
        label: "生成时间",
        prop: "createTime",
        width: 150
      }]
    };
    getNum();

    function getNum() {
      api.num(route.query().code, val => {
        numData(val);
      });
    }

    function numData(val) {
      statusList.forEach((e, i) => {
        e.total = val[i];
      });
    }

    const total = ref(0);
    const data = ref([]);
    getData();

    function getData() {
      let routeQuery = route.query();
      let params = {
        giftCode: routeQuery.code,
        status: routeQuery.status,
        page: routeQuery.p,
        size: routeQuery.s
      };
      api.list(params, val => {
        total.value = val.total;
        data.value = val.list.map(e => {
          switch (e.giftExchangeStatus) {
            case "1013001":
              e.giftStatusVal = "未核销";
              break;

            case "1013002":
              e.giftStatusVal = "已核销";
              break;
          }

          return e;
        });
      });
    }

    const dialogVisible = ref(false);
    const giftCodeFormRef = ref(null);
    const accountOptions = ref([]);
    getConfig();

    function getConfig() {
      api.account(val => {
        accountOptions.value = val;
      });
    }

    const giftCodeForm = ref({
      num: null,
      salesman: null
    });
    const giftCodeFormRules = {
      num: [{
        required: true,
        message: '请输入生成数量',
        trigger: 'blur'
      }, {
        type: "number",
        min: 1,
        max: 100,
        message: '每次发放范围在1到100个',
        trigger: 'blur'
      }],
      salesman: [{
        required: true,
        message: '请选择发放人员',
        trigger: 'change'
      }]
    };

    function add() {
      if (giftCodeFormRef.value) {
        giftCodeFormRef.value.resetFields();
      }

      dialogVisible.value = true;
    }

    function importCode() {
      route.push({
        name: 'importGiftCode',
        query: {
          code: route.query().code
        }
      });
    }

    function submitGiftCodeForm() {
      giftCodeFormRef.value.validate(valid => {
        if (valid) {
          api.add(route.query().code, giftCodeForm.value, () => {
            $msg.succ("生成礼包码成功");
            dialogVisible.value = false;
            getNum();
            getData();
          });
        }
      });
    }

    function getExport() {
      let routeQuery = route.query();
      api.export({
        giftCode: routeQuery.code,
        status: routeQuery.status
      }, val => {
        $.download(val.url, "礼包码导出");
      });
    }

    return (_ctx, _cache) => {
      const _component_BackTitleView = _resolveComponent("BackTitleView");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_main = _resolveComponent("el-main");

      const _component_el_footer = _resolveComponent("el-footer");

      const _component_el_container = _resolveComponent("el-container");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, {
        class: "base_details_container"
      }, {
        default: _withCtx(() => [_createVNode(_component_BackTitleView, {
          title: "礼包码管理"
        }), _createVNode(_component_el_main, null, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, [_createVNode(_component_el_button, {
            onClick: add,
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode("生成礼包码")]),
            _: 1
          }), _createVNode(_component_el_button, {
            style: {
              "margin-left": "20px"
            },
            onClick: importCode,
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode("导入礼包码")]),
            _: 1
          })])]), _createVNode(StatusBar, {
            data: statusList,
            default: "all"
          }, null, 8, ["data"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_button, {
            type: "primary",
            text: "",
            onClick: getExport
          }, {
            default: _withCtx(() => [_createTextVNode("导出")]),
            _: 1
          })]), _createVNode(DataCollectTable, {
            data: data.value,
            total: total.value,
            config: config
          }, null, 8, ["data", "total"])])]),
          _: 1
        }), _createVNode(_component_el_footer, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            onClick: _cache[0] || (_cache[0] = $event => _unref(route).go(-1))
          }, {
            default: _withCtx(() => [_createTextVNode("关闭")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => dialogVisible.value = $event),
        title: "生成礼包码",
        width: "35%",
        top: "35vh",
        class: "base_dialog"
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_5, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: submitGiftCodeForm
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: _cache[3] || (_cache[3] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "giftCodeFormRef",
          ref: giftCodeFormRef,
          model: giftCodeForm.value,
          rules: giftCodeFormRules,
          "label-width": "100px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "生成数量",
            prop: "num"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: giftCodeForm.value.num,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => giftCodeForm.value.num = $event),
              modelModifiers: {
                number: true
              },
              type: "number",
              placeholder: "请输入",
              onkeyup: "value=value.replace(/^(0+)|[^\\d]+/g,'')"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "发放人员",
            prop: "salesman"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: giftCodeForm.value.salesman,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => giftCodeForm.value.salesman = $event),
              placeholder: "请选择",
              filterable: ""
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(accountOptions.value, (item, index) => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: index,
                  label: item.label,
                  value: item.value
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }

};