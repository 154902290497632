import {POST} from "@net/service.js";
import $ from "@util/base";
import page from "./page"
import configApi from "@/api/config";

const URL = {
    num: "member_status_num",
    list: "member_list",
    details: "member_detail",
    status: "member_status_change",
    up: "member_up_type",
    type: "member_type_change",
    export: "member_list_export",
    order: "member_distribution_list",
    orderNum: "member_distribution_status_num",
    orderExport: "member_distribution_list_export",
};

// 1009001 普通会员、 1009002 分销会员
const TYPE = {
    membermanager: '1009001',
    distributormanager: '1009002',
}

const api = {
    num(params, succ) {
        let requestOptions = {
            path: URL.num,
            params: params,
            succ: val => {
                succ([val.total, val.normal, val.freeze])
            }
        }
        POST(requestOptions)
    },
    list(type, params, succ) {
        let paramsData = {}
        paramsData.memberType = TYPE[type]
        if ($.isNotEmpty(params.loginName)) {
            paramsData.loginName = params.loginName
        }
        if ($.isNotEmpty(params.nickname)) {
            paramsData.nickname = params.nickname
        }
        if ($.isNotEmpty(params.memberStatus) && params.memberStatus != 'all') {
            paramsData.memberStatus = params.memberStatus
        }
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.list,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    details(code, succ) {
        let requestOptions = {
            path: URL.details,
            params: {memberCode: code},
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    status(code, status, succ, fail) {
        let paramsData = {}
        paramsData.memberCode = code
        paramsData.memberStatus = status
        let requestOptions = {
            path: URL.status,
            params: paramsData,
            succ: succ,
            fail: fail
        }
        POST(requestOptions)
    },
    type(code, succ, fail) {
        let paramsData = {}
        paramsData.memberCode = code
        paramsData.memberType = TYPE.distributormanager
        let requestOptions = {
            path: URL.type,
            params: paramsData,
            succ: succ,
            fail: fail
        }
        POST(requestOptions)
    },
    export(type, params, succ) {
        let paramsData = {}
        paramsData.memberType = TYPE[type]
        if ($.isNotEmpty(params.loginName)) {
            paramsData.loginName = params.loginName
        }
        if ($.isNotEmpty(params.nickname)) {
            paramsData.nickname = params.nickname
        }
        if ($.isNotEmpty(params.memberStatus) && params.memberStatus != 'all') {
            paramsData.memberStatus = params.memberStatus
        }
        let requestOptions = {
            path: URL.export,
            params: paramsData,
            succ: succ
        }
        POST(requestOptions)
    },
    up(code, succ, fail) {
        let requestOptions = {
            path: URL.up,
            params: {memberCode: code},
            succ: succ,
            fail: fail
        }
        POST(requestOptions)
    },
    unbindDistr(code, succ, fail) {
        let paramsData = {}
        paramsData.memberCode = code
        paramsData.memberType = TYPE.membermanager
        let requestOptions = {
            path: URL.type,
            params: paramsData,
            succ: succ,
            fail: fail
        }
        POST(requestOptions)
    },
    order(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.status) && params.status != 'all') {
            paramsData.status = parseInt(params.status)
        } else {
            paramsData.status = 0
        }
        if ($.isNotEmpty(params.memberCode)) {
            paramsData.memberCode = params.memberCode
        }
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.order,
            params: paramsData,
            succ: succ
        }
        POST(requestOptions)
    },
    orderNum(code, succ) {
        let requestOptions = {
            path: URL.orderNum,
            params: {memberCode: code},
            succ: val => {
                succ([val.total, val.afoot, val.complete])
            }
        }
        POST(requestOptions)
    },
    orderExport(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.status) && params.status != 'all') {
            paramsData.status = parseInt(params.status)
        } else {
            paramsData.status = 0
        }
        if ($.isNotEmpty(params.memberCode)) {
            paramsData.memberCode = params.memberCode
        }
        let requestOptions = {
            path: URL.orderExport,
            params: paramsData,
            succ: succ
        }
        POST(requestOptions)
    },
    memberGradeList(succ) {
        configApi.dict("1039", succ)
    },
    memberGradeChange(params, succ) {
        let requestOptions = {
            path: URL.type,
            params: params,
            succ: succ
        }
        POST(requestOptions)
    }
};

export default api
