import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "select_city"
};
import { ref, watch } from "vue";
import api from "@api/config";
export default {
  __name: 'SelectCity',
  props: {
    value: {
      type: String
    }
  },
  emits: ['setCityCode'],

  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const props = __props;
    watch(() => props.value, val => {
      if (val) {
        cityValue.value = [val.substring(0, 2) + '0000000', val];
      }
    });
    const cityValue = ref([]);
    const selectCity = {
      lazy: true,
      lazyLoad: function (node, resolve) {
        const {
          level,
          value
        } = node;
        api.area(value, level + 1, val => {
          resolve(val.map(e => {
            e.leaf = level >= 1;
            return e;
          }));
        });
      }
    };

    function getCity(value) {
      if (value) {
        emit('setCityCode', value[1]);
      } else {
        emit('setCityCode', null);
      }
    }

    return (_ctx, _cache) => {
      const _component_el_cascader = _resolveComponent("el-cascader");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_cascader, {
        modelValue: cityValue.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => cityValue.value = $event),
        props: selectCity,
        "show-all-levels": false,
        onChange: getCity,
        clearable: ""
      }, null, 8, ["modelValue"])]);
    };
  }

};