import {POST} from "@net/service.js";
import page from "@/api/page";

const URL = {
  list: 'album_list',
  add: 'album_add',
  del: 'album_del',
  get: 'album_get',
  update: 'album_update',
  up: 'album_update_way'
};

const api = {
  list(params, succ) {
    let paramsData = {}
    paramsData.page = params.page ? params.page : 1
    paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
    let requestOptions = {
      path: URL.list,
      params: paramsData,
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  },
  add(params, succ) {
    let requestOptions = {
      path: URL.add,
      params: params,
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  },
  get(id, succ) {
    let requestOptions = {
      path: URL.get,
      params: {id: id},
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  },
  update(params, succ) {
    let requestOptions = {
      path: URL.update,
      params: params,
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  },
  del(id, succ) {
    let requestOptions = {
      path: URL.del,
      params: {id: id},
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  },
  up(id, state, succ) {
    let requestOptions = {
      path: URL.up,
      params: {id: id, lineStatus: state},
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)

  }
}

export default api
