import { POST } from "@net/service.js";
import $ from "@util/base";
import page from "./page"

const URL = {
    list: "time_send_list",
    add: "time_send_add",
    clone: "time_send_clone",
    details: "time_send_get",
    update: "time_send_update",
    couponTimingUpdateWay: "time_send_update_way"
};

const api = {
    list(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.name)) {
            paramsData.name = params.name;
        }
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.list,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    add(params, succ) {
        let requestOptions = {
            path: URL.add,
            params: params,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    update(params, succ) {
        let requestOptions = {
            path: URL.update,
            params: params,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    clone(code, succ) {
        let paramsData = {};
        if ($.isNotEmpty(code)) {
            paramsData.code = code;
        }
        let requestOptions = {
            path: URL.clone,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    details(code, succ) {
        let requestOptions = {
            path: URL.details,
            params: { code: code },
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    stop(code, succ) {
        let paramsData = {};
        if ($.isNotEmpty(code)) {
            paramsData.code = code;
        }
        paramsData.enableStatus = "1001002";
        let requestOptions = {
            path: URL.couponTimingUpdateWay,
            params: paramsData,
            succ: succ,
        };
        POST(requestOptions);
    },
    start(code, succ) {
        let paramsData = {};
        if ($.isNotEmpty(code)) {
            paramsData.code = code;
        }
        paramsData.enableStatus = "1001001";
        let requestOptions = {
            path: URL.couponTimingUpdateWay,
            params: paramsData,
            succ: succ,
        };
        POST(requestOptions);
    },
}

export default api;
