import {
    POST
} from '@net/service.js'
import $ from "@util/base"
import page from "./page"
import examineApi from "./examine";

const URL = {
    goodsInputList: 'product_add_list',
    goodsManagerList: 'product_list',
    addGoods: 'product_add',
    updateGoods: 'product_update',
    generateSKU: 'product_sku_add',
    goodsDetails: 'product_get',
    skuDetails: 'product_sku_get',
    skuUpdate: 'product_sku_update',
    skuFlagUpdata: 'product_sku_flag_update',
    managerExport: 'product_list_export',
    inputExport: 'product_add_list_export',
    updateGoodsState: 'product_update_way'
}

const api = {
    managerInput(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.categoryCode) && params.categoryCode != "all") {
            paramsData.categoryCode = params.categoryCode
        }
        if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
            paramsData.shopCode = params.shopCode
        }
        if ($.isNotEmpty(params.productName)) {
            paramsData.productName = params.productName
        }
        paramsData.flowStatusCode = params.status ? parseInt(params.status) : 1
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.goodsInputList,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    managerList(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.categoryCode) && params.categoryCode != "all") {
            paramsData.categoryCode = params.categoryCode
        }
        if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
            paramsData.shopCode = params.shopCode
        }
        if ($.isNotEmpty(params.productName)) {
            paramsData.productName = params.productName
        }
        if (params.isIntegral) {
            paramsData.belongMall = 1066002
        } else {
            paramsData.belongMall = 1066001
        }
        paramsData.productStatus = params.status ? parseInt(params.status) : 1
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.goodsManagerList,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    generateSKU(params, succ) {
        let paramsGoods = {
            id: params.id,
            categoryCode: params.categoryCode,
            productName: params.productName,
            shopCode: params.shopCode,
            mainPic: params.mainPic,
            picList: params.picList,
            descList: params.descList,
            sellUnit: params.sellUnit,
            postageTempleteCode: params.postageTempleteCode,
            limitDeliveryType: params.limitDeliveryType,
            stationIds: params.stationIds,
            tags: params.tags,
            maxBuy: params.maxBuy,
            minBuy: params.minBuy,
            payType: params.payType,
            couponItemList: params.couponItemList
        }
        if (params.isIntegral) {
            paramsGoods.belongMall = 1066002
        } else {
            paramsGoods.belongMall = 1066001
        }
        var url = ""
        if ($.isNotEmpty(paramsGoods.id)) {
            url = URL.updateGoods
        } else {
            url = URL.addGoods
        }
        let requestGoodsOptions = {
            path: url,
            params: paramsGoods,
            succ: goodsResult => {
                let paramsSKU = {productCode: goodsResult.productCode}
                let specs = params.specs
                if ($.isNotNull(specs.skuSaleAttr1) && $.isNotEmpty(specs.skuSaleAttr1Value)) {
                    paramsSKU.skuSaleAttr1 = specs.skuSaleAttr1
                    paramsSKU.skuSaleAttr1Value = specs.skuSaleAttr1Value
                }
                if ($.isNotNull(specs.skuSaleAttr2) && $.isNotEmpty(specs.skuSaleAttr2Value)) {
                    paramsSKU.skuSaleAttr2 = specs.skuSaleAttr2
                    paramsSKU.skuSaleAttr2Value = specs.skuSaleAttr2Value
                }
                let requestSKUOptions = {
                    path: URL.generateSKU,
                    params: paramsSKU,
                    succ: skuResult => {
                        succ(skuResult)
                    }
                }
                POST(requestSKUOptions)
            }
        }
        POST(requestGoodsOptions)
    },
    details(code, succ) {
        let requestGoodsOptions = {
            path: URL.goodsDetails,
            params: {productCode: parseInt(code)},
            succ: goodsResult => {
                let requestSKUOptions = {
                    path: URL.skuDetails,
                    params: {productCode: goodsResult.productCode},
                    succ: skuResult => {
                        goodsResult.sku = skuResult
                        succ(goodsResult)
                    }
                }
                POST(requestSKUOptions)
            }
        }
        POST(requestGoodsOptions)
    },
    updateSKUSubmit(params, succ) {
        let paramsGoodsData = {
            id: params.id,
            categoryCode: params.categoryCode,
            productName: params.productName,
            shopCode: params.shopCode,
            mainPic: params.mainPic,
            picList: params.picList,
            descList: params.descList,
            sellUnit: params.sellUnit,
            postageTempleteCode: params.postageTempleteCode,
            limitDeliveryType: params.limitDeliveryType,
            stationIds: params.stationIds,
            tags: params.tags,
            maxBuy: params.maxBuy,
            minBuy: params.minBuy,
            payType: params.payType,
            couponItemList: params.couponItemList
        }
        if (params.isIntegral) {
            paramsGoodsData.belongMall = 1066002
        } else {
            paramsGoodsData.belongMall = 1066001
        }
        let requestGoodsOptions = {
            path: URL.updateGoods,
            params: paramsGoodsData,
            succ: goodsResult => {
                let skuParams = params.sku
                let paramsSKUData = {
                    productCode: goodsResult.productCode,
                    shopCode: skuParams.shopCode,
                    itemList: skuParams.itemList,
                    isPass: skuParams.isExamine ? 2 : 1,// 是否提交审核 1 不提交 2提交审核
                    couponItemList: params.couponItemList
                }
                let requestSKUOptions = {
                    path: URL.skuUpdate,
                    params: paramsSKUData,
                    succ: succ
                }
                POST(requestSKUOptions)
            }
        }
        POST(requestGoodsOptions)
    },
    skuSubmit(params, succ) {
        let paramsData = {
            productCode: params.productCode,
            shopCode: params.shopCode,
            itemList: params.itemList,
            isPass: params.isExamine ? 2 : 1, // 是否提交审核 1 不提交 2提交审核
            couponItemList: params.couponItemList
        }
        if (params.isIntegral) {
            paramsData.belongMall = 1066002
        } else {
            paramsData.belongMall = 1066001
        }
        let requestOptions = {
            path: URL.skuUpdate,
            params: paramsData,
            succ: succ
        }
        POST(requestOptions)
    },
    skuFlagUpdata(code, flag, succ, fail) {
        let requestOptions = {
            path: URL.skuFlagUpdata,
            params: {
                skuCode: code,
                flag: flag,
            },
            succ: succ,
            fail: fail
        }
        POST(requestOptions)
    },
    managerExport(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.categoryCode) && params.categoryCode != "all") {
            paramsData.categoryCode = params.categoryCode
        }
        if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
            paramsData.shopCode = params.shopCode
        }
        if ($.isNotEmpty(params.productName)) {
            paramsData.productName = params.productName
        }
        if (params.isIntegral) {
            paramsData.belongMall = 1066002
        } else {
            paramsData.belongMall = 1066001
        }
        paramsData.productStatus = params.status ? parseInt(params.status) : 1
        let requestOptions = {
            path: URL.managerExport,
            params: paramsData,
            succ: succ
        }
        POST(requestOptions)
    },
    inputExport(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.categoryCode) && params.categoryCode != "all") {
            paramsData.categoryCode = params.categoryCode
        }
        if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
            paramsData.shopCode = params.shopCode
        }
        if ($.isNotEmpty(params.productName)) {
            paramsData.productName = params.productName
        }
        paramsData.flowStatusCode = params.status ? parseInt(params.status) : 1
        let requestOptions = {
            path: URL.inputExport,
            params: paramsData,
            succ: succ
        }
        POST(requestOptions)
    },
    sort(id, val, succ) {
        let requestOptions = {
            path: URL.updateGoodsState,
            params: {id: id, sortNum: val},
            succ: succ
        }
        POST(requestOptions)
    },
    up(id, state, succ, fail) {
        let requestOptions = {
            path: URL.updateGoodsState,
            params: {
                id: id,
                productStatus: state
            },
            succ: succ,
            fail: fail
        }
        POST(requestOptions)
    },
    del(id, succ) {
        let requestOptions = {
            path: URL.updateGoodsState,
            params: {
                id: id,
                flag: 0
            },
            succ: succ
        }
        POST(requestOptions)
    },
    approval(code, status, remark, succ) {
        examineApi.approval(code, status, remark, succ);
    },
}

export default api
