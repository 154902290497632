import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "deposit_details"
};
const _hoisted_2 = {
  class: "base_details_content"
};
const _hoisted_3 = {
  class: "content",
  style: {
    "margin-top": "20px"
  }
};
import { ref, reactive } from "vue";
import BackTitleView from "@com/BackTitleView.vue";
import $ from "@util/base";
import useRoute from "@util/router";
import api from "@api/deposit";
export default {
  __name: 'DepositDetails',

  setup(__props) {
    const route = useRoute();
    route.watchRouter("deposittabledetails", () => {
      getList();
    });
    var title = '储值完整记录';
    init();

    function init() {
      if (route.query().type === '1048001') {
        title = '储值完整记录';
      } else {
        title = '冻结完整记录';
      }
    }

    const data = ref([]);
    const total = ref(0);
    const config = {
      showIndex: true,
      column: [{
        label: "变更事项",
        prop: "changeType"
      }, {
        label: "订单号",
        prop: "changeInfo"
      }, {
        label: "变更时间",
        prop: "createTime"
      }, {
        label: "储值变更",
        prop: "changeMoney"
      }, {
        label: "余额",
        prop: "balanceMoney"
      }]
    };
    getList();

    function getList() {
      let routeQuery = route.query();
      api.list({
        depositType: routeQuery.type,
        memberCode: routeQuery.code,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        total.value = val.total;
        data.value = val.list.map(e => {
          if (e.changeMoney > 0) {
            e.changeMoney = `+${e.changeMoney}`;
          }

          return e;
        });
      });
    }

    function back() {
      route.go(-1);
    }

    return (_ctx, _cache) => {
      const _component_DataCollectTable = _resolveComponent("DataCollectTable");

      const _component_el_main = _resolveComponent("el-main");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_footer = _resolveComponent("el-footer");

      const _component_el_container = _resolveComponent("el-container");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, {
        class: "base_details_container"
      }, {
        default: _withCtx(() => [_createVNode(BackTitleView, {
          title: _unref(title)
        }, null, 8, ["title"]), _createVNode(_component_el_main, null, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_DataCollectTable, {
            data: data.value,
            total: total.value,
            config: config
          }, null, 8, ["data", "total"])])])]),
          _: 1
        }), _createVNode(_component_el_footer, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            onClick: back
          }, {
            default: _withCtx(() => [_createTextVNode("关闭")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]);
    };
  }

};