import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "stored_value_manager"
};
const _hoisted_2 = {
  class: "base_screen_header"
};
const _hoisted_3 = {
  class: "btn-box"
};
const _hoisted_4 = {
  class: "base_main_margin_top"
};
const _hoisted_5 = {
  class: "base_main_header"
};
const _hoisted_6 = {
  class: "dialog-footer"
};
import StatusBar from "@com/StatusBar.vue";
import { $msg, $box } from "@ui/msg";
import { ref, reactive } from "vue";
import { Search } from "@element-plus/icons-vue";
import useRoute from "@util/router";
import DataCollectTable from "@com/table/DataCollectTable.vue";
import $ from "@util/base";
import api from "@api/deposit";
export default {
  __name: 'DepositeCard',

  setup(__props) {
    const route = useRoute();
    route.watchRouter("depositecard", () => {
      getData();
    });
    const statusList = reactive([{
      label: "全部",
      value: "all",
      total: 0
    }, {
      label: "未兑换",
      value: "1057001",
      total: 0
    }, {
      label: "已兑换",
      value: "1057002",
      total: 0
    }]);
    const data = ref([]);
    const total = ref(0);
    const config = {
      showIndex: true,
      operations: null,
      column: [{
        label: "储值卡密",
        prop: "cardSecret",
        width: 130
      }, {
        label: "储值卡金额（元）",
        prop: "cardMoney"
      }, {
        label: "状态",
        prop: "cardStatusName",
        width: 100
      }, {
        label: "兑换人",
        prop: "exchangeByName",
        width: 120
      }, {
        label: "兑换时间",
        prop: "exchangeTime"
      }, {
        label: "生成人员",
        prop: "creatorName",
        width: 120
      }, {
        label: "生成时间",
        prop: "createTime",
        width: 180
      }]
    };
    const dialogVisible = ref(false);
    const formRef = ref(null);
    const form = reactive({
      cardMoney: null,
      num: null
    });

    function show() {
      dialogVisible.value = true;
    }

    function add() {
      show();
    }

    const rules = {
      cardMoney: [{
        required: true,
        message: "请输入单卡金额",
        trigger: "blur"
      }],
      num: [{
        required: true,
        message: "请输入生成数量",
        trigger: "blur"
      }]
    };

    const submitForm = formEl => {
      if (!formEl) return;
      formEl.validate((valid, fields) => {
        if (valid) {
          userStoredValue(form);
        }
      });
    };

    function userStoredValue(data) {
      api.addRecharge(data, () => {
        $msg.succ("生成成功");
        dialogVisible.value = false;
        getData();
      });
    }

    const resetForm = formEl => {
      if (!formEl) return;
      formEl.resetFields();
    };

    const status = ref(route.query().status || "");
    getData();

    function getData() {
      getList();
      getStatusNum();
    }

    function getList() {
      let routeQuery = route.query();
      api.rechargeList({
        status: routeQuery.status,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        total.value = val.total;
        data.value = val.list;
      });
    }

    function exportData() {
      let routeQuery = route.query();
      api.exportRecharge({
        status: routeQuery.status,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        $.download(val.url, "储值卡列表导出");
      });
    }

    function getStatusNum() {
      api.rechargeStatusNum(val => {
        statusList.forEach((e, i) => {
          e.total = val[i];
        });
      });
    }

    return (_ctx, _cache) => {
      const _component_BackTitleView = _resolveComponent("BackTitleView");

      const _component_el_header = _resolveComponent("el-header");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_input_number = _resolveComponent("el-input-number");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_dialog = _resolveComponent("el-dialog");

      const _component_el_main = _resolveComponent("el-main");

      const _component_el_container = _resolveComponent("el-container");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, {
        class: "base_details_container"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_header, null, {
          default: _withCtx(() => [_createVNode(_component_BackTitleView, {
            title: "储值卡管理"
          })]),
          _: 1
        }), _createVNode(_component_el_main, {
          class: "base_main_content"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_button, {
            type: "primary",
            onClick: add
          }, {
            default: _withCtx(() => [_createTextVNode("生成储值卡")]),
            _: 1
          })])]), _createElementVNode("div", _hoisted_4, [_createVNode(StatusBar, {
            data: statusList,
            default: "all"
          }, null, 8, ["data"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_button, {
            type: "primary",
            text: "",
            onClick: exportData
          }, {
            default: _withCtx(() => [_createTextVNode("导出")]),
            _: 1
          })]), _createVNode(DataCollectTable, {
            data: data.value,
            config: config,
            total: total.value
          }, null, 8, ["data", "total"]), _createVNode(_component_el_dialog, {
            modelValue: dialogVisible.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => dialogVisible.value = $event),
            title: "生成储值卡",
            width: "35%",
            top: "20vh",
            class: "base_dialog",
            onOpen: _cache[5] || (_cache[5] = $event => resetForm(formRef.value))
          }, {
            footer: _withCtx(() => [_createElementVNode("span", _hoisted_6, [_createVNode(_component_el_button, {
              type: "primary",
              onClick: _cache[2] || (_cache[2] = $event => submitForm(formRef.value))
            }, {
              default: _withCtx(() => [_createTextVNode("生成")]),
              _: 1
            }), _createVNode(_component_el_button, {
              onClick: _cache[3] || (_cache[3] = $event => dialogVisible.value = false)
            }, {
              default: _withCtx(() => [_createTextVNode("取消")]),
              _: 1
            })])]),
            default: _withCtx(() => [_createVNode(_component_el_form, {
              ref_key: "formRef",
              ref: formRef,
              model: form,
              rules: rules,
              "label-width": "120px"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "单卡金额(元)",
                prop: "cardMoney"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input_number, {
                  modelValue: form.cardMoney,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.cardMoney = $event),
                  modelModifiers: {
                    number: true
                  },
                  min: 0,
                  precision: 2,
                  controls: false,
                  "controls-position": "right"
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "生成数量",
                prop: "num"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: form.num,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.num = $event),
                  modelModifiers: {
                    number: true
                  },
                  type: "number",
                  onkeyup: "value=value.replace(/^(0+)|[^\\d]+/g,'')"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }, 8, ["model"])]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      })]);
    };
  }

};