import { unref as _unref, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-1f128d1f"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "uploadSinglePicture"
};
import $ from "@util/base";
import { ref, computed, watch } from "vue";
import { UPLOAD_URL } from "@net/url";
import auth from "@net/auth";
import msg from "@ui/message";
import { ReadBase64Dimension } from "@util/image-dimension";
export default {
  __name: 'UploadSinglePicture',
  props: {
    // 上传地址
    url: {
      type: String,
      default: UPLOAD_URL
    },
    // 上传文件字段名
    name: {
      type: String,
      default: "file_data"
    },
    fileSize: {
      type: Number,
      default: null
    },
    imgSize: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 100
    },
    modelValue: {
      type: String
    }
  },
  emits: ['update:modelValue'],

  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const props = __props;
    const width = props.width + 'px';
    const imgWidth = props.width - 15 + 'px';
    const progressFlag = ref(false);
    const loadProgress = ref(0);
    const value = ref(props.modelValue);
    watch(() => props.modelValue, val => {
      value.value = props.modelValue;
    });

    function uploadProcess(event) {
      progressFlag.value = true;
      loadProgress.value = parseInt(event.percent);

      if (loadProgress.value >= 100) {
        loadProgress.value = 99;
      }
    }

    function checkUploadFile(rawFile) {
      if (process.env.NODE_ENV === "production" && (props.imgSize != null || props.fileSize != null)) {
        return new Promise((res, rej) => {
          loadFile(rawFile, fileBase64 => {
            if (props.fileSize != null && rawFile.size > props.fileSize) {
              msg.error("图片大小以超过" + fileSizeText(props.fileSize));
              rej();
              return;
            }

            if (props.imgSize != null) {
              let imgSizes = props.imgSize.split("×");
              let width = imgSizes[0];
              let height = imgSizes[1];
              let type = rawFile.name.substring(rawFile.name.lastIndexOf(".") + 1, rawFile.name.length);
              let imgWH = ReadBase64Dimension(fileBase64, type);

              if (imgWH.width != width || imgWH.height != height) {
                msg.error("图片尺寸错误，应为" + props.imgSize);
                rej();
                return;
              }
            }

            res();
          });
        });
      } else {
        return true;
      }
    }

    function loadFile(file, callback) {
      var reader = new FileReader();

      reader.onload = function (e) {
        callback(e.target.result);
      };

      reader.readAsDataURL(file);
    }

    function fileSizeText(value) {
      return parseInt(value / 1024) + "kb";
    }

    const upload = ref(null);
    const headers = {
      accessToken: auth.getToken()
    };

    function uploadSuccess(response) {
      loadProgress.value = 100;
      progressFlag.value = false;

      if (response.code === 0) {
        value.value = response.data.fileUrl;
        emit('update:modelValue', value.value);
      } else {
        let err = '';

        if ($.isNotEmpty(response.msg)) {
          err = "：" + response.msg;
        }

        msg.error("图片上传失败" + err);
      }
    }

    function uploadError() {
      msg.error("图片上传失败");
    }

    function removePictures() {
      value.value = null;
      emit('update:modelValue', null);
      upload.value.clearFiles();
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_upload = _resolveComponent("el-upload");

      const _component_el_progress = _resolveComponent("el-progress");

      const _component_Close = _resolveComponent("Close");

      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_image = _resolveComponent("el-image");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("span", {
        class: "tips"
      }, "尺寸" + _toDisplayString(__props.imgSize), 513), [[_vShow, _unref($).isEmpty(value.value) && __props.imgSize != null]]), !__props.disabled ? _withDirectives((_openBlock(), _createBlock(_component_el_upload, {
        key: 0,
        ref_key: "upload",
        ref: upload,
        action: __props.url,
        accept: ".png,.jpg,.jpeg,.gif",
        name: __props.name,
        headers: headers,
        "on-success": uploadSuccess,
        "on-error": uploadError,
        "on-progress": uploadProcess,
        "before-upload": checkUploadFile,
        "show-file-list": false
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, null, {
          default: _withCtx(() => [_createTextVNode("上传图片")]),
          _: 1
        })]),
        _: 1
      }, 8, ["action", "name"])), [[_vShow, _unref($).isEmpty(value.value)]]) : _createCommentVNode("", true), _withDirectives(_createVNode(_component_el_progress, {
        style: _normalizeStyle({
          width: width
        }),
        percentage: loadProgress.value
      }, null, 8, ["style", "percentage"]), [[_vShow, progressFlag.value]]), _withDirectives(_createElementVNode("div", {
        class: "picture",
        style: _normalizeStyle({
          width: width,
          height: width
        })
      }, [_withDirectives(_createElementVNode("div", {
        class: "del",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => removePictures(), ["stop"]))
      }, [_createVNode(_component_el_icon, null, {
        default: _withCtx(() => [_createVNode(_component_Close)]),
        _: 1
      })], 512), [[_vShow, !__props.disabled]]), _createVNode(_component_el_image, {
        style: _normalizeStyle({
          width: imgWidth,
          height: imgWidth
        }),
        src: value.value,
        "preview-src-list": [value.value],
        "preview-teleported": true
      }, {
        placeholder: _withCtx(() => [_createElementVNode("div", {
          style: _normalizeStyle({
            width: imgWidth,
            height: imgWidth
          }),
          class: "image_placeholder"
        }, "加载中", 4)]),
        _: 1
      }, 8, ["style", "src", "preview-src-list"])], 4), [[_vShow, _unref($).isNotEmpty(value.value)]])]);
    };
  }

};