import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-57dfb6ea"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["onClick"];
const _hoisted_2 = ["src", "onClick"];
const _hoisted_3 = {
  class: "album-item-footer"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  class: "pagination_layout"
};
const _hoisted_7 = {
  class: "dialog-footer"
};
import { ref } from "vue";
import { Plus } from "@element-plus/icons-vue";
import api from "@/api/albumManager";
import $ from "@/common/utils/base";
import UploadPicture from "@com/upload/UploadPicture.vue";
import { $box, $msg } from "@/common/ui/msg";
export default {
  __name: 'albumManager',

  setup(__props) {
    const size = ref(20);
    const page = ref(1);
    const total = ref(0);
    const data = ref([]);
    getData();

    function getData() {
      api.list({}, res => {
        data.value = res.itemList;
        total.value = res.total;
      });
    }

    const dialogVisible = ref(false);
    const dialogTitle = ref('');
    const form = ref({
      id: null,
      albumCode: '',
      albumName: '',
      type: 1,
      phone: '',
      phoneText: '',
      albumPic: [],
      picStr: []
    });
    const formRef = ref({});
    const rules = ref([]);

    function getDetail(id) {
      api.get(id, res => {
        form.value.id = res.id;
        form.value.albumCode = res.albumCode;
        form.value.albumName = res.albumName;
        form.value.type = res.type;
        form.value.phone = res.phone;

        if (form.value.phone && form.value.phone.length > 0) {
          form.value.phoneText = res.phone.join(',');
        }

        form.value.albumPic = res.albumPic ? [res.albumPic] : [''];
        form.value.picStr = res.picStr;
      });
    }

    const getAlbum = id => {
      getDetail(id);
      titleIsDisabled.value = true;
      typeIsDisabled.value = true;
      nameIsDisabled.value = true;
      albumPicIsDisabled.value = false;
      picStrIsDisabled.value = false;
      dialogTitle.value = '相册详情';
      dialogVisible.value = true;
    };

    const getType = id => {
      getDetail(id);
      titleIsDisabled.value = true;
      albumPicIsDisabled.value = true;
      nameIsDisabled.value = true;
      picStrIsDisabled.value = true;
      typeIsDisabled.value = false;
      dialogTitle.value = '相册详情';
      dialogVisible.value = true;
    };

    const getName = id => {
      getDetail(id);
      titleIsDisabled.value = true;
      typeIsDisabled.value = true;
      albumPicIsDisabled.value = true;
      picStrIsDisabled.value = true;
      nameIsDisabled.value = false;
      dialogTitle.value = '相册详情';
      dialogVisible.value = true;
    };

    const picStrIsDisabled = ref(false);
    const titleIsDisabled = ref(false);
    const typeIsDisabled = ref(false);
    const nameIsDisabled = ref(false);
    const albumPicIsDisabled = ref(false);

    function add() {
      form.value = {
        id: null,
        albumCode: '',
        albumName: '',
        type: 1,
        phone: [],
        albumPic: [],
        picStr: []
      };
      titleIsDisabled.value = false;
      typeIsDisabled.value = false;
      nameIsDisabled.value = false;
      albumPicIsDisabled.value = false;
      picStrIsDisabled.value = false;
      dialogTitle.value = '新增相册';
      dialogVisible.value = true;
    }

    const submitForm = async formEl => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          let data = {};
          Object.assign(data, form.value);

          if (form.value.phoneText && form.value.phoneText.length > 0) {
            form.value.phoneText.replaceAll('，', ','); //中文逗号转英文逗号

            data.phone = form.value.phoneText.split(',');
          }

          if (data.id && data.albumCode) {
            api.update(data, res => {
              $msg.succ("修改成功");
              getData();
              dialogVisible.value = false;
            });
          } else {
            api.add(data, res => {
              $msg.succ("保存成功");
              getData();
              dialogVisible.value = false;
            });
          }
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    const del = data => {
      $box.open("删除", "确认删除相册" + data.albumName, () => {
        api.del(data.id, () => {
          $msg.succ("删除成功");
          getData();
        });
      });
    };

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_Close = _resolveComponent("Close");

      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_card = _resolveComponent("el-card");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_pagination = _resolveComponent("el-pagination");

      const _component_el_main = _resolveComponent("el-main");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_radio = _resolveComponent("el-radio");

      const _component_el_radio_group = _resolveComponent("el-radio-group");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_dialog = _resolveComponent("el-dialog");

      const _directive_auth = _resolveDirective("auth");

      const _directive_auth_click = _resolveDirective("auth-click");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          icon: _unref(Plus),
          onClick: add
        }, {
          default: _withCtx(() => [_createTextVNode("新增相册")]),
          _: 1
        }, 8, ["icon"])), [[_directive_auth, _ctx.getCurCode('ADD')]]), _createVNode(_component_el_row, {
          gutter: 25,
          class: "album-list"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value, item => {
            return _openBlock(), _createBlock(_component_el_col, {
              md: 6,
              key: item.id,
              style: {
                "margin-top": "20px"
              }
            }, {
              default: _withCtx(() => [_createVNode(_component_el_card, {
                shadow: "never",
                class: "album-item"
              }, {
                default: _withCtx(() => [_withDirectives((_openBlock(), _createElementBlock("div", {
                  class: "del",
                  onClick: _withModifiers($event => del(item), ["stop"])
                }, [_createVNode(_component_el_icon, {
                  size: 17
                }, {
                  default: _withCtx(() => [_createVNode(_component_Close)]),
                  _: 1
                })], 8, _hoisted_1)), [[_directive_auth, _ctx.getCurCode('DELETE')]]), _withDirectives(_createElementVNode("img", {
                  class: "img",
                  src: item.albumPic,
                  style: {
                    "width": "100%"
                  },
                  onClick: $event => getAlbum(item.id)
                }, null, 8, _hoisted_2), [[_directive_auth_click, _ctx.getCurCode('MODIFY')]]), _createElementVNode("div", _hoisted_3, [_withDirectives((_openBlock(), _createElementBlock("p", {
                  onClick: $event => getName(item.id)
                }, [_createTextVNode("相册名称："), _createElementVNode("span", null, _toDisplayString(item.albumName), 1)], 8, _hoisted_4)), [[_directive_auth_click, _ctx.getCurCode('MODIFY')]]), _createElementVNode("p", null, [_createTextVNode("创建时间："), _createElementVNode("span", null, _toDisplayString(item.createTime), 1), _withDirectives((_openBlock(), _createElementBlock("span", {
                  onClick: $event => getType(item.id),
                  style: {
                    "float": "inline-end"
                  }
                }, [_createTextVNode(_toDisplayString(item.type == '2' ? '公开' : '私密'), 1)], 8, _hoisted_5)), [[_directive_auth_click, _ctx.getCurCode('MODIFY')]])])])]),
                _: 2
              }, 1024)]),
              _: 2
            }, 1024);
          }), 128))]),
          _: 1
        }), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_pagination, {
          background: "",
          layout: "total, prev, pager, next, sizes",
          total: total.value,
          currentPage: page.value,
          "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => page.value = $event),
          "page-size": size.value,
          "onUpdate:pageSize": _cache[1] || (_cache[1] = $event => size.value = $event),
          "page-sizes": [10, 20, 50, 100],
          onCurrentChange: _ctx.pagerChange,
          onSizeChange: _ctx.pagerSizeChange
        }, null, 8, ["total", "currentPage", "page-size", "onCurrentChange", "onSizeChange"])])]),
        _: 1
      }), _createVNode(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => dialogVisible.value = $event),
        title: dialogTitle.value,
        width: "70%",
        class: "base_dialog"
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_7, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[7] || (_cache[7] = $event => submitForm(formRef.value))
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: _cache[8] || (_cache[8] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("关闭")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "formRef",
          ref: formRef,
          model: form.value,
          rules: rules.value,
          "label-width": "80px"
        }, {
          default: _withCtx(() => [!nameIsDisabled.value ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "名称",
            prop: "albumName"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: form.value.albumName,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => form.value.albumName = $event),
              placeholder: "请输入相册名称"
            }, null, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), !typeIsDisabled.value ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            label: "类型",
            prop: "type"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_radio_group, {
              modelValue: form.value.type,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => form.value.type = $event)
            }, {
              default: _withCtx(() => [_createVNode(_component_el_radio, {
                label: 1
              }, {
                default: _withCtx(() => [_createTextVNode("公开")]),
                _: 1
              }), _createVNode(_component_el_radio, {
                label: 2
              }, {
                default: _withCtx(() => [_createTextVNode("私密")]),
                _: 1
              })]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), !typeIsDisabled.value && form.value.type === 2 ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 2,
            label: "手机号",
            prop: "phone"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              autosize: "",
              type: "textarea",
              modelValue: form.value.phoneText,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => form.value.phoneText = $event),
              placeholder: "请输入用户手机号,逗号分隔"
            }, null, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), !albumPicIsDisabled.value ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 3,
            label: "封面",
            prop: "albumPic"
          }, {
            default: _withCtx(() => [_createVNode(UploadPicture, {
              modelValue: form.value.albumPic,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => form.value.albumPic = $event),
              limit: 1,
              fileSize: _unref($).imgFileSize(),
              disabled: albumPicIsDisabled.value
            }, null, 8, ["modelValue", "fileSize", "disabled"])]),
            _: 1
          })) : _createCommentVNode("", true), !picStrIsDisabled.value ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 4,
            label: "照片",
            prop: "picStr"
          }, {
            default: _withCtx(() => [_createVNode(UploadPicture, {
              modelValue: form.value.picStr,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => form.value.picStr = $event),
              limit: 50,
              fileSize: _unref($).imgFileSize(),
              disabled: picStrIsDisabled.value
            }, null, 8, ["modelValue", "fileSize", "disabled"])]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }

};