import {POST} from "@net/service.js";
import $ from "@util/base";
import page from "./page"
import examineApi from "./examine";

const URL = {
  travelInput: "travel_add_list",
  travelManager: "travel_list",
  updateTravelState: "travel_update_way",
  add: 'travel_add',
  details: 'travel_get',
  update: 'travel_update',
  travelInputExport: 'travel_add_list_export',
  travelManagerExport: 'travel_list_export'
};

const api = {
  travelInput(params, succ) {
    let paramsData = {};
    if ($.isNotEmpty(params.categoryCode) && params.categoryCode != "all") {
      paramsData.categoryCode = params.categoryCode;
    }
    if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
      paramsData.shopCode = params.shopCode;
    }
    if ($.isNotEmpty(params.name)) {
      paramsData.name = params.name;
    }
    if ($.isNotEmpty(params.targetArea)) {
      paramsData.targetArea = params.targetArea;
    }
    if ($.isNotEmpty(params.jointype)) {
      paramsData.jointype = params.jointype;
    }
    console.log(params, params.jointype, paramsData)
    paramsData.flowStatusCode = params.status ? parseInt(params.status) : 1;
    paramsData.page = params.page ? params.page : 1;
    paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
    let requestOptions = {
      path: URL.travelInput,
      params: paramsData,
      isLoading: true,
      succ: succ,
    };
    POST(requestOptions);
  },
  travelManager(params, succ) {
    let paramsData = {};
    if ($.isNotEmpty(params.categoryCode) && params.categoryCode != "all") {
      paramsData.categoryCode = params.categoryCode;
    }
    if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
      paramsData.shopCode = params.shopCode;
    }
    if ($.isNotEmpty(params.name)) {
      paramsData.name = params.name;
    }
    if ($.isNotEmpty(params.targetArea)) {
      paramsData.targetArea = params.targetArea;
    }
    if ($.isNotEmpty(params.jointype)) {
      paramsData.jointype = params.jointype;
    }
    if (params.isIntegral) {
      paramsData.belongMall = 1066002
    } else {
      paramsData.belongMall = 1066001
    }
    paramsData.travelStatus = params.status ? parseInt(params.status) : 1;
    paramsData.page = params.page ? params.page : 1;
    paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
    let requestOptions = {
      path: URL.travelManager,
      params: paramsData,
      isLoading: true,
      succ: succ,
    };
    POST(requestOptions);
  },
  add(params, succ) {
    if (params.travelItem.tags) {
      params.travelItem.tags = params.travelItem.tags.join(",")
    }
    let requestOptions = {
      path: URL.add,
      params: params,
      isLoading: true,
      succ: succ,
    };
    POST(requestOptions);
  },
  details(id, succ) {
    let requestOptions = {
      path: URL.details,
      params: {id: id},
      isLoading: true,
      succ: succ,
    };
    POST(requestOptions);
  },
  update(params, succ) {
    if (params.travelItem.tags) {
      params.travelItem.tags = params.travelItem.tags.join(",")
    }
    let requestOptions = {
      path: URL.update,
      params: params,
      isLoading: true,
      succ: succ,
    };
    POST(requestOptions);
  },
  sort(id, val, succ) {
    val = parseInt(val)
    let requestOptions = {
      path: URL.updateTravelState,
      params: {id: id, sortNum: val},
      succ: succ,
    };
    POST(requestOptions);
  },
  up(id, state, succ) {
    let requestOptions = {
      path: URL.updateTravelState,
      params: {
        id: id,
        travelStatus: state,
      },
      succ: succ,
    };
    POST(requestOptions);
  },
  del(id, succ) {
    let requestOptions = {
      path: URL.updateTravelState,
      params: {
        id: id,
        flag: 0,
      },
      succ: succ,
    };
    POST(requestOptions);
  },
  travelInputExport(params, succ) {
    let paramsData = {};
    if ($.isNotEmpty(params.categoryCode) && params.categoryCode != "all") {
      paramsData.categoryCode = params.categoryCode;
    }
    if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
      paramsData.shopCode = params.shopCode;
    }
    if ($.isNotEmpty(params.name)) {
      paramsData.name = params.name;
    }
    if ($.isNotEmpty(params.targetArea)) {
      paramsData.targetArea = params.targetArea;
    }
    if ($.isNotEmpty(params.jointype)) {
      paramsData.jointype = params.jointype;
    }
    paramsData.flowStatusCode = params.status ? parseInt(params.status) : 1;
    let requestOptions = {
      path: URL.travelInputExport,
      params: paramsData,
      isLoading: true,
      succ: succ,
    };
    POST(requestOptions);
  },
  travelManagerExport(params, succ) {
    let paramsData = {};
    if ($.isNotEmpty(params.categoryCode) && params.categoryCode != "all") {
      paramsData.categoryCode = params.categoryCode;
    }
    if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
      paramsData.shopCode = params.shopCode;
    }
    if ($.isNotEmpty(params.name)) {
      paramsData.name = params.name;
    }
    if (params.isIntegral) {
      paramsData.belongMall = 1066002
    } else {
      paramsData.belongMall = 1066001
    }
    paramsData.travelStatus = params.status ? parseInt(params.status) : 1;
    let requestOptions = {
      path: URL.travelManagerExport,
      params: paramsData,
      isLoading: true,
      succ: succ,
    };
    POST(requestOptions);
  },
  approval(code, status, remark, succ) {
    examineApi.approval(code, status, remark, succ);
  },
};

export default api;