import { POST } from "@net/service.js";
import $ from "@util/base";
import page from "./page"

const URL = {
    num: "market_message_status_num",
    list: "market_message_list",
    details: "market_message_detail",
    add: "marker_message_add",
    delete: "marker_message_delete",
    export: "market_message_list_export",
    templeteList: "promotion_sms_templete_list",
    send: "market_message_send",
    update: "market_message_update"
};

// 1009001 普通会员、 1009002 分销会员
const TYPE = {
    membermanager: '1009001',
    distributormanager: '1009002',
}

const api = {
    num(name, succ) {
        let paramsData = {}
        if ($.isNotEmpty(name)) {
            paramsData.name = name
        }
        let requestOptions = {
            path: URL.num,
            params: paramsData,
            succ: val => {
                succ([val.total, val.create, val.used])
            }
        }
        POST(requestOptions)
    },
    list(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.name)) {
            paramsData.name = params.name
        }
        if ($.isNotEmpty(params.status) && params.status != "all") {
            paramsData.sendStatus = params.status
        }
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.list,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    details(id, succ) {
        let requestOptions = {
            path: URL.details,
            params: { id: id },
            succ: succ
        }
        POST(requestOptions)
    },
    add(params, succ) {
        let requestOptions = {
            path: URL.add,
            params: params,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    delete(id, succ) {
        let requestOptions = {
            path: URL.delete,
            params: { id: id },
            succ: succ
        }
        POST(requestOptions)
    },
    export (params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.name)) {
            paramsData.name = params.name
        }
        if ($.isNotEmpty(params.status) && params.status != "all") {
            paramsData.sendStatus = params.status
        }
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.export,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    templeteList(succ) {
        let requestOptions = {
            path: URL.templeteList,
            succ: val => {
                succ(val.list.map(e => {
                    return { label: e.name, value: e.templeteId, type: val.templeteId }
                }))
            }
        }
        POST(requestOptions)
    },
    send(id, succ) {
        let requestOptions = {
            path: URL.send,
            params: { id: id },
            succ: succ
        }
        POST(requestOptions)
    },
    update(params, succ) {
        let requestOptions = {
            path: URL.update,
            params: params,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    }
};

export default api