// 商品
import GoodsInput from "@view/goods/GoodsInput.vue";
import GoodsManager from "@view/goods/GoodsManager.vue";
import StockManager from "@view/goods/StockManager.vue";
import StockRecode from "@view/goods/StockRecode.vue";
import SpecsManager from "@view/goods/SpecsManager.vue";
import ExpressManager from "@view/goods/ExpressManager.vue";
import LabelManager from "@view/goods/LabelManager.vue";

import GoodsDetails from "@view/goods/GoodsDetails.vue";
import ExpressDetails from "@view/goods/ExpressDetails.vue";
import OfflineStockImport from "@view/goods/OfflineStockImport.vue"

import TakeGoodsManager from "@view/goods/TakeGoodsManager.vue";
import TakeGoodsDetails from "@view/goods/TakeGoodsDetails.vue";

export default [{
        path: "/goodsinput",
        name: "goodsinput",
        component: GoodsInput,
        meta: {
            activeMainMenu: "goods",
            activeSubMenu: "/goodsinput",
        },
    },
    {
        path: "/goodsmanager",
        name: "goodsmanager",
        component: GoodsManager,
        meta: {
            activeMainMenu: "goods",
            activeSubMenu: "/goodsmanager",
        },
    },
    {
        path: "/stockmanager",
        name: "stockmanager",
        component: StockManager,
        meta: {
            activeMainMenu: "goods",
            activeSubMenu: "/stockmanager",
        },
    },
    {
        path: "/stockrecode",
        name: "stockrecode",
        component: StockRecode,
        meta: {
            activeMainMenu: "goods",
            activeSubMenu: "/stockrecode",
        },
    },
    {
        path: "/goodslabelmanager",
        name: "goodslabelmanager",
        component: LabelManager,
        meta: {
            activeMainMenu: "goods",
            activeSubMenu: "/goodslabelmanager",
        },
    },
    {
        path: "/specsmanager",
        name: "specsmanager",
        component: SpecsManager,
        meta: {
            activeMainMenu: "goods",
            activeSubMenu: "/specsmanager",
        },
    },
    {
        path: "/expressmanager",
        name: "expressmanager",
        component: ExpressManager,
        meta: {
            activeMainMenu: "goods",
            activeSubMenu: "/expressmanager",
        },
    },
    {
        path: "/goodsdetails",
        name: "goodsdetails",
        component: GoodsDetails,
        meta: {
            activeMainMenu: "goods",
            activeSubMenu: "/goodsinput",
        },
    },
    {
        path: "/expressdetails",
        name: "expressdetails",
        component: ExpressDetails,
        meta: {
            activeMainMenu: "goods",
            activeSubMenu: "/expressmanager",
        },
    },
    {
        path: "/offlinestockimport",
        name: "offlinestockimport",
        component: OfflineStockImport,
        meta: {
            activeMainMenu: "goods",
            activeSubMenu: "/stockrecode",
        },
    },
    {
        path: "/takegoodsmanager",
        name: "takegoodsmanager",
        component: TakeGoodsManager,
        meta: {
            activeMainMenu: "goods",
            activeSubMenu: "/takegoodsmanager",
        },
    },
    {
        path: "/takegoodsdetails",
        name: "takegoodsdetails",
        component: TakeGoodsDetails,
        meta: {
            activeMainMenu: "goods",
            activeSubMenu: "/takegoodsmanager",
        },
    }
];