import {POST} from "@net/service.js";
import $ from "@util/base";
import page from "./page"

const URL = {
    list: "mrp_grade_list",
    del: "mrp_grade_del",
    add: "mrp_grade_add",
    update: "mrp_grade_update",
    bind: "mrp_grade_equity_add",
    getMemberGrade: "get_member_grade",
};

const api = {
    num(params, succ) {
        let requestOptions = {
            path: URL.num,
            params: params,
            succ: val => {
                succ([val.total, val.normal, val.freeze])
            }
        }
        POST(requestOptions)
    },
    list(params, succ) {
        let requestOptions = {
            path: URL.list,
            params: params,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    add(params, succ) {
        let requestOptions = {
            path: URL.add,
            params: params,
            isLoading: true,
            succ: succ,
        }
        POST(requestOptions)
    },
    update(params, succ) {
        let requestOptions = {
            path: URL.update,
            params: params,
            isLoading: true,
            succ: succ,
        }
        POST(requestOptions)
    },
    bind(params, succ) {
        let requestOptions = {
            path: URL.bind,
            params: params,
            isLoading: true,
            succ: succ,
        }
        POST(requestOptions)
    },
    del(code, succ) {
        let paramsData = {}
        paramsData.code = code
        let requestOptions = {
            path: URL.del,
            params: paramsData,
            succ: succ,
        }
        POST(requestOptions)
    },
    qrCodeRegister(params, succ) {
        let requestOptions = {
            path: URL.getMemberGrade,
            params: params,
            succ: succ
        }
        POST(requestOptions)
    }
};

export default api
