import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
import { ref, watch, nextTick } from "vue";
import hotelPrice from "./roomPrice/HotelPrice.vue";
import DynamicParams from "@com/DynamicParams.vue";
import $ from "@util/base";
import { $msg } from "@ui/msg";
export default {
  __name: 'RoomConfigure',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },

  setup(__props, {
    expose: __expose
  }) {
    const props = __props;
    const data = ref([]);
    const roomParams = ref(null);
    const hotelPriceRef = ref(null);
    const drawer = ref(false);
    watch(() => props.data, val => {
      data.value = val;
    });
    watch(() => drawer.value, () => {
      if (drawer.value == false) {
        hotelPriceRef.value.reset();
      }
    });

    function operation(readOnly) {
      if (readOnly) {
        return {
          copy: false,
          move: false,
          modify: false,
          del: false,
          add: false,
          check: true
        };
      } else {
        return {
          copy: true,
          move: true,
          modify: true,
          del: true,
          add: true,
          check: false
        };
      }
    }

    function open() {
      drawer.value = true;
    }

    function close() {
      drawer.value = false;
    }

    function save() {
      hotelPriceRef.value.save(res => {
        if (res != false) {
          console.log(res);

          if (modifyIndex != null) {
            roomParams.value.modifyData(modifyIndex, res);
          } else {
            roomParams.value.addData(res);
          }

          close();
        }
      });
    }

    var modifyIndex = null;

    function showAddRoom() {
      modifyIndex = null;
      open();
    }

    function showDetails(index) {
      modifyIndex = null;
      open();
      nextTick(() => {
        hotelPriceRef.value.improtData(roomParams.value.getValue()[index]);
      });
    }

    function modify(index) {
      modifyIndex = index;
      open();
      nextTick(() => {
        hotelPriceRef.value.improtData(roomParams.value.getValue()[index]);
      });
    }

    function copy(index) {
      $msg.succ("复制成功");
      setTimeout(() => {
        open();
        nextTick(() => {
          let copyData = roomParams.value.getValue()[index];
          copyData.roomName = null;
          copyData.roomPic = null;
          copyData.roomCode = null;
          copyData.id = null;
          hotelPriceRef.value.improtData(copyData);
        });
      }, 1000);
    }

    function getValue() {
      let value = roomParams.value.getValue();

      if (value.length <= 0) {
        $msg.error("请添加房型");
        return false;
      }

      return value;
    }

    __expose({
      getValue
    });

    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_HotelPrice = _resolveComponent("HotelPrice");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_drawer = _resolveComponent("el-drawer");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(DynamicParams, {
        ref_key: "roomParams",
        ref: roomParams,
        showDefault: __props.readOnly,
        data: data.value,
        customAdd: true,
        onAdd: _cache[0] || (_cache[0] = $event => showAddRoom()),
        onCheck: showDetails,
        operation: operation(__props.readOnly),
        onCopy: copy,
        onModify: modify,
        addText: "添加房型"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "房型名称",
          prop: "roomName",
          align: "center"
        }), _createVNode(_component_el_table_column, {
          label: "可住人数",
          prop: "peopleNum",
          align: "center"
        })]),
        _: 1
      }, 8, ["showDefault", "data", "operation"]), _createVNode(_component_el_drawer, {
        "custom-class": "base_drawer add_room_layout",
        modelValue: drawer.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => drawer.value = $event),
        "close-on-click-modal": false,
        title: "添加房型",
        "with-header": true,
        size: 1000
      }, {
        footer: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_el_button, {
          onClick: _cache[1] || (_cache[1] = $event => drawer.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), !__props.readOnly ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          type: "primary",
          onClick: save
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        })) : _createCommentVNode("", true)])]),
        default: _withCtx(() => [_createVNode(_component_HotelPrice, {
          ref_key: "hotelPriceRef",
          ref: hotelPriceRef
        }, null, 512)]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }

};