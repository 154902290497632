import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "travel_price_operate"
};
const _hoisted_2 = {
  class: "operate_layout"
};
const _hoisted_3 = {
  class: "operate_item"
};
const _hoisted_4 = {
  class: "picker"
};
const _hoisted_5 = {
  class: "operate_item"
};
import { ref, reactive, watch } from "vue";
import msg from "@ui/message";
import DynamicParams from "@com/DynamicParams.vue";
export default {
  __name: 'TravelPriceOperate',
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ["execute", "checkOperateChange", "calendarSwitch"],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const baseDataFormRef = ref(null);
    const baseData = reactive({
      singleRoomPrice: null,
      groupPeopleNum: null,
      expectPeopleNum: null,
      packagePeopleNum: null,
      stockNum: null,
      orderPeopleNum: null
    });
    const rules = {
      singleRoomPrice: [{
        required: true,
        message: "请填写单房差",
        trigger: "blur"
      }]
    };
    const chackBaseKey = ["groupPeopleNum", "expectPeopleNum", "packagePeopleNum", "stockNum", "orderPeopleNum"];
    const priceTemplateOperation = {
      move: false,
      modify: false,
      del: true,
      add: true,
      check: false
    };
    const calendarSwitch = ref((localStorage.getItem("calendarNumber") || 6) == 6);
    const priceTemplateRef = ref(null);
    const priceTemplate = ref([// {
      //   priceType: "成人票",
      //   costPrice: 30,
      //   competitorPrice: 30,
      //   markingPrice: 30,
      //   sellPrice: 30,
      // },
      // {
      //   priceType: "儿童票",
      //   costPrice: 20,
      //   competitorPrice: 20,
      //   markingPrice: 20,
      //   sellPrice: 0.01,
      // },
      // {
      //   priceType: "老年票",
      //   costPrice: 10,
      //   competitorPrice: 10,
      //   markingPrice: 10,
      //   sellPrice: 10,
      // },
    ]);
    const priceTemplateTemplate = {
      priceType: "",
      withBedFlag: 0,
      competitorPrice: null,
      markingPrice: null,
      sellPrice: null
    };
    const selectWeek = ref([0, 1, 2, 3, 4, 5, 6]);
    const selectOperate = ref("add");
    const selectDate = ref();
    const pickerOptions = [{
      text: "一个月",

      value() {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
        return [end, start];
      }

    }, {
      text: "三个月",

      value() {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() + 3600 * 1000 * 24 * 90);
        return [end, start];
      }

    }, {
      text: "半年",

      value() {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() + 3600 * 1000 * 24 * 182);
        return [end, start];
      }

    }, {
      text: "一年",

      value() {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() + 3600 * 1000 * 24 * 365);
        return [end, start];
      }

    }];

    const disabledDate = date => {
      return date.getTime() < Date.now() - 3600 * 1000 * 24 || date.getTime() > Date.now() + 3600 * 1000 * 24 * 365;
    };

    const weeks = [{
      id: 1,
      name: "周一"
    }, {
      id: 2,
      name: "周二"
    }, {
      id: 3,
      name: "周三"
    }, {
      id: 4,
      name: "周四"
    }, {
      id: 5,
      name: "周五"
    }, {
      id: 6,
      name: "周六"
    }, {
      id: 0,
      name: "周日"
    }];
    const operate = [{
      id: "clear",
      name: "清空"
    }, {
      id: "modify",
      name: "覆盖"
    }, {
      id: "add",
      name: "添加"
    }];
    watch(() => calendarSwitch.value, val => {
      emit("calendarSwitch", val);
    });

    function getOperate() {
      let data = {
        date: selectDate.value,
        template: {
          price: priceTemplateRef.value.getValue(),
          other: {}
        },
        week: selectWeek.value,
        operate: selectOperate.value
      };

      for (const key in baseData) {
        data.template.other[key] = baseData[key];
      }

      return data;
    }

    function isNotNull(value) {
      if (value != null && value != undefined) {
        if (value.length) {
          return value.length > 0;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    function chackBaseData() {
      if (baseData.singleRoomPrice == null) {
        msg.error("请填写单房差");
        return false;
      }

      let num = 0;
      chackBaseKey.forEach(item => {
        if (baseData[item]) {
          num += 1;
        }
      });

      if (num < chackBaseKey.length && num > 0) {
        msg.error("请完善日期价格设置");
        return false;
      }

      if (num == chackBaseKey.length) {
        if (parseInt(baseData.stockNum) <= 0) {
          msg.error("库存需要大于0");
          return false;
        }

        if (parseInt(baseData.orderPeopleNum) < parseInt(baseData.packagePeopleNum)) {
          msg.error("最大预定人数需要大于最低预定人数");
          return false;
        }
      }

      return true;
    }

    function validate() {
      if (!chackBaseData()) {
        return false;
      }

      for (const price of priceTemplateRef.value.getValue()) {
        for (const templateKey in price) {
          if (!isNotNull(price[templateKey])) {
            msg.error("请选择完善价格");
            return false;
          }
        }
      }

      return true;
    }

    function execute() {
      const data = {
        date: selectDate.value,
        template: {
          price: priceTemplateRef.value.getValue(),
          other: {}
        },
        week: selectWeek.value,
        operate: selectOperate.value
      };

      for (const key in baseData) {
        data.template.other[key] = baseData[key];
      }

      if (selectOperate.value != "clear") {
        if (!chackBaseData()) {
          return;
        }

        for (const price of data.template.price) {
          for (const templateKey in price) {
            if (!isNotNull(price[templateKey])) {
              msg.error("请选择完善价格");
              return;
            }
          }
        }

        if (data.date == null) {
          msg.error("请选择时间范围");
          return;
        }

        if (data.week.length <= 0) {
          msg.error("请选择星期");
          return;
        }
      }

      emit("execute", data);
    }

    function addTemplate() {
      emit("checkOperateChange", res => {
        if (res) {
          var data = {};

          for (const key in priceTemplateTemplate) {
            data[key] = priceTemplateTemplate[key];
          }

          priceTemplateRef.value.addData(data);
        } else {
          msg.error("已经生成了日期价格数据，请先清空后再添加");
        }
      });
    }

    function delTemplate(index) {
      emit("checkOperateChange", res => {
        if (res) {
          priceTemplateRef.value.getReactiveValue().splice(index, 1);
        } else {
          msg.error("已经生成了日期价格数据，请先清空后再删除");
        }
      });
    }

    function importData(data) {
      for (const key in baseData) {
        for (const p of data.price) {
          delete p[key];
        }
      }

      priceTemplate.value = data.price;
    }

    function resetData() {
      baseDataFormRef.value.resetFields();

      for (const key in baseData) {
        baseData[key] = null;
      }

      priceTemplate.value = [];
      priceTemplateRef.value.clear();
    }

    function getData() {
      return {
        price: priceTemplateRef.value.getValue()
      };
    }

    __expose({
      getOperate,
      importData,
      resetData,
      getData,
      chackBaseKey,
      validate
    });

    return (_ctx, _cache) => {
      const _component_el_input_number = _resolveComponent("el-input-number");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_switch = _resolveComponent("el-switch");

      const _component_el_date_picker = _resolveComponent("el-date-picker");

      const _component_el_radio = _resolveComponent("el-radio");

      const _component_el_radio_group = _resolveComponent("el-radio-group");

      const _component_el_checkbox = _resolveComponent("el-checkbox");

      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");

      const _component_el_button = _resolveComponent("el-button");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
        ref_key: "baseDataFormRef",
        ref: baseDataFormRef,
        model: baseData,
        rules: rules,
        "label-width": "120px",
        disabled: __props.readOnly,
        class: "base_details_form"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_row, null, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 8
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "单房差",
              prop: "singleRoomPrice"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input_number, {
                modelValue: baseData.singleRoomPrice,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => baseData.singleRoomPrice = $event),
                modelModifiers: {
                  number: true
                },
                min: 0,
                precision: 2,
                controls: false,
                "controls-position": "right"
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 8
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "最低成团人数"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: baseData.groupPeopleNum,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => baseData.groupPeopleNum = $event),
                type: "number",
                onkeyup: "value=value.replace(/^[^\\d]+/g,'')"
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 8
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "计划预收位"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: baseData.expectPeopleNum,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => baseData.expectPeopleNum = $event),
                type: "number",
                onkeyup: "value=value.replace(/^[^\\d]+/g,'')"
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 8
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "最低预定人数"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: baseData.packagePeopleNum,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => baseData.packagePeopleNum = $event),
                type: "number",
                onkeyup: "value=value.replace(/^[^\\d]+/g,'')"
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 8
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "最大预定人数"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: baseData.orderPeopleNum,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => baseData.orderPeopleNum = $event),
                type: "number",
                onkeyup: "value=value.replace(/^[^\\d]+/g,'')"
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 8
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "套餐库存"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: baseData.stockNum,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => baseData.stockNum = $event),
                type: "number",
                onkeyup: "value=value.replace(/^[^\\d]+/g,'')"
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model", "disabled"]), _createVNode(DynamicParams, {
        ref_key: "priceTemplateRef",
        ref: priceTemplateRef,
        template: priceTemplateTemplate,
        data: priceTemplate.value,
        onAdd: addTemplate,
        customDel: true,
        onDelete: delTemplate,
        customAdd: true,
        operation: priceTemplateOperation,
        addText: "添加价格类型"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "价格类型",
          align: "center"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_input, {
            modelValue: scope.row.priceType,
            "onUpdate:modelValue": $event => scope.row.priceType = $event
          }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "是否占床",
          align: "center",
          width: "120"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_switch, {
            modelValue: scope.row.withBedFlag,
            "onUpdate:modelValue": $event => scope.row.withBedFlag = $event,
            "inline-prompt": "",
            "active-text": "占床",
            "inactive-text": "不占",
            "active-value": 1,
            "inactive-value": 0,
            size: "large",
            width: 60
          }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "同行价(元)",
          align: "center",
          width: "120"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_input_number, {
            modelValue: scope.row.competitorPrice,
            "onUpdate:modelValue": $event => scope.row.competitorPrice = $event,
            modelModifiers: {
              number: true
            },
            min: 0,
            precision: 2,
            controls: false,
            "controls-position": "right"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "销售价(元)",
          align: "center",
          width: "120"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_input_number, {
            modelValue: scope.row.sellPrice,
            "onUpdate:modelValue": $event => scope.row.sellPrice = $event,
            modelModifiers: {
              number: true
            },
            min: 0,
            precision: 2,
            controls: false,
            "controls-position": "right"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "划线价(元)",
          align: "center",
          width: "120"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_input_number, {
            modelValue: scope.row.markingPrice,
            "onUpdate:modelValue": $event => scope.row.markingPrice = $event,
            modelModifiers: {
              number: true
            },
            min: 0,
            precision: 2,
            controls: false,
            "controls-position": "right"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_date_picker, {
        modelValue: selectDate.value,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => selectDate.value = $event),
        type: "daterange",
        "range-separator": "~",
        "start-placeholder": "开始日期",
        "end-placeholder": "结束日期",
        "value-format": "YYYY-MM-DD",
        shortcuts: pickerOptions,
        "disabled-date": disabledDate
      }, null, 8, ["modelValue"])]), _createVNode(_component_el_radio_group, {
        modelValue: selectOperate.value,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => selectOperate.value = $event)
      }, {
        default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(operate, item => {
          return _createVNode(_component_el_radio, {
            key: item.id,
            label: item.id
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
            _: 2
          }, 1032, ["label"]);
        }), 64))]),
        _: 1
      }, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_checkbox_group, {
        modelValue: selectWeek.value,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => selectWeek.value = $event)
      }, {
        default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(weeks, item => {
          return _createVNode(_component_el_checkbox, {
            key: item.id,
            label: item.id
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
            _: 2
          }, 1032, ["label"]);
        }), 64))]),
        _: 1
      }, 8, ["modelValue"]), _createElementVNode("div", null, [_createVNode(_component_el_switch, {
        class: "calendar_switch",
        modelValue: calendarSwitch.value,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => calendarSwitch.value = $event),
        style: {
          "--el-switch-on-color": "#13ce66",
          "--el-switch-off-color": "#409eff"
        },
        "active-text": "6月",
        "inactive-text": "12月"
      }, null, 8, ["modelValue"]), _createVNode(_component_el_button, {
        type: "primary",
        onClick: execute
      }, {
        default: _withCtx(() => [_createTextVNode("批量执行")]),
        _: 1
      })])])])]);
    };
  }

};