import usePermissionsStore from "@/store/permissions";
import {nextTick} from 'vue'

export default {
  install: function (app) {
    /**
     * @description: 权限控制指令
     * @param {*}
     * @return {*}
     * @author: gumingchen
     */
    app.directive('auth', {
      mounted: (el, binding, vnode) => {
        const permissionsStore = usePermissionsStore()
        const permissions = permissionsStore.btnPermissions
        const permission = binding.value
        //如果permission是child，直接返回
        if (permission === 'child') {
          return
        }
        const result = permissions.indexOf(permission) !== -1
        const tagName = el.localName
        if (!result) {
          switch (tagName) {
            case 'button': // 按钮权限控制-删除tag
              el.remove()
              break
            case 'div': // element-plus switch 组件权限控制
              if (vnode.props && vnode.props.class && vnode.props.class.includes('el-switch')) {
                nextTick(() => {
                  el.className += ' is-disabled' // 设置禁用样式
                  const tag = el.cloneNode(true) // 深拷贝节点以解除绑定事件
                  const parent = el.parentNode
                  el.remove()
                  parent.append(tag)
                })
              }
              // 其他div标签权限控制
              else {
                el.style.display = 'none'
                el.parentNode && el.parentNode.removeChild(el);
              }
              break
          }
        }
      }
    })

    /**
     * @description: 针对click事件的权限控制
     * @param {*}
     * @return {*}
     */
    app.directive('auth-click', {
      mounted: (el, binding, vnode) => {
        const permissionsStore = usePermissionsStore()
        const permissions = permissionsStore.btnPermissions
        const permission = binding.value
        const result = permissions.indexOf(permission) !== -1
        if (!result) {
          el.style.pointerEvents = 'none'
          el.addEventListener('click', (e) => {
            e.stopPropagation()
            e.preventDefault()
          })
        }
      }
    })
  }
}
