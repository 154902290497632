import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "base_screen_header"
};
import { ref, reactive } from 'vue';
import useRoute from "@util/router";
import DataCollectTable from "@/components/table/DataCollectTable.vue";
import { COLUMN_TYPE, OP_KEY, OPERATIONS, operationsHander } from "@/components/table/tableOperations";
import { $box, $msg } from "@/common/ui/msg";
import api from "@/api/lineManager";
export default {
  __name: 'LineManager',

  setup(__props) {
    const data = ref([]);
    const total = ref(0);
    const route = useRoute();
    const config = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: getOperations
      },
      column: [{
        label: "线路图片",
        prop: "mainPic",
        type: COLUMN_TYPE.picture,
        width: 100
      }, {
        label: "线路名称",
        prop: "lineName"
      }, {
        label: "线路编号",
        prop: "lineCode",
        width: 100
      }, {
        label: "审核状态",
        prop: "flowStatusName",
        width: 100
      }, {
        label: "上架状态",
        prop: "lineStatusName",
        type: COLUMN_TYPE.switch,
        operationsKey: OPERATIONS.switch,
        activeText: "已上架",
        inactiveText: "未上架",
        activeValue: "已上架",
        inactiveValue: "未上架",
        switchWidth: 65,
        width: 100
      }, {
        label: "更新时间",
        prop: "updateTime",
        width: 100
      }, {
        label: "更新人",
        prop: "updateBy",
        width: 100
      }, {
        label: "创建时间",
        prop: "createTime",
        width: 100
      }, {
        label: "创建人",
        prop: "creator",
        width: 100
      }]
    };

    function getOperations(data) {
      switch (data.flowStatus) {
        case "1007001":
          //草稿
          return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.del, OPERATIONS.examine];

        case "1007003":
          //被驳回
          return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.del, OPERATIONS.reject];

        case "1007004":
          //审批通过
          return [OPERATIONS.check];

        default:
          return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.del];
      } // return [
      //   OPERATIONS.check,
      //   OPERATIONS.modify,
      //   OPERATIONS.del,
      // ];

    }

    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      // route.activeMeta('goods', props.type)
      route.push({
        name: "lineDetails",
        query: {
          type: "show",
          lineCode: data.lineCode
        }
      });
    });
    opHander.install(OP_KEY.modify, data => {
      // route.activeMeta('goods', props.type)
      route.push({
        name: "lineDetails",
        query: {
          type: "modify",
          lineCode: data.lineCode
        }
      });
    });
    opHander.install(OP_KEY.delete, data => {
      $box.open("删除", "确认删除线路" + data.lineName, () => {
        api.del(data.id, () => {
          $msg.succ("删除成功");
          getData();
        });
      });
    });
    opHander.install(OP_KEY.switch, data => {
      // 1004003已上架 1004002未上架
      let state = data.data.lineStatus;
      api.up(data.data.id, state, () => {
        data.resolve(true);

        if (state == "1004002") {
          $msg.succ("上架成功");
        } else {
          $msg.succ("下架成功");
        }

        getData();
      }, () => {
        data.reject();
      });
    });
    getData();

    function getData() {
      api.list({}, res => {
        data.value = res.itemList;
        total.value = res.total;
      });
    }

    function add() {
      route.push({
        name: 'lineDetails',
        query: {
          type: 'add'
        }
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_main = _resolveComponent("el-main");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          onClick: _cache[0] || (_cache[0] = $event => add())
        }, {
          default: _withCtx(() => [_createTextVNode("线路录入")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('ADD')]])]), _createVNode(DataCollectTable, {
          data: data.value,
          config: config,
          total: total.value,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"])]),
        _: 1
      })]);
    };
  }

};