import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueAMap, { initAMapApiLoader } from '@vuemap/vue-amap';
import '@vuemap/vue-amap/dist/style.css'
import { BASE } from "@net/url"
import horizontalScroll from 'el-table-horizontal-scroll'
import pinia from "@/store/index";
import permission from '@/common/permissions'
import AuthEnum from "@/common/enum";
import useRoute from "@/common/utils/router";

initAMapApiLoader({
    key: '57a3de0da08ab92b7668cb60f98b70da',
    plugins: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Geocoder'],
    serviceHost: BASE + '_AMapService'
});

const app = createApp(App)
// 添加全局方法
app.config.globalProperties.getCurCode = (key) => {
    const route = useRoute();
    if (AuthEnum[route.name()]){
        return AuthEnum[route.name()][key];
    }else {
        return 'child';
    }
}


app.use(permission)
app.use(pinia).use(router).use(VueAMap).use(horizontalScroll).mount('#app')


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// import ElementPlus from 'element-plus'
// import zhCn from 'element-plus/es/locale/lang/zh-cn'

// app.use(ElementPlus, {
//     locale: zhCn,
// })

Array.prototype.remove = function(func) {
    for (var i = this.length - 1; i >= 0; i--) {
        if (func(this[i])) {
            this.splice(i, 1);
        }
    }
    return this
}
