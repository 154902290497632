import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "applets_code_content"
};
import { ref } from "vue";
import api from "@api/config";
import { $msg } from "@/common/ui/msg";
export default {
  __name: 'AppletsCode',

  setup(__props, {
    expose: __expose
  }) {
    const dialogVisible = ref(false);
    const filePath = ref(null);
    const url = ref(null);

    function copy() {
      // 模拟 输入框
      var cInput = document.createElement("input");
      cInput.value = url.value;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效

      document.execCommand("copy");
      $msg.succ("复制成功"); // 复制成功后再将构造的标签 移除

      document.body.removeChild(cInput);
    }

    function show() {
      dialogVisible.value = true;
    }

    const TYPE = {
      goods: 1,
      hotel: 2,
      travel: 3,
      gift: 4
    };

    function getUnlimited(data) {
      api.code(data.code, TYPE[data.type], val => {
        filePath.value = val.filePath;
        url.value = val.url;
        show();
      });
    }

    __expose({
      getUnlimited
    });

    return (_ctx, _cache) => {
      const _component_el_image = _resolveComponent("el-image");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => dialogVisible.value = $event),
        title: "小程序码",
        width: "300px",
        top: "30vh",
        class: "base_dialog"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_image, {
          style: {
            "width": "200px",
            "height": "200px"
          },
          src: `data:image/png;base64,${filePath.value}`,
          fit: "fill"
        }, null, 8, ["src"])])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};