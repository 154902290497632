import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import DataCollectTable from "../table/DataCollectTable.vue";
import AppletsCode from "@com/AppletsCode.vue";
import { OP_KEY, COLUMN_TYPE, OPERATIONS, operationsHander } from "../table/tableOperations";
import useRoute from "@util/router";
import { ref } from "vue";
import api from "@api/gift";
import { $msg, $box } from "@/common/ui/msg";
export default {
  __name: 'GiftTable',
  props: {
    route: {
      type: String
    }
  },
  emits: ['getNum'],

  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const props = __props;
    const data = ref([]);
    const total = ref(0);
    const route = useRoute();
    route.watchRouter(props.route, () => {
      getData();
    });

    function getOperations(data) {
      // 0 否， 1 是
      if (data.homeFlag == 1) {
        return [OPERATIONS.check, OPERATIONS.modify, // OPERATIONS.copy, 
        OPERATIONS.del, OPERATIONS.giftCode, OPERATIONS.appletsCode];
      } else {
        return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.del, OPERATIONS.giftCode, OPERATIONS.appletsCode, OPERATIONS.homeShow];
      }
    }

    const config = {
      showIndex: true,
      operations: {
        label: '操作',
        width: 130,
        operations: getOperations
      },
      column: [{
        label: '礼包图片',
        prop: 'mainPic',
        type: COLUMN_TYPE.picture,
        width: 100
      }, {
        label: '礼包名称',
        prop: 'name'
      }, {
        label: '上架状态',
        prop: 'giftStatus',
        type: COLUMN_TYPE.switch,
        operationsKey: OPERATIONS.switch,
        activeText: '已上架',
        inactiveText: '未上架',
        activeValue: "1004003",
        // 1004002 未上架、1004003 已上架
        inactiveValue: "1004002",
        switchWidth: 65,
        width: 100
      }, {
        label: '首页展示',
        prop: 'homeFlagVal',
        width: 100
      }, {
        label: '礼包价（元）',
        prop: 'price',
        width: 120
      }, {
        label: '已发放数量',
        prop: 'provideNum',
        width: 120
      }, {
        label: '失效时间',
        prop: 'validTime',
        width: 100
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 100
      }, {
        label: '创建人',
        prop: 'creator',
        width: 100
      }, {
        label: '更新时间',
        prop: 'updateTime',
        width: 100
      }, {
        label: '更新人',
        prop: 'updateBy',
        width: 100
      }]
    };
    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      route.push({
        name: 'giftdetails',
        query: {
          type: 'show',
          code: data.giftCode
        }
      });
    });
    opHander.install(OP_KEY.modify, data => {
      route.push({
        name: 'giftdetails',
        query: {
          type: 'modify',
          code: data.giftCode
        }
      });
    });
    opHander.install(OP_KEY.copy, data => {
      $box.open("复制", "确认复制礼包" + data.name, () => {
        api.clone(data.giftCode, () => {
          $msg.succ("复制成功");
          getData();
          emit('getNum');
        });
      });
    });
    opHander.install(OP_KEY.delete, data => {
      $box.open("删除", "确认删除礼包" + data.name, () => {
        api.delete(data.giftCode, () => {
          $msg.succ("删除成功");
          getData();
          emit('getNum');
        });
      });
    });
    opHander.install(OP_KEY.switch, data => {
      // 1004002 未上架、1004003 已上架
      let status = data.data.giftStatus == "1004003" ? "1004002" : "1004003";
      api.status(data.data.giftCode, status, () => {
        data.resolve(true);

        if (status == '1004003') {
          $msg.succ("上架成功");
        } else {
          $msg.succ("下架成功");
        }

        getData();
        emit('getNum');
      }, () => {
        data.reject();
      });
    });
    opHander.install(OP_KEY.giftCode, data => {
      route.push({
        name: 'giftcodemanager',
        query: {
          code: data.giftCode
        }
      });
    });
    opHander.install(OP_KEY.homeShow, data => {
      $box.open("操作", "是否将" + data.name + "设为首页展示", () => {
        api.homeflag(data.giftCode, () => {
          $msg.succ("设为首页展示成功");
          getData();
        });
      });
    });
    const appletsCodeRef = ref(null);
    opHander.install(OP_KEY.appletsCode, data => {
      appletsCodeRef.value.getUnlimited({
        code: data.giftCode,
        type: "gift"
      });
    });
    getData();

    function getData() {
      let routeQuery = route.query();
      api.list({
        name: routeQuery.name,
        status: routeQuery.status,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        total.value = val.total;
        data.value = val.list.map(e => {
          // 0 否， 1 是
          if (e.homeFlag == 1) {
            e.homeFlagVal = '是';
          } else {
            e.homeFlagVal = '否';
          }

          return e;
        });
      });
    }

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", null, [_createVNode(DataCollectTable, {
        ref: "giftTableRef",
        data: data.value,
        config: config,
        onOperationsHander: _unref(opHander).receive,
        total: total.value
      }, null, 8, ["data", "onOperationsHander", "total"]), _createVNode(AppletsCode, {
        ref_key: "appletsCodeRef",
        ref: appletsCodeRef
      }, null, 512)]);
    };
  }

};