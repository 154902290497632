import { POST } from "@net/service.js";
import configApi from "./config"
import $ from "@util/base";
import page from "./page"

const URL = {
    list: 'transactionlog_list',
    num: 'transactionlog_money',
    details: 'transactionlog_detail',
    export: 'transactionlog_list_export',
    baseList: "finance_shop_list"
}

const api = {
    base(succ) {
        configApi.base(succ)
    },
    list(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.transactionType) && params.transactionType != 'all') {
            paramsData.transactionType = parseInt(params.transactionType)
        }
        if ($.isNotEmpty(params.mainBusiType) && params.mainBusiType != 'all') {
            paramsData.mainBusiType = params.mainBusiType
        }
        if ($.isNotEmpty(params.base) && params.base != 'all') {
            paramsData.shopCode = params.base
        }
        if ($.isNotEmpty(params.name)) {
            paramsData.name = params.name
        }
        if ($.isNotEmpty(params.name1)) {
            paramsData.name = params.name1
        }
        if ($.isNotEmpty(params.payType)) {
            paramsData.payType = params.payType
        }
        if ($.isNotEmpty(params.sku) && $.isNotEmpty(params.type)) {
            paramsData.itemCode = params.sku
            paramsData.itemType = parseInt(params.type)
        }
        if ($.isNotEmpty(params.date)) {
            paramsData.startTime = params.date[0]
            paramsData.endTime = params.date[1]
        }
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.list,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    num(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.transactionType) && params.transactionType != 'all') {
            paramsData.transactionType = parseInt(params.transactionType)
        }
        if ($.isNotEmpty(params.mainBusiType) && params.mainBusiType != 'all') {
            paramsData.mainBusiType = params.mainBusiType
        }
        if ($.isNotEmpty(params.base) && params.base != 'all') {
            paramsData.shopCode = params.base
        }
        if ($.isNotEmpty(params.name)) {
            paramsData.name = params.name
        }
        if ($.isNotEmpty(params.name1)) {
            paramsData.name = params.name1
        }
        if ($.isNotEmpty(params.payType)) {
            paramsData.payType = params.payType
        }
        if ($.isNotEmpty(params.sku) && $.isNotEmpty(params.type)) {
            paramsData.itemCode = params.sku
            paramsData.itemType = parseInt(params.type)
        }
        if ($.isNotEmpty(params.date)) {
            paramsData.startTime = params.date[0]
            paramsData.endTime = params.date[1]
        }

        let requestOptions = {
            path: URL.num,
            params: paramsData,
            succ: val => {
                succ([val.totalPay, val.totalRefund, val.balance])
            }
        }
        POST(requestOptions)
    },
    details(code, succ) {
        let requestOptions = {
            path: URL.details,
            params: { mainOrderCode: code },
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    exportData(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.transactionType) && params.transactionType != 'all') {
            paramsData.transactionType = parseInt(params.transactionType)
        }
        if ($.isNotEmpty(params.mainBusiType) && params.mainBusiType != 'all') {
            paramsData.mainBusiType = params.mainBusiType
        }
        if ($.isNotEmpty(params.payType)) {
            paramsData.payType = params.payType
        }
        if ($.isNotEmpty(params.date)) {
            paramsData.startTime = params.date[0]
            paramsData.endTime = params.date[1]
        }
        let requestOptions = {
            path: URL.export,
            isLoading: true,
            params: paramsData,
            succ: succ,
        };
        POST(requestOptions);
    },
    baseList(name, page, pageTotal, succ) {
        let paramsData = {}

        if ($.isNotEmpty(name)) {
            paramsData.name = name;
        }
        if ($.isNotEmpty(page)) {
            paramsData.page = parseInt(page);
        } else {
            paramsData.page = 1
        }
        if ($.isNotEmpty(pageTotal)) {
            paramsData.pageTotal = parseInt(pageTotal);
        }
        let requestOptions = {
            path: URL.baseList,
            isLoading: true,
            params: paramsData,
            succ: succ,
        };
        POST(requestOptions);
    }
}

export default api