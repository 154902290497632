import { POST } from "@net/service.js";
import configApi from "./config"
import $ from "@util/base";
import page from "./page"

const URL = {
    list: 'marketing_classify_list',
    add: 'marketing_classify_add',
    del: 'marketing_classify_del',
    update: 'marketing_classify_update',
    productGet: 'marketing_classify_product_get',
    productAdd: 'marketing_classify_product_add',
    productDel: 'marketing_classify_product_del',
};

const api = {
    list(params, succ) {
        let paramsData = {}
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.list,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    add(params, succ) {
        let requestOptions = {
            path: URL.add,
            params: params,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    update(params, succ) {
        let requestOptions = {
            path: URL.update,
            params: params,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    del(code, succ) {
        let requestOptions = {
            path: URL.del,
            params: { code: code },
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    productGet(params, succ) {
        let paramsData = {}
        paramsData.code = params.code
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.productGet,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    productAdd(params, succ) {
        let requestOptions = {
            path: URL.productAdd,
            params: params,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    productDel(param, succ) {
        let requestOptions = {
            path: URL.productDel,
            params: param,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    }
}

export default api
