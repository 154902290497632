import { createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref } from "vue";
const _hoisted_1 = {
  class: "registration_list"
};
const _hoisted_2 = {
  class: "base_screen_header"
};
const _hoisted_3 = {
  class: "base_main_margin_top"
};
import BackTitleView from "@com/BackTitleView.vue";
import DataCollectTable from '@com/table/DataCollectTable.vue';
import { OP_KEY, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import { ref, reactive, computed } from 'vue';
import { Search } from '@element-plus/icons-vue';
import useRoute from "@util/router";
import api from "@api/groupRemain";
import $ from "@util/base";
import { $msg } from '@/common/ui/msg';
export default {
  __name: 'RegistrationList',

  setup(__props) {
    const route = useRoute();
    const total = ref(0);
    const data = ref([]); // function getOperations() {
    //   return [OPERATIONS.check]
    // }

    const tableConfig = {
      showIndex: true,
      // operations: {
      //   label: '操作',
      //   width: 120,
      //   operations: getOperations
      // },
      column: [{
        label: '订单编号',
        prop: 'orderCode'
      }, {
        label: '下单时间',
        prop: 'createTime'
      }, {
        label: '订单状态',
        prop: 'orderStatus',
        width: 100
      }, {
        label: '归属基地',
        prop: 'shopName',
        width: 150
      }, {
        label: '预订人',
        prop: 'guestName',
        width: 150
      }, {
        label: '联系方式',
        prop: 'phoneNumber',
        width: 150
      }, {
        label: '身份证信息',
        prop: 'identityCardNumber',
        width: 150
      }, {
        label: '签署合同状态',
        prop: 'isSign',
        width: 150
      }]
    };
    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {// route.push({ name: 'registrationlist', query: { code: data.flowData } })
    });
    const resultGroupData = ref([]);
    const resultGroupColumn = [{
      label: '发团日期',
      prop: 'startGroupTime'
    }, {
      label: '套餐名称+团号',
      prop: 'nameAndCode'
    }, {
      label: '最大收客',
      prop: 'maxHoldVisitor',
      width: 100
    }, {
      label: '未签/已签合同',
      prop: 'whetherSign',
      width: 150
    }, {
      label: '未收款/已收款',
      prop: 'whetherReceived',
      width: 150
    }, {
      label: '占位/已确认',
      prop: 'whetherHolder',
      width: 150
    }, {
      label: '余位',
      prop: 'surplusSeat',
      width: 100
    }];
    getData();
    route.watchRouter("registrationlist", () => {
      getData();
    });

    function getData() {
      let routeQuery = route.query();
      api.registrationList({
        groupCode: routeQuery.code,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        data.value = val.itemList;
        total.value = val.total;
        resultGroupData.value = handleResultGroup(val.resultGroupItem);
      });
    }

    function handleResultGroup(data) {
      let obj = {}; // 套餐名称+团号

      obj.nameAndCode = `${data.packageName ? data.packageName : '-'}/${data.groupCode}`; // 未签/已签合同

      obj.whetherSign = `${data.noSign}/${data.isSign}`; // 未收款/已收款

      obj.whetherReceived = `${data.uncollected}/${data.received}`; // 占位/已确认

      obj.whetherHolder = `${data.holder}/${data.isHolder}`;
      return [{ ...data,
        ...obj
      }];
    }

    function getExport() {
      let routeQuery = route.query();
      api.registrationExport({
        groupCode: routeQuery.code
      }, val => {
        $.download(val.url, "报名情况列表导出");
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_table = _resolveComponent("el-table");

      const _component_el_main = _resolveComponent("el-main");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(BackTitleView, {
          title: "报名情况列表"
        }), _createVNode(_component_el_button, {
          type: "primary",
          text: "",
          onClick: getExport
        }, {
          default: _withCtx(() => [_createTextVNode("导出")]),
          _: 1
        })]), _createVNode(_component_el_table, {
          data: resultGroupData.value,
          border: ""
        }, {
          default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(resultGroupColumn, (item, index) => {
            return _createVNode(_component_el_table_column, {
              key: index,
              label: item.label,
              prop: item.prop,
              width: item.width,
              align: "center"
            }, null, 8, ["label", "prop", "width"]);
          }), 64))]),
          _: 1
        }, 8, ["data"]), _createElementVNode("div", _hoisted_3, [_createVNode(DataCollectTable, {
          data: data.value,
          total: total.value,
          config: tableConfig,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"])])]),
        _: 1
      })]);
    };
  }

};