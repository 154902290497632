import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "base_screen_header"
};
const _hoisted_2 = {
  class: "base_main_header"
};
const _hoisted_3 = {
  class: "dialog-footer"
};
import DataCollectTable from "@com/table/DataCollectTable.vue";
import { OP_KEY, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import { $msg, $box } from "@ui/msg";
import { ref, reactive } from "vue";
import { Search } from "@element-plus/icons-vue";
import api from "@api/coupon";
import $ from "@util/base";
import useRoute from "@util/router";
export default {
  __name: 'CouponManager',

  setup(__props) {
    const route = useRoute();
    const name = ref(route.query().name || "");
    const total = ref(0);
    const data = ref([]);
    route.watchRouter("couponmanager", () => {
      getData();
    });
    getData();

    function getData() {
      let routeQuery = route.query();
      let params = {
        name: routeQuery.name,
        page: routeQuery.p,
        size: routeQuery.s
      };
      api.list(params, val => {
        total.value = val.total;
        data.value = val.list;
      });
    }

    function add() {
      route.push({
        name: 'coupondetails',
        query: {
          type: 'add'
        }
      });
    }

    function getOperations(data) {
      let op = [];

      if (data.enableStatus == 1001001) {
        op.push(OPERATIONS.check);
        op.push(OPERATIONS.grant);
        op.push(OPERATIONS.couponStop);
      } else {
        op.push(OPERATIONS.check);
        op.push(OPERATIONS.couponStart);
      }

      if (data.provideNum > 0) {
        op.push(OPERATIONS.grantRecord);
      }

      op.push(OPERATIONS.modify);
      return op;
    }

    const config = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: getOperations
      },
      column: [{
        label: "名称",
        prop: "name",
        width: 200
      }, {
        label: "已发放",
        prop: "provideNum",
        width: 100
      }, {
        label: "已核销",
        prop: "usedNum",
        width: 100
      }, {
        label: "当前状态",
        prop: "enableStatusName",
        width: 100
      }, {
        label: "有效范围",
        prop: "lifespan"
      }, {
        label: "优惠内容",
        prop: "couponDesc"
      }, {
        label: "创建时间",
        prop: "createTime"
      }, {
        label: "创建人",
        prop: "creator",
        width: 100
      }]
    };
    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      route.push({
        name: 'coupondetails',
        query: {
          type: 'show',
          code: data.couponCode
        }
      });
    });
    opHander.install(OP_KEY.modify, data => {
      route.push({
        name: 'coupondetails',
        query: {
          type: 'modify',
          code: data.couponCode
        }
      });
    });
    opHander.install(OP_KEY.grant, data => {
      qrcode.value = '';
      provideFlagForm.value.provideFlag = '1';
      provideFlagForm.value.sendCrowds = [];
      provideFlagForm.value.sendCrowd = '';
      provideFlagForm.value.phones = '';
      provideFlagForm.value.phone = [];
      dialogVisible.value = true;
      provideFlagForm.value.couponCode = data.couponCode;
      resetForm();
    });
    opHander.install(OP_KEY.couponStop, data => {
      api.stop(data.couponCode, val => {
        $msg.succ("停用优惠券成功");
        getData();
      });
    });
    opHander.install(OP_KEY.couponStart, data => {
      api.start(data.couponCode, val => {
        $msg.succ("启用优惠券成功");
        getData();
      });
    });
    opHander.install(OP_KEY.grantRecord, data => {
      route.push({
        name: 'couponGrantRecord',
        query: {
          code: data.couponCode
        }
      });
    });

    function searchName(val) {
      route.replaceQuery("name", val);
    }

    const dialogVisible = ref(false);
    const provideFlagFormRef = ref('');
    const provideFlagForm = ref({
      couponCode: null,
      provideFlag: '1',
      sendCrowds: [],
      sendCrowd: '',
      phones: '',
      phone: []
    });
    const provideFlagRules = {
      provideFlag: [{
        required: true,
        message: '请选择发放群体',
        trigger: 'change'
      }]
    };
    const qrcode = ref('');

    const submitForm = () => {
      provideFlagFormRef.value.validate(valid => {
        if (valid) {
          if (provideFlagForm.value.provideFlag == '1') {
            if (provideFlagForm.value.sendCrowds.indexOf('1') > -1 && !provideFlagForm.value.phones) {
              $msg.error("请输入用户手机号");
              return;
            }

            provideFlagForm.value.phone = provideFlagForm.value.phones.split(',');

            if (provideFlagForm.value.sendCrowds.length > 0) {
              provideFlagForm.value.sendCrowd = provideFlagForm.value.sendCrowds[0];
            }

            api.provide(provideFlagForm.value, val => {
              $msg.succ("发放优惠券成功");
              dialogVisible.value = false;
              getData();
            });
          } else {
            api.getQrcode({
              code: provideFlagForm.value.couponCode,
              page: '1'
            }, val => {
              qrcode.value = val.filePath;
            });
          }
        }
      });
    };

    const resetForm = () => {
      if (!provideFlagFormRef.value) return;
      provideFlagFormRef.value.resetFields();
    };

    function getExport() {
      let routeQuery = route.query();
      api.export({
        name: routeQuery.name
      }, val => {
        $.download(val.url, "优惠券列表导出");
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_main = _resolveComponent("el-main");

      const _component_el_radio = _resolveComponent("el-radio");

      const _component_el_radio_group = _resolveComponent("el-radio-group");

      const _component_el_checkbox = _resolveComponent("el-checkbox");

      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");

      const _component_el_image = _resolveComponent("el-image");

      const _component_el_dialog = _resolveComponent("el-dialog");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          onClick: add,
          type: "primary",
          style: {
            "margin-right": "20px"
          }
        }, {
          default: _withCtx(() => [_createTextVNode("新增优惠券 ")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('ADD')]])]), _createVNode(_component_el_form, {
          inline: true,
          class: "base_search_form",
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: name.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => name.value = $event),
              "onKeyup[native]": searchName,
              onChange: searchName,
              placeholder: "优惠券名称",
              "suffix-icon": _unref(Search),
              clearable: ""
            }, null, 8, ["modelValue", "suffix-icon"])]),
            _: 1
          })]),
          _: 1
        })]), _createElementVNode("div", _hoisted_2, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          text: "",
          onClick: getExport
        }, {
          default: _withCtx(() => [_createTextVNode("导出")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('EXPORT')]])]), _createVNode(DataCollectTable, {
          data: data.value,
          total: total.value,
          config: config,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"])]),
        _: 1
      }), _createVNode(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => dialogVisible.value = $event),
        title: "发放优惠券",
        width: "400px",
        top: "35vh",
        class: "base_dialog"
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_3, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(provideFlagForm.value.provideFlag == '1' ? '保存' : '生成二维码'), 1)]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: _cache[5] || (_cache[5] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "provideFlagFormRef",
          ref: provideFlagFormRef,
          model: provideFlagForm.value,
          rules: provideFlagRules,
          "label-position": "top"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "发放方式",
            prop: "provideFlag"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_radio_group, {
              modelValue: provideFlagForm.value.provideFlag,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => provideFlagForm.value.provideFlag = $event)
            }, {
              default: _withCtx(() => [_createVNode(_component_el_radio, {
                label: "1"
              }, {
                default: _withCtx(() => [_createTextVNode("立即发放")]),
                _: 1
              }), _createVNode(_component_el_radio, {
                label: "2"
              }, {
                default: _withCtx(() => [_createTextVNode("线下发放")]),
                _: 1
              })]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), provideFlagForm.value.provideFlag == '1' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "发放人群",
            prop: "sendCrowds"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_checkbox_group, {
              modelValue: provideFlagForm.value.sendCrowds,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => provideFlagForm.value.sendCrowds = $event)
            }, {
              default: _withCtx(() => [_createVNode(_component_el_checkbox, {
                label: "1"
              }, {
                default: _withCtx(() => [_createTextVNode("指定用户")]),
                _: 1
              })]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), provideFlagForm.value.provideFlag == '1' && provideFlagForm.value.sendCrowds.indexOf('1') > -1 ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            label: "指定用户"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "textarea",
              modelValue: provideFlagForm.value.phones,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => provideFlagForm.value.phones = $event),
              placeholder: "请输入用户手机号，多个用户用逗号隔开",
              autosize: "{ minRows: 2, maxRows: 4 }"
            }, null, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), _createVNode(_component_el_form_item, {
            label: ""
          }, {
            default: _withCtx(() => [provideFlagForm.value.provideFlag == '2' && qrcode.value != '' ? (_openBlock(), _createBlock(_component_el_image, {
              key: 0,
              src: 'data:image/png;base64,' + qrcode.value,
              style: {
                "width": "200px"
              }
            }, null, 8, ["src"])) : _createCommentVNode("", true)]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }

};