import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "base_manager"
};
const _hoisted_2 = {
  class: "base_screen_header"
};
import DataCollectTable from '@com/table/DataCollectTable.vue';
import { OP_KEY, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import { ref, reactive } from 'vue';
import $ from "@util/base";
import { $msg, $box } from "@ui/msg";
import { Search } from '@element-plus/icons-vue';
import useRoute from "@util/router";
import api from "@api/base";
export default {
  __name: 'BaseManager',

  setup(__props) {
    const route = useRoute();
    route.watchRouter('basemanager', () => {
      getBaseList();
    });
    const name = ref(route.query().name || '');

    function searchName(val) {
      route.replaceQuery('name', val);
    }

    const data = ref([]);
    const total = ref(0);

    function roleOperations() {
      return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.del];
    }

    const config = {
      showIndex: true,
      operations: {
        label: '操作',
        width: 120,
        operations: roleOperations
      },
      column: [{
        label: '基地名称',
        prop: 'name',
        width: 180
      }, {
        label: '基地地址',
        prop: 'address'
      }, {
        label: '创建人',
        prop: 'creatorName',
        width: 100
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 100
      }]
    };

    function addBase() {
      route.push({
        name: 'basedetails',
        query: {
          type: 'add'
        }
      });
    }

    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      route.push({
        name: 'basedetails',
        query: {
          type: 'show',
          code: data.id
        }
      });
    }); // opHander.install(OP_KEY.baseData, (data) => {
    //   route.push({ name: 'overview', query: { base: data.baseCode } })
    // })

    opHander.install(OP_KEY.modify, data => {
      route.push({
        name: 'basedetails',
        query: {
          type: 'modify',
          code: data.id
        }
      });
    });
    opHander.install(OP_KEY.delete, data => {
      $box.open("删除确认", `是否删除${data.name}`, () => {
        api.delete(data.id, () => {
          $msg.succ("删除成功");
          getBaseList();
        });
      });
    });
    getBaseList();

    function getBaseList() {
      let routeQuery = route.query();
      api.baseList({
        name: routeQuery.name,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        total.value = val.total;
        data.value = val.itemList;
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_main = _resolveComponent("el-main");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          onClick: addBase
        }, {
          default: _withCtx(() => [_createTextVNode("新增基地")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('ADD')]]), _createVNode(_component_el_form, {
          inline: true,
          class: "base_search_form",
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: name.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => name.value = $event),
              "onKeyup[native]": searchName,
              onChange: searchName,
              placeholder: "基地名称",
              clearable: "",
              "suffix-icon": _unref(Search)
            }, null, 8, ["modelValue", "suffix-icon"])]),
            _: 1
          })]),
          _: 1
        })]), _createVNode(DataCollectTable, {
          data: data.value,
          total: total.value,
          config: config,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"])]),
        _: 1
      })]);
    };
  }

};