import {POST} from "@net/service.js";
import page from "@/api/page";

const URL = {
  get: 'mrp_install_get',
  update: 'mrp_install_update',
};

const api = {
  get(succ) {
    let requestOptions = {
      path: URL.get,
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  },
  update(params, succ) {
    let requestOptions = {
      path: URL.update,
      params: params,
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  },
}

export default api
