import { renderSlot as _renderSlot, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "dynamic_params_layout"
};
const _hoisted_2 = {
  class: "rm_operation_layout"
};
const _hoisted_3 = {
  class: "rm_operation_item"
};
const _hoisted_4 = {
  class: "rm_operation_item"
};
const _hoisted_5 = {
  class: "rm_operation_item"
};
const _hoisted_6 = {
  class: "rm_operation_item"
};
const _hoisted_7 = {
  class: "rm_operation_item"
};
const _hoisted_8 = {
  key: 1,
  class: "add_layout"
};
import { reactive, watch, onBeforeUnmount } from "vue";
import { onMounted } from "vue";
import $ from "@util/base";
import Sortable from "sortablejs";
import { Delete, Edit, Rank, CopyDocument } from "@element-plus/icons-vue";
export default {
  __name: 'DynamicParams',
  props: {
    template: {
      type: Object
    },
    data: {
      type: Array
    },
    multipleType: {
      type: Boolean,
      default: false
    },
    showDefault: {
      type: Boolean,
      default: true
    },
    operation: {
      type: Object,
      default: () => {
        return {
          copy: false,
          move: false,
          modify: false,
          del: false,
          add: false,
          check: false
        };
      }
    },
    addText: {
      type: String,
      default: "添加选项"
    },
    multipleAddText: {
      type: Array,
      default: () => ["添加选项", "添加选项", "添加选项"]
    },
    customAdd: {
      type: Boolean,
      default: false
    },
    customDel: {
      type: Boolean,
      default: false
    },
    customMove: {
      type: Boolean,
      default: false
    },
    typeProp: String,
    type1Code: String,
    type2Code: String,
    type3Code: String,
    spanMethod: Function
  },
  emits: ["add", "check", "modify", "delete", "move"],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const emit = __emit;
    const props = __props;
    const tableIdName = "params-" + $.uuid8();
    var columnWidth = 30;

    for (const key in props.operation) {
      if (props.operation[key] && key != "add") {
        columnWidth += 30;
      }
    }

    watch(() => props.data, () => {
      data.value = defaultData();
      sortData.value = data.value;
    });

    function defaultData() {
      let dataNotEmpty = $.isNotEmpty(props.data);
      return props.showDefault || dataNotEmpty ? dataNotEmpty ? props.data.map(e => {
        e.tempId = $.uuid8();
        return e;
      }) : [templateData()] : [];
    }

    const data = reactive({
      value: defaultData()
    });

    function delOperation(index) {
      if (props.customDel) {
        // data.value.splice(index, 1);
        emit("delete", index);
      } else {
        data.value.splice(index, 1);
      }
    }

    function templateData() {
      let addData = {
        tempId: $.uuid8()
      };
      let template = $.deepCopy(props.template || props.data[0]);

      for (let key in template) {
        addData[key] = template[key];
      }

      return addData;
    }

    function add() {
      if (props.customAdd) {
        emit("add");
      } else {
        addData(templateData());
      }
    }

    function addType1() {
      let data = templateData();
      data[props.typeProp] = props.type1Code;
      addData(data);
    }

    function addType2() {
      let data = templateData();
      data[props.typeProp] = props.type2Code;
      addData(data);
    }

    function addType3() {
      let data = templateData();
      data[props.typeProp] = props.type3Code;
      addData(data);
    }

    function addData(val) {
      val.tempId = $.uuid8();
      data.value.push(val);
    }

    function setData(val) {
      if ($.isEmpty(val)) {
        data.value = defaultData();
      } else {
        data.value = val;
      }

      sortData.value = data.value;
    }

    function modifyData(index, val) {
      data.value[index] = val;
    }

    function clear() {
      data.value = defaultData();
      sortData.value = data.value;
    }

    function getValue() {
      return $.deepCopy(data.value);
    }

    function getReactiveValue() {
      return data.value;
    }

    const sortData = reactive({
      value: data.value
    });
    var sortable = null;
    onMounted(() => {
      if (props.operation.move) {
        const table = document.querySelector("#" + tableIdName + " .el-table__body-wrapper tbody");
        sortable = Sortable.create(table, {
          handle: ".move",
          animation: 150,
          onEnd: function (evt) {
            const currRow = sortData.value.splice(evt.oldIndex, 1)[0];
            sortData.value.splice(evt.newIndex, 0, currRow);

            if (props.customMove) {
              emit("move", sortData.value);
            }
          }
        });
      }
    });
    onBeforeUnmount(() => {
      if (sortable) {
        sortable.destroy();
      }
    });

    __expose({
      getValue,
      getReactiveValue,
      addData,
      setData,
      modifyData,
      clear
    });

    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_table = _resolveComponent("el-table");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_table, {
        id: tableIdName,
        data: data.value,
        "row-key": "tempId",
        border: "",
        "span-method": __props.spanMethod
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default"), __props.operation.move || __props.operation.modify || __props.operation.del || __props.operation.check ? (_openBlock(), _createBlock(_component_el_table_column, {
          key: 0,
          label: "操作",
          width: _unref(columnWidth),
          align: "center"
        }, {
          default: _withCtx(scope => [_createElementVNode("div", _hoisted_2, [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [__props.operation.move ? (_openBlock(), _createBlock(_component_el_icon, {
            key: 0,
            class: "move pointer",
            size: 18
          }, {
            default: _withCtx(() => [_createVNode(_unref(Rank))]),
            _: 1
          })) : _createCommentVNode("", true)])), [[_directive_auth, _ctx.getCurCode('MOVE')]]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [__props.operation.copy ? (_openBlock(), _createBlock(_component_el_icon, {
            key: 0,
            class: "copy pointer",
            onClick: $event => _ctx.$emit('copy', scope.$index),
            size: 18
          }, {
            default: _withCtx(() => [_createVNode(_unref(CopyDocument))]),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])), [[_directive_auth, _ctx.getCurCode('COPY')]]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [__props.operation.modify ? (_openBlock(), _createBlock(_component_el_icon, {
            key: 0,
            class: "pointer",
            onClick: $event => _ctx.$emit('modify', scope.$index),
            size: 18
          }, {
            default: _withCtx(() => [_createVNode(_unref(Edit))]),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])), [[_directive_auth, _ctx.getCurCode('MODIFY')]]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [__props.operation.del ? (_openBlock(), _createBlock(_component_el_icon, {
            key: 0,
            class: "pointer",
            onClick: $event => delOperation(scope.$index),
            size: 18
          }, {
            default: _withCtx(() => [_createVNode(_unref(Delete))]),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])), [[_directive_auth, _ctx.getCurCode('DELETE')]]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [__props.operation.check ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            size: "small",
            type: "primary",
            text: "",
            onClick: $event => _ctx.$emit('check', scope.$index)
          }, {
            default: _withCtx(() => [_createTextVNode(" 查看 ")]),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])), [[_directive_auth, _ctx.getCurCode('CHECK')]])])]),
          _: 1
        }, 8, ["width"])) : _createCommentVNode("", true)]),
        _: 3
      }, 8, ["data", "span-method"]), __props.operation.add && !__props.multipleType ? _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "dashed_but",
        onClick: add
      }, [_createTextVNode(_toDisplayString(__props.addText), 1)])), [[_directive_auth, _ctx.getCurCode('ADD')]]) : _createCommentVNode("", true), !__props.operation.add && __props.multipleType ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", {
        class: "dashed_but",
        onClick: addType1
      }, _toDisplayString(__props.multipleAddText[0]), 1), _createElementVNode("div", {
        class: "dashed_but",
        onClick: addType2
      }, _toDisplayString(__props.multipleAddText[1]), 1), _createElementVNode("div", {
        class: "dashed_but",
        onClick: addType3
      }, _toDisplayString(__props.multipleAddText[2]), 1)])), [[_directive_auth, _ctx.getCurCode('ADD')]]) : _createCommentVNode("", true)]);
    };
  }

};