import { openBlock as _openBlock, createBlock as _createBlock } from "vue";
import SingleFieldManager from "@com/SingleFieldManager.vue";
import useRoute from "@util/router";
export default {
  __name: 'TakeGoodsManager',

  setup(__props) {
    const config = {
      name: "自提点",
      type: 'deliveryStation',
      route: 'takegoodsmanager'
    };
    const route = useRoute();

    function add() {
      route.push({
        name: 'takegoodsdetails',
        query: {
          type: 'add'
        }
      });
    }

    function modify(data) {
      route.push({
        name: 'takegoodsdetails',
        query: {
          type: 'modify',
          code: data.id
        }
      });
    }

    function show(data) {
      route.push({
        name: 'takegoodsdetails',
        query: {
          type: 'show',
          code: data.id
        }
      });
    }

    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(SingleFieldManager, {
        config: config,
        customAdd: true,
        onAdd: add,
        onModify: modify,
        onShow: show
      });
    };
  }

};