// 概览
import OverView from '@view/overview/OverView.vue'


export default [{
    path: '/',
    redirect: 'overview'
}, {
    path: '/overview',
    name: 'overview',
    component: OverView,
    meta: {
        activeMainMenu: 'overview',
        activeSubMenu: ''
    }
}]