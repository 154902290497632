import { createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref } from "vue";
const _hoisted_1 = {
  class: "registration_list"
};
const _hoisted_2 = {
  class: "base_screen_header"
};
const _hoisted_3 = {
  class: "base_main_margin_top"
};
const _hoisted_4 = {
  class: "dialog-footer"
};
import BackTitleView from "@com/BackTitleView.vue";
import DataCollectTable from '@com/table/DataCollectTable.vue';
import { OP_KEY, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import { ref } from 'vue';
import useRoute from "@util/router";
import api from "@api/groupRemain";
import $ from "@util/base";
import { $msg, $box } from "@ui/msg";
export default {
  __name: 'OccupyManager',

  setup(__props) {
    const route = useRoute();
    const total = ref(0);
    const data = ref([]);

    function getOperations(data) {
      // 1050001未确认 1050002确认 1050003取消
      if (data.holderStatus === "1050001") {
        return [OPERATIONS.modify, OPERATIONS.cancelOccupy, OPERATIONS.confirmOccupy];
      }
    }

    const tableConfig = {
      showIndex: true,
      operations: {
        label: '操作',
        width: 120,
        operations: getOperations
      },
      column: [{
        label: '客人姓名',
        prop: 'guestName'
      }, {
        label: '电话',
        prop: 'phoneNumber'
      }, {
        label: '身份证号码',
        prop: 'idCard'
      }, {
        label: '人数',
        prop: 'peopleNum',
        width: 100
      }, {
        label: '销售人员',
        prop: 'sales',
        width: 150
      }, {
        label: '占位日期',
        prop: 'createTime',
        width: 150
      }, {
        label: '占位状态',
        prop: 'holderStatusName',
        width: 100
      }]
    };
    const dialogVisible = ref(false);
    const title = ref("");
    const formRef = ref(null);
    const occupyForm = ref({});
    const occupyRules = {
      guestName: [{
        required: true,
        message: '请输入客人姓名',
        trigger: 'blur'
      }],
      phoneNumber: [{
        required: true,
        message: '请输入客人电话',
        trigger: 'blur'
      }],
      idCard: [{
        required: true,
        message: '请输入客人身份证号码',
        trigger: 'blur'
      }],
      peopleNum: [{
        required: true,
        message: '请输入占位人数',
        trigger: 'blur'
      }],
      // b5: [{
      //   required: true,
      //   message: '请选择占位日期',
      //   trigger: 'change',
      // }],
      sales: [{
        required: true,
        message: '请输入销售人员',
        trigger: 'blur'
      }]
    };
    var occupyId = null;

    function add() {
      title.value = "新增占位";
      occupyId = null;
      dialogVisible.value = true;
      resetForm();
    }

    const opHander = operationsHander();
    opHander.install(OP_KEY.modify, data => {
      title.value = "修改占位";
      occupyId = data.id;
      dialogVisible.value = true;
      resetForm();
      api.getOccupy(data.id, val => {
        occupyForm.value = {
          guestName: val.guestName,
          phoneNumber: val.phoneNumber,
          idCard: val.idCard,
          peopleNum: val.peopleNum,
          sales: val.sales,
          packageCode: val.packageCode,
          holderStatus: val.holderStatus
        };
      });
    });
    opHander.install(OP_KEY.cancelOccupy, data => {
      $box.open("取消确认", `是否取消${data.name}的占位`, () => {
        api.statusOccupy(data.id, "1050003", () => {
          getData();
          $msg.succ("取消成功");
        });
      });
    });
    opHander.install(OP_KEY.confirmOccupy, data => {
      $box.open("占位确认", `是否确认${data.name}的占位`, () => {
        api.statusOccupy(data.id, "1050002", () => {
          getData();
          $msg.succ("确认成功");
        });
      });
    });

    const submitForm = formEl => {
      occupyForm.value.travelDate = resultGroupData.value[0].startGroupTime;
      formEl.validate((valid, fields) => {
        if (valid) {
          if (occupyId) {
            api.updateOccupy(occupyForm.value, occupyId, () => {
              $msg.succ("修改成功");
              dialogVisible.value = false;
              getData();
            });
          } else {
            api.addOccupy(occupyForm.value, route.query().package, () => {
              $msg.succ("新增成功");
              dialogVisible.value = false;
              getData();
            });
          }
        }
      });
    };

    const resetForm = () => {
      if (!formRef.value) return;
      formRef.value.resetFields();
    };

    const resultGroupData = ref([]);
    const resultGroupColumn = [{
      label: '发团日期',
      prop: 'startGroupTime'
    }, {
      label: '套餐名称+团号',
      prop: 'nameAndCode'
    }, {
      label: '最大收客',
      prop: 'maxHoldVisitor',
      width: 100
    }, {
      label: '未签/已签合同',
      prop: 'whetherSign',
      width: 150
    }, {
      label: '未收款/已收款',
      prop: 'whetherReceived',
      width: 150
    }, {
      label: '占位/已确认',
      prop: 'whetherHolder',
      width: 150
    }, {
      label: '余位',
      prop: 'surplusSeat',
      width: 100
    }];
    route.watchRouter("occupymanager", () => {
      getData();
    });

    function getData() {
      let routeQuery = route.query();
      api.occupyList({
        groupCode: routeQuery.code,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        data.value = val.itemList;
        total.value = val.total;
        resultGroupData.value = handleResultGroup(val.resultGroupItem);
      });
    }

    function handleResultGroup(data) {
      let obj = {}; // 套餐名称+团号

      obj.nameAndCode = `${data.packageName ? data.packageName : '-'}/${data.groupCode}`; // 未签/已签合同

      obj.whetherSign = `${data.noSign}/${data.isSign}`; // 未收款/已收款

      obj.whetherReceived = `${data.uncollected}/${data.received}`; // 占位/已确认

      obj.whetherHolder = `${data.holder}/${data.isHolder}`;
      return [{ ...data,
        ...obj
      }];
    }

    getData();
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_table = _resolveComponent("el-table");

      const _component_el_main = _resolveComponent("el-main");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(BackTitleView, {
          title: "占位管理"
        }), _createVNode(_component_el_button, {
          onClick: add,
          type: "primary"
        }, {
          default: _withCtx(() => [_createTextVNode("新增占位")]),
          _: 1
        })]), _createVNode(_component_el_table, {
          data: resultGroupData.value,
          border: ""
        }, {
          default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(resultGroupColumn, (item, index) => {
            return _createVNode(_component_el_table_column, {
              key: index,
              label: item.label,
              prop: item.prop,
              width: item.width,
              align: "center"
            }, null, 8, ["label", "prop", "width"]);
          }), 64))]),
          _: 1
        }, 8, ["data"]), _createElementVNode("div", _hoisted_3, [_createVNode(DataCollectTable, {
          data: data.value,
          total: total.value,
          config: tableConfig,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"])])]),
        _: 1
      }), _createVNode(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => dialogVisible.value = $event),
        title: title.value,
        width: "35%",
        top: "10vh",
        class: "base_dialog occupy_dialog"
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_4, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[5] || (_cache[5] = $event => submitForm(formRef.value))
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: _cache[6] || (_cache[6] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "formRef",
          ref: formRef,
          model: occupyForm.value,
          rules: occupyRules,
          "label-width": "125px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "客人姓名",
            prop: "guestName"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: occupyForm.value.guestName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => occupyForm.value.guestName = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "客人电话",
            prop: "phoneNumber"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: occupyForm.value.phoneNumber,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => occupyForm.value.phoneNumber = $event),
              modelModifiers: {
                number: true
              }
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "客人身份证号码",
            prop: "idCard"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: occupyForm.value.idCard,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => occupyForm.value.idCard = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "占位人数",
            prop: "peopleNum"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: occupyForm.value.peopleNum,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => occupyForm.value.peopleNum = $event),
              modelModifiers: {
                number: true
              }
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "销售人员",
            prop: "sales"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: occupyForm.value.sales,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => occupyForm.value.sales = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }

};