import { POST } from "@net/service.js";
import page from "@/api/page";

const URL = {
  list: 'team_building_show_img_get',
  update: 'team_building_show_img_update'

};

const api = {
  list(succ) {
    let requestOptions = {
      path: URL.list,
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  },
  update(params, succ) {
    let requestOptions = {
      path: URL.update,
      params: params,
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  },
}

export default api
