import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
import TravelPrice from "./setMeal/TravelPrice.vue";
import DynamicParams from "@com/DynamicParams.vue";
import { ref, reactive, watch, nextTick } from "vue";
import $ from "@util/base";
import { $msg } from "@ui/msg";
export default {
  __name: 'TravelSetMeal',
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    },
    modify: {
      type: Boolean,
      default: false
    }
  },
  emits: ["programme"],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const emit = __emit;
    const props = __props;
    const drawer = ref(false);
    const data = ref([]);
    const drawerRef = ref(null);
    const travelPriceRef = ref(null);
    const setMealParam = ref(null);
    watch(() => props.data, val => {
      data.value = val;
    });
    watch(() => drawer.value, () => {
      if (drawer.value == false) {
        travelPriceRef.value.reset();
      }
    });

    function operation(readOnly) {
      if (readOnly) {
        return {
          copy: false,
          move: false,
          modify: false,
          del: false,
          add: false,
          check: true
        };
      } else {
        return {
          copy: true,
          move: true,
          modify: true,
          del: true,
          add: true,
          check: false
        };
      }
    }

    function aaa() {
      console.log(setMealParam.value.getValue());
    }

    var modifyIndex = null;

    function addSetMeal() {
      modifyIndex = null;
      openPriceParams();
      nextTick(() => {
        travelPriceRef.value.clear();
      });
    }

    function showDetails(index) {
      modifyIndex = null;
      openPriceParams();
      nextTick(() => {
        travelPriceRef.value.improtData(setMealParam.value.getValue()[index]);
      });
    }

    function modifySetMeal(index) {
      modifyIndex = index;
      openPriceParams();
      nextTick(() => {
        travelPriceRef.value.improtData(setMealParam.value.getValue()[index]);
      });
    }

    function copy(index) {
      $msg.succ("复制成功");
      setTimeout(() => {
        openPriceParams();
        nextTick(() => {
          let copyData = setMealParam.value.getValue()[index];
          copyData.name = null;
          copyData.id = null;
          copyData.packageCode = null;
          travelPriceRef.value.improtData(copyData);
        });
      }, 1000);
    }

    function openPriceParams() {
      drawer.value = true;
    }

    function closePriceParams() {
      drawer.value = false;
    }

    function save() {
      travelPriceRef.value.save(res => {
        if (res != false) {
          console.log(res);

          if (modifyIndex != null) {
            setMealParam.value.modifyData(modifyIndex, res);
          } else {
            setMealParam.value.addData(res);
          }

          closePriceParams();
          let data = [];

          for (const item of setMealParam.value.getReactiveValue()) {
            data.push({
              packageName: item.name
            });
          }

          emit("programme", data);
        }
      });
    }

    function chack() {
      if (setMealParam.value.getReactiveValue().length <= 0) {
        return false;
      }

      return true;
    }

    function chackName(data) {
      var set = new Set();
      data.forEach(e => {
        set.add(e.name);
      });
      return set.size == data.length;
    }

    function getValue() {
      let value = setMealParam.value.getValue();

      if (value.length <= 0) {
        return false;
      }

      if (!chackName(value)) {
        $msg.error("套餐名不能重复");
        return false;
      }

      return value;
    }

    __expose({
      chack,
      getValue
    });

    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_drawer = _resolveComponent("el-drawer");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(DynamicParams, {
        ref_key: "setMealParam",
        ref: setMealParam,
        showDefault: __props.readOnly,
        data: data.value,
        customAdd: true,
        onAdd: addSetMeal,
        onCheck: showDetails,
        onModify: modifySetMeal,
        onCopy: copy,
        addText: "添加套餐",
        operation: operation(__props.readOnly)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "套餐名称",
          prop: "name",
          align: "center"
        })]),
        _: 1
      }, 8, ["showDefault", "data", "operation"]), _createVNode(_component_el_drawer, {
        modelValue: drawer.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => drawer.value = $event),
        title: "添加套餐",
        direction: "rtl",
        ref_key: "drawerRef",
        ref: drawerRef,
        "close-on-click-modal": false,
        size: 1080,
        "custom-class": "base_drawer add_room_layout"
      }, {
        footer: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_el_button, {
          onClick: _cache[0] || (_cache[0] = $event => drawer.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), !__props.readOnly ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          type: "primary",
          onClick: save
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        })) : _createCommentVNode("", true)])]),
        default: _withCtx(() => [_createVNode(TravelPrice, {
          ref_key: "travelPriceRef",
          ref: travelPriceRef
        }, null, 512)]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }

};