import { POST } from "@net/service.js";

const URL = {
  login: "account_login",
  userInfo: "account_current",
  changePassword: "account_password_change",
  logout: "account_logout"
};

const api = {
  login(name, password, succ) {
    let params = {
      loginName: name,
      loginPassword: password,
    };
    let requestOptions = {
      path: URL.login,
      params: params,
      isLoading: true,
      succ: succ,
    };
    POST(requestOptions);
  },
  userInfo(succ) {
    let requestOptions = {
      path: URL.userInfo,
      succ: succ,
    };
    POST(requestOptions);
  },
  changePassword(params, succ) {
    let requestOptions = {
      path: URL.changePassword,
      params: params,
      succ: succ,
    };
    POST(requestOptions);
  },
  logout(succ) {
    let requestOptions = {
      path: URL.logout,
      succ: succ,
    };
    POST(requestOptions);
  },
};

export default api;
