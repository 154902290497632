import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import DataCollectTable from "../table/DataCollectTable.vue";
import { OP_KEY, COLUMN_TYPE, OPERATIONS, operationsHander } from "../table/tableOperations"; // import data from "@/test/goodsData.json"

import useRoute from "@util/router";
import { ref } from "vue";
import api from "@api/hotel";
import $ from "@util/base";
import { $msg, $box } from "@/common/ui/msg";
export default {
  __name: 'HotelStockTable',
  props: {
    type: {
      type: String
    },
    route: {
      type: String
    }
  },
  emits: ['statusData'],

  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const props = __props;
    const data = ref([]);
    const total = ref(0);
    const route = useRoute();
    route.watchRouter("financestockmanager", () => {
      if ($.isNotNull(route.query().tab) && route.query().tab == "hotel") {
        getData();
      }
    });

    function getOperations(data) {
      return [OPERATIONS.inventory];
    }

    const config = {
      showIndex: true,
      operations: {
        label: '操作',
        width: 120,
        operations: getOperations
      },
      column: [{
        label: '酒店图片',
        prop: 'mainPic',
        type: COLUMN_TYPE.picture,
        width: 100
      }, {
        label: '酒店名称',
        prop: 'hotelName'
      }, {
        label: '产品编号',
        prop: 'hotelCode',
        width: 100
      }, // {
      //   label: '库存',
      //   prop: 'totalStock',
      //   width: 80
      // }, 
      {
        label: '归属基地',
        prop: 'shopName',
        width: 100
      }]
    };
    const opHander = operationsHander();
    opHander.install(OP_KEY.inventory, data => {
      route.push({
        name: 'hotelstockdetails',
        query: {
          type: 'show',
          code: data.id
        }
      });
    });
    getData();

    function getData() {
      if (props.type == 'input') {
        getInputData();
      } else {
        getManagerData();
      }
    }

    function getManagerData() {
      let routeQuery = route.query();
      api.manager({
        categoryCode: routeQuery.c,
        shopCode: routeQuery.base,
        name: routeQuery.name,
        status: routeQuery.status,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        total.value = val.total;
        data.value = val.itemList;
        emit('statusData', [val.allNum, val.leadUpNum, val.leadDownNum]);
      });
    }

    function getInputData() {
      let routeQuery = route.query();
      api.input({
        categoryCode: routeQuery.c,
        shopCode: routeQuery.base,
        name: routeQuery.name,
        status: routeQuery.status,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        total.value = val.total;
        data.value = val.itemList;
        emit('statusData', [val.allNum, val.draftNum, val.notApprovalNum, val.isPassNum, val.rejectedNum]);
      });
    }

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", null, [_createVNode(DataCollectTable, {
        ref: "hotelStockTableRef",
        data: data.value,
        config: config,
        onOperationsHander: _unref(opHander).receive,
        total: total.value
      }, null, 8, ["data", "onOperationsHander", "total"])]);
    };
  }

};