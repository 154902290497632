import { unref as _unref, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "text-large font-600 mr-3"
};
import { ArrowLeft } from '@element-plus/icons-vue';
import { useRouter } from "vue-router";
export default {
  __name: 'BackTitleView',
  props: ['title'],

  setup(__props) {
    const route = useRouter();
    return (_ctx, _cache) => {
      const _component_el_page_header = _resolveComponent("el-page-header");

      const _component_el_header = _resolveComponent("el-header");

      return _openBlock(), _createBlock(_component_el_header, null, {
        default: _withCtx(() => [_createVNode(_component_el_page_header, {
          onBack: _cache[0] || (_cache[0] = $event => _unref(route).go(-1)),
          icon: _unref(ArrowLeft)
        }, {
          title: _withCtx(() => [_createTextVNode(" 返回 ")]),
          content: _withCtx(() => [_createElementVNode("span", _hoisted_1, _toDisplayString(__props.title), 1)]),
          _: 1
        }, 8, ["icon"])]),
        _: 1
      });
    };
  }

};