import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-75d8e623"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "calender_layout"
};
const _hoisted_2 = {
  class: "calender_title"
};
const _hoisted_3 = {
  class: "calender_week"
};
const _hoisted_4 = {
  class: "calender_day"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = ["onClick"];
import { ref, watch } from "vue";
export default {
  __name: 'PriceCalendar',
  props: {
    number: {
      type: Number,
      default: 3
    },
    del: {
      type: Boolean,
      default: true
    }
  },
  emits: ["select", "del"],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const weekText = ["日", "一", "二", "三", "四", "五", "六"]; //每月最大天数

    const _monthMaxDay = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    /**
     * 获取指定月份的最大天数
     * @param {*} month 月份 从0开始
     */

    function monthMaxDay(year, month) {
      //2月判断是否是闰年
      if (month == 1 && (year % 4 === 0 && year % 100 !== 0 || year % 400 === 0)) {
        return 28;
      } else {
        return _monthMaxDay[month];
      }
    }

    const nowDate = new Date();

    function pastTime(year, month, day) {
      if (nowDate.getFullYear() >= year && nowDate.getMonth() + 1 >= month && nowDate.getDate() > day) {
        return true;
      } else {
        return false;
      }
    }

    function isNotNull(value) {
      return value != null && value != undefined;
    }
    /**
     * 判断两个日期是否相同
     */


    function dateEquals(date1, date2) {
      return date1.year == date2.year && date1.month == date2.month && date1.day == date2.day;
    }
    /**
     * 计算两个日期相隔天数
     */


    function getDaysBetween(date1, date2) {
      var startDate = new Date(date1.year, date1.month - 1, date1.day);
      var endDate = new Date(date2.year, date2.month - 1, date2.day);
      var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
      return days;
    }
    /**
     * 获取指定月份1日星期几
     * @param {*} month 月份 从0开始
     */


    function monthFirstWeek(year, month) {
      return new Date(year, month, 1).getDay();
    }
    /**
     * 创建月数据
     * @param {*} month 月份 从1开始
     */


    function createMonth(year, month) {
      const monthNum = month - 1;
      const week = monthFirstWeek(year, monthNum);
      const maxDay = monthMaxDay(year, monthNum);
      var dates = [];
      var weekindex = week; //根据当前月第一天星期几，用上个月天数补全空白

      for (let i = 0; i < week; i++) {
        dates.push({
          isCurrentMonth: false,
          isEnd: false,
          checked: false
        });
      }

      for (let i = 1; i <= maxDay; i++) {
        let isEnd = false;

        if (weekindex % 7 == 6) {
          isEnd = true;
        }

        dates.push({
          isCurrentMonth: true,
          isPastTime: pastTime(year, month, i),
          isEnd: isEnd,
          year: year,
          month: monthNum + 1,
          day: i,
          checked: false
        });
        weekindex++;
      } //剩余格子补全


      const remainingDay = 42 - dates.length;

      for (let i = 0; i < remainingDay; i++) {
        let isEnd = false;

        if (weekindex % 7 == 6) {
          isEnd = true;
        }

        dates.push({
          isCurrentMonth: false,
          isEnd: isEnd,
          checked: false
        });
        weekindex++;
      }

      return {
        year: year,
        month: month,
        date: dates
      };
    }

    function createCalender(num) {
      var year = nowDate.getFullYear();
      var month = nowDate.getMonth() + 1;
      var calendar = [];

      for (let index = 0; index < num; index++) {
        calendar.push(createMonth(year, month));

        if (month == 12) {
          year++;
          month = 1;
        } else {
          month++;
        }
      }

      calendarData.value = calendar; // console.log(calendar)
    }

    const calendarData = ref([]);
    var checkedData = [];

    function setChecked(targetDate) {
      checkedData = targetDate;

      for (const calendar of calendarData.value) {
        for (const month of calendar.date) {
          month.checked = false;

          if (!month.isCurrentMonth || month.isPastTime) {
            continue;
          }

          for (const target of targetDate) {
            if (dateEquals({
              year: target.dateSrc.getFullYear(),
              month: target.dateSrc.getMonth() + 1,
              day: target.dateSrc.getDate()
            }, month)) {
              month.checked = true;
            }
          }
        }
      }
    }

    createCalender(props.number);
    watch(() => props.number, val => {
      createCalender(val);
      setChecked(checkedData);
    });

    function select(data) {
      if (data.day != null && !data.isPastTime) {
        emit("select", data, res => {
          if (res) {
            if (!data.checked) {
              data.checked = true;
            }
          }
        });
      }
    }

    function del(data) {
      emit("del", data);
      data.checked = false;
    }

    __expose({
      setChecked
    });

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(calendarData.value, calendar => {
        return _openBlock(), _createElementBlock("div", {
          class: "calender_item",
          key: calendar.year
        }, [_createElementVNode("div", _hoisted_2, _toDisplayString(calendar.year) + "年" + _toDisplayString(calendar.month), 1), _createElementVNode("div", _hoisted_3, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(weekText, (item, weekIndex) => {
          return _createElementVNode("div", {
            class: _normalizeClass(["week_item", {
              week2: weekIndex == 0 || weekIndex == 6
            }]),
            key: weekIndex
          }, _toDisplayString(item), 3);
        }), 64))]), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(calendar.date, (month, mIndex) => {
          return _openBlock(), _createElementBlock("div", {
            key: mIndex,
            onClick: $event => select(month),
            class: _normalizeClass({
              date_item: true,
              past_time: month.isPastTime,
              checked: month.checked
            })
          }, [_createElementVNode("div", null, _toDisplayString(month.day), 1), props.del && !month.isPastTime && month.isCurrentMonth && month.checked ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "del",
            onClick: _withModifiers($event => del(month), ["stop"])
          }, " x ", 8, _hoisted_6)) : _createCommentVNode("", true)], 10, _hoisted_5);
        }), 128))])]);
      }), 128))]);
    };
  }

};