import { POST } from "@net/service.js";
import $ from "@util/base";
import page from "./page";
import configApi from "./config";

const URL = {
    manageList: "group_remain_seat_manage_list",
    orderList: "order_group_remain_seat_manage_list",
    registrationList: "order_enroll_manage_list",
    registrationExport: "order_enroll_manage_list_export",
    registrationExport2: "order_enroll_manage_list_export2",
    occupyList: "order_occupancy_manage_list",
    getOccupy: "order_occupancy_get",
    addOccupy: "order_occupancy_add",
    updateOccupy: "order_occupancy_update",
    statusOccupy: "order_occupancy_update_status",
    groupRemainExport: "order_group_remain_seat_list_export",
    groupRemainOpen: "group_remain_seat_manage_close"
};

const api = {
    base(succ) {
        configApi.base(succ);
    },
    manageList(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.shopCode)) {
            paramsData.shopCode = params.shopCode;
        }
        if ($.isNotEmpty(params.name)) {
            paramsData.name = params.name;
        }
        if ($.isNotEmpty(params.code)) {
            paramsData.code = params.code;
        }
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.manageList,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    orderList(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.shopCode)) {
            paramsData.shopCode = params.shopCode;
        }
        if ($.isNotEmpty(params.code)) {
            paramsData.code = params.code;
        }
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.orderList,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    registrationList(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.groupCode)) {
            paramsData.groupCode = params.groupCode;
        }
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.registrationList,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    registrationExport(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.groupCode)) {
            paramsData.groupCode = params.groupCode;
        }
        let requestOptions = {
            path: URL.registrationExport,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    registrationExport2(code, succ) {
        let requestOptions = {
            path: URL.registrationExport2,
            params: { groupCode: code },
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    occupyList(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.groupCode)) {
            paramsData.groupCode = params.groupCode;
        }
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.occupyList,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    getOccupy(code, succ) {
        let requestOptions = {
            path: URL.getOccupy,
            params: { id: code },
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    addOccupy(params, packageCode, succ) {
        params.packageCode = packageCode
        params.holderStatus = "1050001"
        let requestOptions = {
            path: URL.addOccupy,
            params: params,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    updateOccupy(params, code, succ) {
        params.id = code
        let requestOptions = {
            path: URL.updateOccupy,
            params: params,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    statusOccupy(code, status, succ) {
        let paramsData = {};
        paramsData.id = code;
        paramsData.holderStatus = status;
        let requestOptions = {
            path: URL.statusOccupy,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    groupRemainExport(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.shopCode)) {
            paramsData.shopCode = params.shopCode;
        }
        if ($.isNotEmpty(params.code)) {
            paramsData.code = params.code;
        }
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.groupRemainExport,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    groupRemainClose(startGroupTime, packageCode, travelCode, isClose, succ) {
        let paramsData = {
            startGroupTime: startGroupTime,
            packageCode: packageCode,
            travelCode: travelCode
        };
        // 1002001是 1002002否
        if (isClose) {
            paramsData.closeStatus = "1002001"
        } else {
            paramsData.closeStatus = "1002002"
        }
        let requestOptions = {
            path: URL.groupRemainOpen,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);

    }
};

export default api;