import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import DataCollectTable from "@com/table/DataCollectTable.vue";
import { operationsHander } from "@com/table/tableOperations";
import { ref, reactive } from "vue";
import { Search } from "@element-plus/icons-vue";
import api from "@api/coupon";
import useRoute from "@util/router";
import BackTitleView from "@/components/BackTitleView.vue";
export default {
  __name: 'CouponGrantRecord',

  setup(__props) {
    const route = useRoute();
    const title = ref("发放记录");
    const size = ref(20);
    const page = ref(1);
    const total = ref(0);
    const data = ref([]);
    getData();

    function getData() {
      let routeQuery = route.query();
      let params = {
        couponCode: routeQuery.code,
        page: page.value,
        size: size.value
      };
      api.couponSendList(params, val => {
        total.value = val.total;
        data.value = val.list;
      });
    }

    const config = {
      showIndex: true,
      column: [{
        label: "所属用户",
        prop: "user",
        width: 200
      }, {
        label: "状态",
        prop: "status",
        width: 100
      }, {
        label: "发放方式",
        prop: "sendType"
      }, {
        label: "发放时间",
        prop: "sendTime"
      }, {
        label: "关联订单",
        prop: "order"
      }]
    };
    const opHander = operationsHander();

    function dataPageChange(type, val) {
      if (type == 'page') {
        page.value = val;
      } else if (type == 'size') {
        size.value = val;
      }

      getData();
    }

    return (_ctx, _cache) => {
      const _component_el_main = _resolveComponent("el-main");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_footer = _resolveComponent("el-footer");

      const _component_el_container = _resolveComponent("el-container");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_container, {
        class: "base_details_container"
      }, {
        default: _withCtx(() => [_createVNode(BackTitleView, {
          title: title.value
        }, null, 8, ["title"]), _createVNode(_component_el_main, {
          class: "base_main_content",
          style: {
            "max-height": "100%"
          }
        }, {
          default: _withCtx(() => [_createVNode(DataCollectTable, {
            data: data.value,
            total: total.value,
            config: config,
            isRoute: false,
            onOperationsHander: _unref(opHander).receive,
            onPageChange: dataPageChange
          }, null, 8, ["data", "total", "onOperationsHander"])]),
          _: 1
        }), _createVNode(_component_el_footer, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            onClick: _cache[0] || (_cache[0] = $event => _unref(route).go(-1))
          }, {
            default: _withCtx(() => [_createTextVNode("返回")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]);
    };
  }

};