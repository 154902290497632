import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "base_screen_header"
};
const _hoisted_2 = {
  class: "base_main_header"
};
import HotelTable from '@com/hotel/HotelTable.vue';
import StatusBar from '@com/StatusBar.vue';
import CategoryManager from '@com/CategoryManager.vue';
import { ref, reactive } from 'vue';
import { Search } from '@element-plus/icons-vue';
import useRoute from "@util/router";
import configApi from "@api/config";
import api from "@api/hotel";
import $ from "@util/base";
export default {
  __name: 'HotelInput',

  setup(__props) {
    const route = useRoute();
    const categoryConfig = {
      type: 'hotel'
    };
    const baseOptions = ref([]);
    getConfig();

    function getConfig() {
      configApi.base(val => {
        baseOptions.value = val;
      });
    }

    const statusList = reactive([{
      label: '全部',
      value: '1',
      total: 0
    }, {
      label: '草稿',
      value: '1007001',
      total: 0
    }, {
      label: '待审批',
      value: '1007002',
      total: 0
    }, {
      label: '已通过',
      value: '1007004',
      total: 0
    }, {
      label: '已驳回',
      value: '1007003',
      total: 0
    }]);
    const base = ref(route.query().base || '');
    const name = ref(route.query().name || '');

    function statusData(val) {
      statusList.forEach((e, i) => {
        e.total = val[i];
      });
    }

    function searchBase(val) {
      route.replaceQuery('base', val);
    }

    function searchName(val) {
      route.replaceQuery('name', val);
    }

    function add() {
      route.push({
        name: 'hoteldetails',
        query: {
          type: 'add'
        }
      });
    }

    function getExport() {
      let routeQuery = route.query();
      api.inputExport({
        categoryCode: routeQuery.c,
        shopCode: routeQuery.base,
        name: routeQuery.name,
        status: routeQuery.status
      }, val => {
        $.download(val.url, "酒店入录列表导出");
      });
    }

    return (_ctx, _cache) => {
      const _component_el_aside = _resolveComponent("el-aside");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_main = _resolveComponent("el-main");

      const _component_el_container = _resolveComponent("el-container");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createBlock(_component_el_container, null, {
        default: _withCtx(() => [_createVNode(_component_el_aside, {
          width: "170px"
        }, {
          default: _withCtx(() => [_createVNode(CategoryManager, {
            config: categoryConfig
          })]),
          _: 1
        }), _createVNode(_component_el_main, {
          class: "base_main_content"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            onClick: add
          }, {
            default: _withCtx(() => [_createTextVNode("新增酒店")]),
            _: 1
          })), [[_directive_auth, _ctx.getCurCode('ADD')]]), _createVNode(_component_el_form, {
            inline: true,
            class: "base_search_form",
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [_createVNode(_component_el_select, {
                modelValue: base.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => base.value = $event),
                onChange: searchBase,
                placeholder: "请选择基地",
                clearable: ""
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(baseOptions.value, (item, index) => {
                  return _openBlock(), _createBlock(_component_el_option, {
                    key: index,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]);
                }), 128))]),
                _: 1
              }, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: name.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => name.value = $event),
                "onKeyup[native]": searchName,
                onChange: searchName,
                placeholder: "酒店名称",
                clearable: "",
                "suffix-icon": _unref(Search)
              }, null, 8, ["modelValue", "suffix-icon"])]),
              _: 1
            })]),
            _: 1
          })]), _createVNode(StatusBar, {
            data: statusList,
            default: "1"
          }, null, 8, ["data"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
            type: "primary",
            text: "",
            onClick: getExport
          }, {
            default: _withCtx(() => [_createTextVNode("导出")]),
            _: 1
          })]), _withDirectives(_createVNode(HotelTable, {
            type: "input",
            route: "hotelinput",
            onStatusData: statusData
          }, null, 512), [[_directive_auth, _ctx.getCurCode('EXPORT')]])]),
          _: 1
        })]),
        _: 1
      });
    };
  }

};