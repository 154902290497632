import { POST } from "@net/service.js";
import $ from "@util/base";
import page from "./page"

const URL = {
    num: "gift_package_status_num",
    list: "gift_package_list",
    details: "gift_package_detail",
    status: "gift_package_status_change",
    delete: "gift_package_delete",
    clone: "gift_package_clone",
    update: "gift_package_update",
    add: "gift_package_add",
    export: "gift_package_list_export",
    homeflag: "gift_package_homeflag",
};

// 1011001 线上礼包、1011002 线下礼包
// const TYPE = {
//   online: "1011001",
//   offline: "1011002",
// };

const api = {
    num(succ) {
        let requestOptions = {
            path: URL.num,
            succ: (val) => {
                succ([val.total, val.down, val.up]);
            },
        };
        POST(requestOptions);
    },
    list(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.name)) {
            paramsData.name = params.name;
        }
        if ($.isNotEmpty(params.status) && params.status != "all") {
            paramsData.giftStatus = params.status;
        }
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.list,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    details(code, succ) {
        let requestOptions = {
            path: URL.details,
            params: { giftCode: code },
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    delete(code, succ) {
        let requestOptions = {
            path: URL.delete,
            params: { giftCode: code },
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    clone(code, succ) {
        let requestOptions = {
            path: URL.clone,
            params: { giftCode: code },
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    status(code, status, succ, fail) {
        let requestOptions = {
            path: URL.status,
            params: { giftCode: code, giftStatus: status },
            succ: succ,
            fail: fail,
        };
        POST(requestOptions);
    },
    add(params, succ) {
        let requestOptions = {
            path: URL.add,
            params: params,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    update(params, succ) {
        let requestOptions = {
            path: URL.update,
            params: params,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    export (params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.name)) {
            paramsData.name = params.name;
        }
        if ($.isNotEmpty(params.status) && params.status != "all") {
            paramsData.giftStatus = params.status;
        }
        let requestOptions = {
            path: URL.export,
            params: paramsData,
            succ: succ,
        };
        POST(requestOptions);
    },
    homeflag(code, succ) {
        let requestOptions = {
            path: URL.homeflag,
            params: { giftCode: code },
            succ: succ,
        };
        POST(requestOptions);
    },
};

export default api;