const eventList = []
const event = {
    registerEvent(key, func) {
        eventList.push({
            key: key,
            func: func
        })
    },
    sendEvent(key) {
        eventList.forEach(element => {
            if (element.key === key) {
                element.func()
                return;
            }
        });
    },
    unregisterEvent(key) {
        for (let index = 0; index < eventList.length; index++) {
            const element = eventList[index];
            if (element.key === key) {
                eventList.splice(index, 1)
            }
        }
    }
}
export default event