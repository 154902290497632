import { POST } from "@net/service.js";

const URL = {
  getCompany: "company_get",
  updateCompany: "company_update",
};

const api = {
  getCompany(succ) {
    let requestOptions = {
      path: URL.getCompany,
      isLoading: true,
      succ: succ,
    };
    POST(requestOptions);
  },
  updateCompany(params, succ) {
    let requestOptions = {
      path: URL.updateCompany,
      params: params,
      isLoading: true,
      succ: succ,
    };
    POST(requestOptions);
  }
};

export default api
