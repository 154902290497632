import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "geo_leaderboards"
};
const _hoisted_2 = {
  class: "gl_header"
};
const _hoisted_3 = {
  class: "gl_content"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  key: 0
};
const _hoisted_7 = {
  key: 1
};
import { ArrowRight, Medal } from "@element-plus/icons-vue";
import { ref } from "vue";
import useRoute from "@util/router";
import api from "@api/overview";
export default {
  __name: 'GeoLeaderboards',
  props: {
    title: {
      type: String,
      required: true
    },
    which: {
      type: Number,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },

  setup(__props) {
    const route = useRoute();
    const props = __props;
    const config = {
      1: [{
        label: "编号",
        prop: "code"
      }, {
        label: "名称",
        prop: "name"
      }, {
        label: "类型",
        prop: "type",
        handle: "isType"
      }, {
        label: "数量",
        prop: "num"
      }],
      2: [{
        label: "商品名称",
        prop: "productName"
      }, {
        label: "归属基地",
        prop: "shopName"
      }, {
        label: "购买数量",
        prop: "num"
      }],
      3: [{
        label: "旅行产品名称",
        prop: "travelName"
      }, {
        label: "归属基地",
        prop: "shopName"
      }, {
        label: "购买数量",
        prop: "num"
      }],
      4: [{
        label: "酒店名称",
        prop: "hotelName"
      }, {
        label: "归属基地",
        prop: "shopName"
      }, {
        label: "预定数量",
        prop: "num"
      }]
    };

    function typeValue(code) {
      // 1044001商品、1044002酒店、1044003旅行、1044004礼包、1044005活动
      switch (code) {
        case '1044001':
          return '商品';

        case '1044002':
          return '酒店';

        case '1044003':
          return '旅行';

        case '1044004':
          return '礼包';

        case '1044005':
          return '活动';

        default:
          return '';
      }
    }

    const rankList = ref([]);
    const drawer = ref(false);

    function lookRank() {
      drawer.value = true;
      let routeQuery = route.query();
      api.rankList({
        which: props.which,
        shopCode: routeQuery.base,
        dateType: routeQuery.date
      }, val => {
        rankList.value = val.list;
      });
    }

    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_table = _resolveComponent("el-table");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_drawer = _resolveComponent("el-drawer");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, _toDisplayString(__props.title), 1), _createElementVNode("div", {
        onClick: lookRank,
        class: "glh_right"
      }, [_createTextVNode(" 查看完成榜单 "), _createVNode(_component_el_icon, null, {
        default: _withCtx(() => [_createVNode(_unref(ArrowRight))]),
        _: 1
      })])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_table, {
        data: __props.data,
        border: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "排名",
          width: "60",
          align: "center"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_unref(Medal))]),
            _: 1
          })]),
          _: 1
        }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(config[__props.which], (item, index) => {
          return _openBlock(), _createBlock(_component_el_table_column, {
            key: index,
            label: item.label,
            align: "center"
          }, {
            default: _withCtx(scope => [item.handle == 'isType' ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(typeValue(scope.row[item.prop])), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(scope.row[item.prop]), 1))]),
            _: 2
          }, 1032, ["label"]);
        }), 128))]),
        _: 1
      }, 8, ["data"])]), _createVNode(_component_el_drawer, {
        "custom-class": "base_drawer",
        modelValue: drawer.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => drawer.value = $event),
        "close-on-click-modal": false,
        title: __props.title,
        "with-header": true,
        size: 720
      }, {
        footer: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_el_button, {
          onClick: _cache[0] || (_cache[0] = $event => drawer.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("关闭")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_table, {
          data: rankList.value,
          border: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            type: "index",
            label: "序号",
            width: "80",
            align: "center"
          }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(config[__props.which], (item, index) => {
            return _openBlock(), _createBlock(_component_el_table_column, {
              key: index,
              label: item.label,
              align: "center"
            }, {
              default: _withCtx(scope => [item.handle == 'isType' ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(typeValue(scope.row[item.prop])), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(scope.row[item.prop]), 1))]),
              _: 2
            }, 1032, ["label"]);
          }), 128))]),
          _: 1
        }, 8, ["data"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }

};