import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "base_screen_header"
};
const _hoisted_2 = {
  class: "base_main_header"
};
import StatusBar from '@com/StatusBar.vue';
import DataCollectTable from '@com/table/DataCollectTable.vue';
import { OP_KEY, COLUMN_TYPE, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import { ref, reactive } from 'vue';
import { Search } from '@element-plus/icons-vue';
import useRoute from "@util/router";
import { $msg } from "@ui/msg";
import $ from "@util/base";
import api from "@api/user";
export default {
  __name: 'UserManager',

  setup(__props) {
    const route = useRoute();
    route.watchRouter('usermanager', () => {
      getUserList();
    });
    const baseOptions = ref([]);
    getConfig();

    function getConfig() {
      api.base(val => {
        baseOptions.value = val;
      });
    }

    const statusList = reactive([{
      label: '全部',
      value: 2,
      total: 0
    }, {
      label: '正常',
      value: 1,
      total: 0
    }, {
      label: '封禁',
      value: 0,
      total: 0
    }]);
    const base = ref(route.query().base || '');
    const name = ref(route.query().name || '');

    function searchBase(val) {
      route.replaceQuery('base', val);
    }

    function searchName(val) {
      route.replaceQuery('name', val);
    }

    function userOperations(data) {
      return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.resetPassword];
    }

    const data = ref([]);
    const total = ref(0);
    const config = {
      showIndex: true,
      operations: {
        label: '操作',
        width: 120,
        operations: userOperations
      },
      column: [{
        label: '头像',
        prop: 'avatar',
        type: COLUMN_TYPE.picture,
        width: 100
      }, {
        label: '姓名',
        prop: 'name'
      }, {
        label: '账号',
        prop: 'loginName'
      }, {
        label: '用户状态',
        prop: 'flag',
        type: COLUMN_TYPE.switch,
        operationsKey: OPERATIONS.switch,
        activeText: '正常',
        inactiveText: '封禁',
        activeValue: 1,
        // 1正常 0封禁
        inactiveValue: 0,
        switchWidth: 55,
        width: 100
      }, {
        label: '归属基地',
        prop: 'shop',
        width: 100
      }, {
        label: '用户角色',
        prop: 'roleName',
        width: 100
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 100
      }, {
        label: '创建人',
        prop: 'creator',
        width: 100
      }]
    };
    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      route.push({
        name: 'userdetails',
        query: {
          type: 'show',
          code: data.id
        }
      });
    });
    opHander.install(OP_KEY.modify, data => {
      route.push({
        name: 'userdetails',
        query: {
          type: 'modify',
          code: data.id
        }
      });
    });
    opHander.install(OP_KEY.resetPassword, data => {
      api.resetPassword(data.id, val => {
        $msg.succ("用户密码重置成功，新密码为123456！");
      });
    });
    opHander.install(OP_KEY.switch, data => {
      // 1正常 0封禁
      let status = data.data.flag == 1 ? 0 : 1;
      api.status(data.data.id, status, () => {
        data.resolve(true);
        $msg.succ("用户状态修改成功");
        getUserList();
      }, () => {
        data.reject();
      });
    });

    function addUser() {
      route.push({
        name: 'userdetails',
        query: {
          type: 'add'
        }
      });
    }

    getUserList();

    function getUserList() {
      let routeQuery = route.query();
      api.userList({
        flag: routeQuery.status,
        shopId: routeQuery.base,
        loginName: routeQuery.name,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        total.value = val.total;
        data.value = val.itemList;
        statusData([val.allNum, val.normalNum, val.closeNum]);
      });
    }

    function statusData(val) {
      statusList.forEach((e, i) => {
        e.total = val[i];
      });
    }

    function userExport() {
      let routeQuery = route.query();
      api.export({
        flag: routeQuery.status,
        shopId: routeQuery.base,
        loginName: routeQuery.name
      }, val => {
        $.download(val.url, "用户导出");
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_main = _resolveComponent("el-main");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          onClick: addUser
        }, {
          default: _withCtx(() => [_createTextVNode("新增用户")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('ADD')]]), _createVNode(_component_el_form, {
          inline: true,
          class: "base_search_form",
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: base.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => base.value = $event),
              onChange: searchBase,
              placeholder: "请选择基地",
              clearable: ""
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(baseOptions.value, (item, index) => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: index,
                  label: item.label,
                  value: item.id
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: name.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => name.value = $event),
              "onKeyup[native]": searchName,
              onChange: searchName,
              placeholder: "账号",
              clearable: "",
              "suffix-icon": _unref(Search)
            }, null, 8, ["modelValue", "suffix-icon"])]),
            _: 1
          })]),
          _: 1
        })]), _createVNode(StatusBar, {
          data: statusList,
          default: 2
        }, null, 8, ["data"]), _createElementVNode("div", _hoisted_2, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          text: "",
          onClick: userExport
        }, {
          default: _withCtx(() => [_createTextVNode("导出")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('EXPORT')]])]), _createVNode(DataCollectTable, {
          data: data.value,
          total: total.value,
          config: config,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"])]),
        _: 1
      })]);
    };
  }

};