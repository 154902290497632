// 设置
import CompanyInformation from '@/views/setting/CompanyInformation.vue'
import BaseManager from '@/views/setting/BaseManager.vue'
import UserManager from '@/views/setting/UserManager.vue'
import RoleManager from '@/views/setting/RoleManager.vue'
import LogManager from '@/views/setting/LogManager.vue'

import BaseDetails from '@/views/setting/BaseDetails.vue'
import UserDetails from '@/views/setting/UserDetails.vue'
import RoleDetails from '@/views/setting/RoleDetails.vue'

export default [{
  path: '/companyinformation',
  name: 'companyinformation',
  component: CompanyInformation,
  meta: {
    activeMainMenu: 'setting',
    activeSubMenu: '/companyinformation'
  }
}, {
  path: '/basemanager',
  name: 'basemanager',
  component: BaseManager,
  meta: {
    activeMainMenu: 'setting',
    activeSubMenu: '/basemanager'
  }
}, {
  path: '/usermanager',
  name: 'usermanager',
  component: UserManager,
  meta: {
    activeMainMenu: 'setting',
    activeSubMenu: '/usermanager'
  }
}, {
  path: '/rolemanager',
  name: 'rolemanager',
  component: RoleManager,
  meta: {
    activeMainMenu: 'setting',
    activeSubMenu: '/rolemanager'
  }
}, {
  path: '/basedetails',
  name: 'basedetails',
  component: BaseDetails,
  meta: {
    activeMainMenu: 'setting',
    activeSubMenu: '/basemanager'
  }
}, {
  path: '/userdetails',
  name: 'userdetails',
  component: UserDetails,
  meta: {
    activeMainMenu: 'setting',
    activeSubMenu: '/usermanager'
  }
}, {
  path: '/roledetails',
  name: 'roledetails',
  component: RoleDetails,
  meta: {
    activeMainMenu: 'setting',
    activeSubMenu: '/rolemanager'
  }
}, {
    path: '/logmanager',
    name: 'logmanager',
    component: LogManager,
    meta: {
      activeMainMenu: 'setting',
      activeSubMenu: '/rolemanager'
    }
  }
]