import { openBlock as _openBlock, createBlock as _createBlock } from "vue";
import SingleFieldManager from "@com/SingleFieldManager.vue";
export default {
  __name: 'LabelManager',

  setup(__props) {
    const config = {
      name: '商品标签',
      type: 'goodsLabel',
      route: 'goodslabelmanager'
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(SingleFieldManager, {
        config: config
      });
    };
  }

};