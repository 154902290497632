import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "dialog-footer"
};
import { ref, reactive } from "vue";
import $ from "@util/base";
import { $msg } from "@ui/msg";
export default {
  __name: 'ChooseProduct',
  emits: ["chooseProduct"],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const emit = __emit;
    const dialogVisible = ref(false);
    const addTableRef = ref(null);
    const typeKey = ref(null);
    const title = ref(null);

    function show(val) {
      typeKey.value = val;

      switch (val) {
        case 'goods':
          title.value = '添加商品';
          break;

        case 'travel':
          title.value = '添加旅游';
          break;

        case 'hotel':
          title.value = '添加酒店';
          break;

        case 'gift':
          title.value = '添加礼包';
          break;
      }

      dialogVisible.value = true;

      if (!addTableRef.value) {
        return;
      }

      addTableRef.value.clearSelection();
    }

    function close() {
      dialogVisible.value = false;
    }

    function saveProduct() {
      let data = addTableRef.value.getSelectData();

      if ($.isEmpty(data)) {
        $msg.error("请勾选需要添加的产品");
      } else {
        emit("chooseProduct", {
          code: data[0].code,
          name: data[0].name,
          type: typeKey.value
        });
        close();
      }
    }

    __expose({
      show
    });

    return (_ctx, _cache) => {
      const _component_ProductTable = _resolveComponent("ProductTable");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => dialogVisible.value = $event),
        title: title.value,
        width: "720px",
        class: "base_dialog home_page_dialog",
        "destroy-on-close": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_2, [_createVNode(_component_el_button, {
          onClick: saveProduct,
          type: "primary"
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: close
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ProductTable, {
          type: "add",
          ref_key: "addTableRef",
          ref: addTableRef,
          isRadio: true,
          typeKey: typeKey.value
        }, null, 8, ["typeKey"])])]),
        _: 1
      }, 8, ["modelValue", "title"]);
    };
  }

};