import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "base_screen_header"
};
import DataCollectTable from "@/components/table/DataCollectTable.vue";
import { ref } from "vue";
import useRoute from "@/common/utils/router";
import { COLUMN_TYPE, OP_KEY, OPERATIONS, operationsHander } from "@/components/table/tableOperations";
import api from "@api/lineSharing";
import { $box, $msg } from "@/common/ui/msg";
export default {
  __name: 'LineSharing',

  setup(__props) {
    const data = ref([]);
    const total = ref(0);
    const route = useRoute();
    const config = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: getOperations
      },
      column: [{
        label: "封面图片",
        prop: "mainPic",
        type: COLUMN_TYPE.picture,
        width: 100
      }, {
        label: "主标题",
        prop: "mainTitle"
      }, {
        label: "副标题",
        prop: "subtitle" // width: 100,

      }]
    };

    function getOperations(data) {
      return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.del];
    }

    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      // route.activeMeta('goods', props.type)
      route.push({
        name: "lineSharingDetails",
        query: {
          type: "show",
          id: data.id
        }
      });
    });
    opHander.install(OP_KEY.modify, data => {
      // route.activeMeta('goods', props.type)
      route.push({
        name: "lineSharingDetails",
        query: {
          type: "modify",
          id: data.id
        }
      });
    });
    opHander.install(OP_KEY.delete, data => {
      $box.open("删除", "确认删除分享" + data.mainTitle, () => {
        api.del(data.id, () => {
          $msg.succ("删除成功");
          getData();
        });
      });
    });
    getData();

    function getData() {
      api.list({}, res => {
        data.value = res.itemList;
        total.value = res.total;
      });
    }

    function add() {
      route.push({
        name: 'lineSharingDetails',
        query: {
          type: 'add'
        }
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_main = _resolveComponent("el-main");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          onClick: _cache[0] || (_cache[0] = $event => add())
        }, {
          default: _withCtx(() => [_createTextVNode("分享录入")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('ADD')]])]), _createVNode(DataCollectTable, {
          data: data.value,
          config: config,
          total: total.value,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"])]),
        _: 1
      })]);
    };
  }

};