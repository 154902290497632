import { defineStore } from 'pinia'
import { ref } from 'vue'

const useAccoutStore = defineStore('accout', () => {
    const isLoading = ref(true)
    const userInfo = ref({
        mainStore: false
    })
    function authfail() {
        isLoading.value = false
    }

    function setUserInfo(info) {
        userInfo.value = info
    }
    return { isLoading, authfail, userInfo, setUserInfo }
})

export default useAccoutStore