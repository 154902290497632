const common = {
    interval: function(call, intervalTime) {
        let time = new Date().getTime()
        return (arg) => {
            if (new Date().getTime() - time >= intervalTime) {
                call(arg)
                time = new Date().getTime()
            }
        }
    },
    isNull: function(value) {
        if (value != null && value != undefined) {
            return false
        } else {
            return true
        }
    },
    isNotNull: function(value) {
        return !this.isNull(value)
    },
    isEmpty: function(value) {
        if (this.isNull(value)) {
            return true
        } else {
            return value.length <= 0
        }
    },
    isNotEmpty: function(value) {
        return !this.isEmpty(value)
    },
    uuid8() {
        return this.uuid(8, 10)
    },
    uuid: function(len, radix) {
        var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
        var uuid = [],
            i;
        radix = radix || chars.length;
        if (len) {
            for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
        } else {
            var r;
            uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
            uuid[14] = '4';
            for (i = 0; i < 36; i++) {
                if (!uuid[i]) {
                    r = 0 | Math.random() * 16;
                    uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
                }
            }
        }
        return uuid.join('');
    },
    deepCopy: function(obj) {
        var result = Array.isArray(obj) ? [] : {};
        for (var key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (obj[key] == null) {
                    result[key] = null
                } else if (typeof obj[key] === 'object') {
                    result[key] = this.deepCopy(obj[key]);
                } else {
                    result[key] = obj[key];
                }
            }
        }
        return result;
    },
    handleCRU(type, handle) {
        switch (type) {
            case "show":
                handle.show()
                break
            case "add":
                handle.add()
                break
            case "modify":
                handle.modify()
                break
            case "examine":
                handle.examine()
                break
        }
    },
    download(href, title) {
        const a = document.createElement('a')
        a.setAttribute('href', href)
        a.setAttribute('download', title)
        a.click();
    },
    bannerImgSize() {
        return "700*290"
    },
    describeImgSize() {
        return "750×750"
    },
    homePackageSize() {
        return "700×200"
    },
    marketingSize() {
        return "700×200"
    },
    imgFileSize() {
        return null;
    },
    videoFileSize() {
        return 5 * 1024
    },
    DPOperation(readOnly) {
        if (readOnly) {
            return {
                move: false,
                modify: false,
                del: false,
                add: false,
                check: false,
            }
        } else {
            return {
                move: false,
                modify: false,
                del: true,
                add: true,
                check: false,
            }
        }
    },
    DPCustomOperation(readOnly) {
        if (readOnly) {
            return {
                move: false,
                modify: false,
                del: false,
                add: false,
                check: true,
            }
        } else {
            return {
                move: true,
                modify: true,
                del: true,
                add: true,
                check: false,
            }
        }
    },
    DPMoveOperation(readOnly) {
        if (readOnly) {
            return {
                move: false,
                modify: false,
                del: false,
                add: false,
                check: false,
            }
        } else {
            return {
                move: true,
                modify: false,
                del: true,
                add: true,
                check: false,
            }
        }
    },

    // toTime1(time) {
    //     let d = new Date(time);
    //     let date =
    //         d.getFullYear() +
    //         "-" +
    //         (d.getMonth() + 1) +
    //         "-" +
    //         d.getDate() +
    //         " " +
    //         d.getHours() +
    //         ":" +
    //         d.getMinutes();
    //     return date;
    // },
    // toTime2(time) {
    //     let nHour = parseInt(time / 3600)
    //     let nMin = parseInt(time % 3600)
    //     let nSec = parseInt(nMin % 60)
    //     nMin /= 60
    //     if (nHour > 1) {
    //         return nHour + "小时" + parseInt(nMin) + "分" + nSec + "秒";
    //     } else if (nMin > 1) {
    //         return parseInt(nMin) + "分" + nSec + "秒";
    //     } else {
    //         return nSec + "秒";
    //     }
    // },
    // deepCopy(obj) {
    //     return JSON.parse(JSON.stringify(obj));
    // },
}
export default common