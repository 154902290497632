import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { ref } from "vue";
import api from "@api/member";
import useRoute from "@util/router";
export default {
  __name: 'MemberInfo',
  props: {
    type: {
      type: String
    }
  },

  setup(__props) {
    const props = __props;
    const route = useRoute();
    const data = ref({});
    getData();

    function getData() {
      let routeQuery = route.query();
      api.details(routeQuery.code, val => {
        data.value = val;
      });
    }

    return (_ctx, _cache) => {
      const _component_el_image = _resolveComponent("el-image");

      const _component_el_descriptions_item = _resolveComponent("el-descriptions-item");

      const _component_el_descriptions = _resolveComponent("el-descriptions");

      return _openBlock(), _createBlock(_component_el_descriptions, {
        column: 1,
        class: "base_descriptions_one"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_descriptions_item, {
          label: "头像"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_image, {
            src: data.value.avatar,
            fit: "fill"
          }, null, 8, ["src"])]),
          _: 1
        }), _createVNode(_component_el_descriptions_item, {
          label: "昵称"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(data.value.nickname), 1)]),
          _: 1
        }), _createVNode(_component_el_descriptions_item, {
          label: "账号"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(data.value.loginName), 1)]),
          _: 1
        }), _createVNode(_component_el_descriptions_item, {
          label: "性别"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(data.value.gender), 1)]),
          _: 1
        }), _createVNode(_component_el_descriptions_item, {
          label: "真实姓名"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(data.value.realName), 1)]),
          _: 1
        }), _createVNode(_component_el_descriptions_item, {
          label: "身份证"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(data.value.identityCardNumber), 1)]),
          _: 1
        }), _createVNode(_component_el_descriptions_item, {
          label: "地址"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(data.value.address), 1)]),
          _: 1
        }), _createVNode(_component_el_descriptions_item, {
          label: "累计消费（元）"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(data.value.totalPayMoney), 1)]),
          _: 1
        }), __props.type === 'memberdetails' ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
          key: 0,
          label: "会员积分"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(data.value.rewardPoints), 1)]),
          _: 1
        })) : _createCommentVNode("", true), _createVNode(_component_el_descriptions_item, {
          label: "创建时间"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(data.value.createTime), 1)]),
          _: 1
        })]),
        _: 1
      });
    };
  }

};