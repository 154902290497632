import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "base_screen_header"
};
const _hoisted_2 = {
  class: "base_main_header"
};
import GoodsTable from '@com/goods/GoodsTable.vue';
import CategoryManager from '@com/CategoryManager.vue';
import StatusBar from '@com/StatusBar.vue';
import { ref, reactive, watch } from 'vue';
import { Search } from '@element-plus/icons-vue';
import useRoute from "@util/router";
import $ from "@util/base";
import goodsConfigApi from "@api/goodsConfig";
import api from "@api/goods";
export default {
  __name: 'IntegralProduct',

  setup(__props) {
    const route = useRoute();
    const base = ref(route.query().base || '');
    const name = ref(route.query().name || '');
    const baseOptions = ref([]);
    getConfig();

    function getConfig() {
      goodsConfigApi.base(val => {
        baseOptions.value = val;
      });
    }

    function goodsExport() {
      let routeQuery = route.query();
      api.managerExport({
        categoryCode: routeQuery.c,
        shopCode: routeQuery.base,
        productName: routeQuery.name,
        state: routeQuery.status,
        isIntegral: true
      }, val => {
        $.download(val.url, "商品列表导出");
      });
    }

    function download(href, title) {
      const a = document.createElement('a');
      a.setAttribute('href', href);
      a.setAttribute('download', title);
      a.click();
    }

    function statusData(val) {// statusList.forEach((e, i) => { e.total = val[i] })
    }

    function searchBase(val) {
      route.replaceQuery('base', val);
    }

    function searchName(val) {
      route.replaceQuery('name', val);
    }

    function add() {
      route.push({
        name: 'integralProductDetails',
        query: {
          type: 'add'
        }
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_main = _resolveComponent("el-main");

      const _component_el_container = _resolveComponent("el-container");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createBlock(_component_el_container, null, {
        default: _withCtx(() => [_createVNode(_component_el_main, {
          class: "base_main_content"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            onClick: _cache[0] || (_cache[0] = $event => add())
          }, {
            default: _withCtx(() => [_createTextVNode("新增商品")]),
            _: 1
          })), [[_directive_auth, _ctx.getCurCode('ADD')]]), _createVNode(_component_el_form, {
            inline: true,
            class: "base_search_form",
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [_createVNode(_component_el_select, {
                modelValue: base.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => base.value = $event),
                onChange: searchBase,
                placeholder: "请选择基地",
                clearable: ""
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(baseOptions.value, (item, index) => {
                  return _openBlock(), _createBlock(_component_el_option, {
                    key: index,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]);
                }), 128))]),
                _: 1
              }, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: name.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => name.value = $event),
                "onKeyup[native]": searchName,
                onChange: searchName,
                placeholder: "商品名称",
                clearable: "",
                "suffix-icon": _unref(Search)
              }, null, 8, ["modelValue", "suffix-icon"])]),
              _: 1
            })]),
            _: 1
          })]), _createElementVNode("div", _hoisted_2, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            text: "",
            onClick: goodsExport
          }, {
            default: _withCtx(() => [_createTextVNode("导出")]),
            _: 1
          })), [[_directive_auth, _ctx.getCurCode('EXPORT')]])]), _createVNode(GoodsTable, {
            ref: "integralProductGoods",
            type: "manager",
            route: "integralProduct",
            isIntegral: true,
            onStatusData: statusData
          }, null, 512)]),
          _: 1
        })]),
        _: 1
      });
    };
  }

};