import { ElMessageBox } from 'element-plus'
import "element-plus/es/components/message-box/style/css";
const box = {
  open: function (title, msg, func) {
    ElMessageBox.confirm(msg, title, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
    }).then(() => {
      if (func != undefined && func != null) {
        func()
      }
    })
  },
  input: function (title, msg, value, func) {
    ElMessageBox.prompt(msg, title, {
      inputValue: value,
      confirmButtonText: '保存',
      cancelButtonText: '取消',
      inputErrorMessage: '输入不能为空',
      inputValidator: (value) => {       // 点击按钮时，对文本框里面的值进行验证
        if (!value) {
          return '输入不能为空';
        }
      },
    }).then(({ value }) => {
      func(value)
    })
  },
  number: function (title, msg, func) {
    ElMessageBox.prompt(msg, title, {
      inputType: 'number',
      confirmButtonText: '保存',
      cancelButtonText: '取消',
      inputErrorMessage: '输入不能为空',
      inputValidator: (value) => {       // 点击按钮时，对文本框里面的值进行验证
        console.log(value)
        if (!value) {
          return '输入不能为空';
        }
        if (value > 100) {
          return '每次最多100个';
        }
      },
    }).then(({ value }) => {
      func(value)
    })
  },
  alert: function(title, html, func) {
    ElMessageBox.alert(html, title, {
      dangerouslyUseHTMLString: true,
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      showCancelButton: true
    }).then(() => {
      if (func != undefined && func != null) {
        func()
      }
    })
  }
}
export default box