import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-aa823eb4"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "travel_details"
};
const _hoisted_2 = {
  class: "base_details_content"
};
const _hoisted_3 = {
  class: "content"
};
const _hoisted_4 = {
  class: "base_details_content_second"
};
import UploadPicture from "@com/upload/UploadPicture.vue";
import BackTitleView from "@com/BackTitleView.vue";
import { ref, reactive, nextTick, watch } from 'vue';
import useRoute from "@util/router";
import api from "@api/lineSharing";
import $ from "@util/base";
import { $box, $msg } from "@/common/ui/msg";
export default {
  __name: 'LineSharingDetails',

  setup(__props) {
    const route = useRoute();
    const urlQuery = route.query();
    var readOnly = false;
    var title = "分享详情";
    var modifyMode = false;
    $.handleCRU(urlQuery.type, {
      show: () => {
        readOnly = true;
        title = "分享详情";
        getData();
      },
      add: () => {
        title = "新增分享";
      },
      modify: () => {
        modifyMode = true;
        title = "修改分享";
        getData();
      },
      examine: () => {
        readOnly = true;
        title = "分享详情";
        getData();
      }
    });
    const programme = ref([]);
    const typeOptions = ref([]);
    const featureInfo = ref();
    const basicFormRef = ref(null);
    const basicForm = reactive({
      id: null,
      mainPic: '',
      mainTitle: '',
      subtitle: '',
      picList: []
    });
    const basicRules = {
      // mainPic: [
      //   {
      //     required: true,
      //     // type: "array",
      //     message: "请上传产品封面",
      //     trigger: "blur",
      //   },
      // ],
      mainTitle: [{
        required: true,
        message: "请输入分享标题",
        trigger: "blur"
      }],
      mainPic: [{
        required: true,
        message: "请上传产品封面",
        trigger: "blur"
      }]
    };
    var intervalSave = $.interval(save, 2500);

    function getValue() {
      let data = $.deepCopy(basicForm);
      data.mainPic = data.mainPic[0];
      return data;
    }

    function save(isExamine) {
      basicFormRef.value.validate((valid, fields) => {
        if (valid) {
          let data = getValue(); //校验基本信息

          $.handleCRU(urlQuery.type, {
            add: () => {
              api.add(data, () => {
                $msg.succ("保存成功");
                route.go(-1);
              });
            },
            modify: () => {
              api.update(data, () => {
                $msg.succ("保存成功");
                route.go(-1);
              });
            }
          });
        } else {
          $msg.error("请完善基本信息");
        }
      });
    }

    function getData() {
      api.get(route.query().id, val => {
        basicForm.id = val.id; // basicForm.content = val.content;

        basicForm.subtitle = val.subtitle;
        basicForm.mainTitle = val.mainTitle;
        basicForm.mainPic = [val.mainPic];
        basicForm.picList = val.picList;
      });
    }

    return (_ctx, _cache) => {
      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_main = _resolveComponent("el-main");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_footer = _resolveComponent("el-footer");

      const _component_el_container = _resolveComponent("el-container");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, {
        class: "base_details_container"
      }, {
        default: _withCtx(() => [_createVNode(BackTitleView, {
          title: _unref(title)
        }, null, 8, ["title"]), _createVNode(_component_el_main, null, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_form, {
            ref_key: "basicFormRef",
            ref: basicFormRef,
            model: basicForm,
            disabled: _unref(readOnly),
            rules: basicRules,
            "label-width": "100px",
            class: "base_details_form"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "封面图片",
              prop: "mainPic"
            }, {
              default: _withCtx(() => [_createVNode(UploadPicture, {
                modelValue: basicForm.mainPic,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => basicForm.mainPic = $event),
                limit: 1,
                imgSize: '750*564',
                fileSize: _unref($).imgFileSize(),
                disabled: _unref(readOnly)
              }, null, 8, ["modelValue", "fileSize", "disabled"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "主标题",
              prop: "mainTitle"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: basicForm.mainTitle,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => basicForm.mainTitle = $event),
                placeholder: "请输入"
              }, null, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "副标题",
              prop: "subtitle"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: basicForm.subtitle,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => basicForm.subtitle = $event),
                placeholder: "请输入"
              }, null, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "详情图片",
              prop: "picList"
            }, {
              default: _withCtx(() => [_createVNode(UploadPicture, {
                modelValue: basicForm.picList,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => basicForm.picList = $event),
                limit: 10,
                imgSize: '750x*',
                fileSize: _unref($).imgFileSize(),
                disabled: _unref(readOnly)
              }, null, 8, ["modelValue", "fileSize", "disabled"])]),
              _: 1
            })]),
            _: 1
          }, 8, ["model", "disabled"])])])])]),
          _: 1
        }), _createVNode(_component_el_footer, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            onClick: _cache[4] || (_cache[4] = $event => _unref(route).go(-1))
          }, {
            default: _withCtx(() => [_createTextVNode("取消")]),
            _: 1
          }), !_unref(readOnly) ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            type: "primary",
            onClick: _cache[5] || (_cache[5] = $event => _unref(intervalSave)(false))
          }, {
            default: _withCtx(() => [_createTextVNode("保存 ")]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      })]);
    };
  }

};