// 酒店
import HotelInput from '@/views/hotel/HotelInput.vue'
import HotelManager from '@/views/hotel/HotelManager.vue'
import HotelFacilitieManager from '@/views/hotel/HotelFacilitieManager.vue'
import RoomFacilitieManager from '@/views/hotel/RoomFacilitieManager.vue'
import ServiceManager from '@/views/hotel/ServiceManager.vue'
import HotelDetails from '@/views/hotel/HotelDetails.vue'
import HotelStockManager from '@/views/finance/HotelStockManager.vue'
import HotelLabelManager from "@/views/hotel/HotelLabelManager";

export default [{
    path: '/hotelinput',
    name: 'hotelinput',
    component: HotelInput,
    meta: {
        activeMainMenu: 'hotel',
        activeSubMenu: '/hotelinput'
    }
}, {
    path: '/hotelmanager',
    name: 'hotelmanager',
    component: HotelManager,
    meta: {
        activeMainMenu: 'hotel',
        activeSubMenu: '/hotelmanager'
    }
}, {
    path: '/hotelfacilitiemanager',
    name: 'hotelfacilitiemanager',
    component: HotelFacilitieManager,
    meta: {
        activeMainMenu: 'hotel',
        activeSubMenu: '/hotelfacilitiemanager'
    }
}, {
    path: '/roomfacilitiemanager',
    name: 'roomfacilitiemanager',
    component: RoomFacilitieManager,
    meta: {
        activeMainMenu: 'hotel',
        activeSubMenu: '/roomfacilitiemanager'
    }
}, {
    path: '/servicemanager',
    name: 'servicemanager',
    component: ServiceManager,
    meta: {
        activeMainMenu: 'hotel',
        activeSubMenu: '/servicemanager'
    }
}, {
    path: "/hotellabelmanager",
    name: "hotellabelmanager",
    component: HotelLabelManager,
    meta: {
        activeMainMenu: "hotel",
        activeSubMenu: "/hotellabelmanager",
    },
}, {
        path: '/hoteldetails',
        name: 'hoteldetails',
        component: HotelDetails,
        meta: {
            activeMainMenu: 'hotel',
            activeSubMenu: '/hotelinput'
        }
    }, {
        path: '/hotelstockmanager',
        name: 'hotelstockmanager',
        component: HotelStockManager,
        meta: {
            activeMainMenu: 'hotel',
            activeSubMenu: '/hotelstocksmanager'
        }
    }]