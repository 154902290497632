import { POST } from "@net/service.js";
import configApi from "./config"
import $ from "@util/base";

const URL = {
    get: 'team_building_intro_get',
    update: 'team_building_intro_update'
};

const api = {
    get(succ) {
        let requestOptions = {
            path: URL.get,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    update(params, succ) {
        let requestOptions = {
            path: URL.update,
            params: params,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
}

export default api
