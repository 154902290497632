// 旅行
import TravelInput from '@/views/travel/TravelInput.vue'
import TravelManager from '@/views/travel/TravelManager.vue'
import LabelManager from '@/views/travel/LabelManager.vue'
import GroupManager from '@/views/travel/GroupManager.vue'
import TrafficManager from '@/views/travel/TrafficManager.vue'
import TravelDetails from '@/views/travel/TravelDetails.vue'
import GroupRemainManager from '@/views/travel/GroupRemainManager.vue'
import GroupRemainOrder from '@/views/travel/GroupRemainOrder.vue'
import RegistrationList from '@/views/travel/RegistrationList.vue'
import OccupyManager from '@/views/travel/OccupyManager.vue'
import AdditionalFeesManager from '@/views/travel/AdditionalFeesManager.vue'
import TravelClassifyManager from '@/views/travel/TravelClassifyManager.vue'
import TravelAreaManager from '@/views/travel/TravelAreaManager.vue'

export default [{
    path: '/travelinput',
    name: 'travelinput',
    component: TravelInput,
    meta: {
        activeMainMenu: 'travel',
        activeSubMenu: '/travelinput'
    }
}, {
    path: '/travelmanager',
    name: 'travelmanager',
    component: TravelManager,
    meta: {
        activeMainMenu: 'travel',
        activeSubMenu: '/travelmanager'
    }
}, {
        path: '/travellabelmanager',
        name: 'travellabelmanager',
        component: LabelManager,
        meta: {
            activeMainMenu: 'travel',
            activeSubMenu: '/travellabelmanager'
        }
    }, {
        path: '/groupmanager',
        name: 'groupmanager',
        component: GroupManager,
        meta: {
            activeMainMenu: 'travel',
            activeSubMenu: '/groupmanager'
        }
    }, {
        path: '/trafficmanager',
        name: 'trafficmanager',
        component: TrafficManager,
        meta: {
            activeMainMenu: 'travel',
            activeSubMenu: '/trafficmanager'
        }
    }, {
        path: '/traveldetails',
        name: 'traveldetails',
        component: TravelDetails,
        meta: {
            activeMainMenu: 'travel',
            activeSubMenu: '/travelinput'
        }
    },
    {
        path: '/groupremainmanager',
        name: 'groupremainmanager',
        component: GroupRemainManager,
        meta: {
            activeMainMenu: 'travel',
            activeSubMenu: '/groupremainmanager'
        }
    }, {
        path: '/groupremainorder',
        name: 'groupremainorder',
        component: GroupRemainOrder,
        meta: {
            activeMainMenu: 'travel',
            activeSubMenu: '/groupremainmanager'
        }
    }, {
        path: '/registrationlist',
        name: 'registrationlist',
        component: RegistrationList,
        meta: {
            activeMainMenu: 'travel',
            activeSubMenu: '/groupremainmanager'
        }
    }, {
        path: '/occupymanager',
        name: 'occupymanager',
        component: OccupyManager,
        meta: {
            activeMainMenu: 'travel',
            activeSubMenu: '/groupremainmanager'
        }
    }, {
        path: '/additionalfeesmanager',
        name: 'additionalfeesmanager',
        component: AdditionalFeesManager,
        meta: {
            activeMainMenu: 'travel',
            activeSubMenu: '/additionalfeesmanager'
        }
    }, {
        path: '/travelclassifymanager',
        name: 'travelclassifymanager',
        component: TravelClassifyManager,
        meta: {
            activeMainMenu: 'travel',
            activeSubMenu: '/travelclassifymanager'
        }
    }, {
        path: '/TravelAreaManager',
        name: 'TravelAreaManager',
        component: TravelAreaManager,
        meta: {
            activeMainMenu: 'travel',
            activeSubMenu: '/travelareamanager'
        }
    }

]