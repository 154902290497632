import { POST } from "@net/service.js";
import $ from "@util/base";
import page from "./page";

const URL = {
    list: "additional_fees_list",
    details: "additional_fees_get",
    add: "additional_fees_add",
    update: "additional_fees_update",
    delete: "additional_fees_del",
};

const api = {
    list(params, succ) {
        let paramsData = {};
        paramsData.groupName = params.groupName;
        paramsData.extraName = params.extraName
        if ($.isNotEmpty(params.status) && params.status != "all") {
            paramsData.giftStatus = params.status;
        }
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.list,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    details(code, succ) {
        let requestOptions = {
            path: URL.details,
            params: { id: code },
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    add(params, succ) {
        let requestOptions = {
            path: URL.add,
            params: params,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    update(params, code, succ) {
        params.id = code
        let requestOptions = {
            path: URL.update,
            params: params,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    delete(code, succ) {
        let requestOptions = {
            path: URL.delete,
            params: { id: code },
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
};

export default api;