import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "deposit_money_list"
};
const _hoisted_2 = {
  class: "base_details_content"
};
const _hoisted_3 = {
  class: "content",
  style: {
    "margin-top": "20px"
  }
};
import { ref, reactive } from "vue";
import BackTitleView from "@com/BackTitleView.vue";
import $ from "@util/base";
import useRoute from "@util/router";
import api from "@api/deposit";
export default {
  __name: 'DepositMoneyList',

  setup(__props) {
    const route = useRoute();
    route.watchRouter("depositmoneylist", () => {
      getList();
    });
    var title = "储值完整记录";
    var code = 1;
    init();

    function init() {
      switch (route.query().type) {
        case "deposit":
          title = "储值余额列表";
          code = 1;
          getList();
          break;

        case "freeze":
          title = "冻结余额列表";
          code = 2;
          getList();
          break;

        case "user":
          title = "储值用户列表";
          code = 3;
          getList();
          break;
      }
    }

    const getConfig = function () {
      switch (route.query().type) {
        case "deposit":
          return depositConfig;

        case "freeze":
          return freezeConfig;

        case "user":
          return depositConfig;
      }
    };

    const data = ref([]);
    const total = ref(0);
    const depositConfig = {
      showIndex: true,
      column: [{
        label: "姓名",
        prop: "memberName"
      }, {
        label: "账号",
        prop: "memberLoginName"
      }, {
        label: "储值余额",
        prop: "depositMoney"
      }]
    };
    const freezeConfig = {
      showIndex: true,
      column: [{
        label: "姓名",
        prop: "memberName"
      }, {
        label: "账号",
        prop: "memberLoginName"
      }, {
        label: "冻结金额",
        prop: "freezeMoney"
      }]
    };

    function getList() {
      let routeQuery = route.query();
      api.moneyList({
        type: code,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        total.value = val.total;
        data.value = val.list;
      });
    }

    function back() {
      route.go(-1);
    }

    return (_ctx, _cache) => {
      const _component_DataCollectTable = _resolveComponent("DataCollectTable");

      const _component_el_main = _resolveComponent("el-main");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_footer = _resolveComponent("el-footer");

      const _component_el_container = _resolveComponent("el-container");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, {
        class: "base_details_container"
      }, {
        default: _withCtx(() => [_createVNode(BackTitleView, {
          title: _unref(title)
        }, null, 8, ["title"]), _createVNode(_component_el_main, null, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_DataCollectTable, {
            data: data.value,
            total: total.value,
            config: getConfig()
          }, null, 8, ["data", "total", "config"])])])]),
          _: 1
        }), _createVNode(_component_el_footer, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            onClick: back
          }, {
            default: _withCtx(() => [_createTextVNode("关闭")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]);
    };
  }

};