import {defineStore} from "pinia";

export default defineStore("cw-auth", {
  state: () => {
    return {
      // 按钮权限列表
      permissionsMap: {},
      // 当前页面的 router name，用来做按钮权限筛选
      routeName: "",
      // 菜单权限列表
      permissionList: [],
      permissionCodes: [],
      // 按钮权限列表
      btnPermissions: [],
    }
  },
  getters: {},
  actions: {
    setPermissions(perms) {
      if (perms === null) return
      let permsObj = {}
      for (let i = 0; i < perms.length; i++) {
        permsObj[perms[i].code] = perms[i].buttons
      }
      this.permissionsMap = permsObj
    },
    setPermissionCodes(perms) {
      this.permissionCodes = perms
    },
    setPermissionList(perms) {
      this.permissionList = perms
    },
    setBtnPermissions(perms) {
      this.btnPermissions = perms
    },
    clearPermissions() {
      this.permissionsMap = {}
      this.permissionList = []
      this.permissionCodes = []
      this.btnPermissions = []
    }
  },
  // 数据持久化
  persist: true,
})
