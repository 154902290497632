import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "base_screen_header"
};
import DataCollectTable from "@com/table/DataCollectTable.vue";
import { OP_KEY, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import { $msg, $box } from "@ui/msg";
import { ref, reactive } from "vue";
import { Search } from "@element-plus/icons-vue";
import api from "@api/couponTiming";
import $ from "@util/base";
import useRoute from "@util/router";
export default {
  __name: 'CouponTimingManager',

  setup(__props) {
    const route = useRoute();
    const name = ref(route.query().name || "");
    const total = ref(0);
    const data = ref([]);
    route.watchRouter("couponmanager", () => {
      getData();
    });
    getData();

    function getData() {
      let routeQuery = route.query();
      let params = {
        name: routeQuery.name,
        page: routeQuery.p,
        size: routeQuery.s
      };
      api.list(params, val => {
        total.value = val.total;
        data.value = val.list;
      });
    }

    function add() {
      route.push({
        name: 'couponTimingDetails',
        query: {
          type: 'add'
        }
      });
    }

    function getOperations(data) {
      let op = [];
      op.push(OPERATIONS.check);
      op.push(OPERATIONS.copy);

      if (data.enableStatus == 1001001) {
        op.push(OPERATIONS.couponStop);
      } else {
        op.push(OPERATIONS.modify);
        op.push(OPERATIONS.couponStart);
      }

      return op;
    }

    const config = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: getOperations
      },
      column: [{
        label: "名称",
        prop: "name",
        width: 200
      }, {
        label: "已发放(人)",
        prop: "provideNum",
        width: 100
      }, {
        label: "发放时间",
        prop: "sendDate"
      }, {
        label: "当前状态",
        prop: "enableStatusName"
      }, {
        label: "创建时间",
        prop: "createTime"
      }, {
        label: "创建人",
        prop: "creator"
      }]
    };
    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      route.push({
        name: 'couponTimingDetails',
        query: {
          type: 'show',
          code: data.code
        }
      });
    });
    opHander.install(OP_KEY.modify, data => {
      route.push({
        name: 'couponTimingDetails',
        query: {
          type: 'modify',
          code: data.code
        }
      });
    });
    opHander.install(OP_KEY.copy, data => {
      api.clone(data.code, val => {
        $msg.succ("复制优惠券成功");
        getData();
      });
    });
    opHander.install(OP_KEY.grant, data => {
      dialogVisible.value = true;
      provideFlagForm.value.couponCode = data.couponCode;
      resetForm();
    });
    opHander.install(OP_KEY.couponStop, data => {
      api.stop(data.code, val => {
        $msg.succ("停用优惠券成功");
        getData();
      });
    });
    opHander.install(OP_KEY.couponStart, data => {
      api.start(data.code, val => {
        $msg.succ("启用优惠券成功");
        getData();
      });
    });

    function searchName(val) {
      route.replaceQuery("name", val);
    }

    const dialogVisible = ref(false);
    const provideFlagFormRef = ref('');
    const provideFlagForm = ref({
      couponCode: null,
      provideFlag: ''
    });
    const provideFlagRules = {
      provideFlag: [{
        required: true,
        message: '请选择发放群体',
        trigger: 'change'
      }]
    };

    const submitForm = () => {
      provideFlagFormRef.value.validate(valid => {
        if (valid) {
          api.provide(provideFlagForm.value, val => {
            $msg.succ("发放优惠券成功");
            dialogVisible.value = false;
            getData();
          });
        }
      });
    };

    const resetForm = () => {
      if (!provideFlagFormRef.value) return;
      provideFlagFormRef.value.resetFields();
    };

    function getExport() {
      let routeQuery = route.query();
      api.export({
        name: routeQuery.name
      }, val => {
        $.download(val.url, "优惠券列表导出");
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_main = _resolveComponent("el-main");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          onClick: add,
          type: "primary",
          style: {
            "margin-right": "20px"
          }
        }, {
          default: _withCtx(() => [_createTextVNode("定时发放")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('ADD')]])]), _createVNode(_component_el_form, {
          inline: true,
          class: "base_search_form",
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: name.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => name.value = $event),
              "onKeyup[native]": searchName,
              onChange: searchName,
              placeholder: "名称",
              "suffix-icon": _unref(Search),
              clearable: ""
            }, null, 8, ["modelValue", "suffix-icon"])]),
            _: 1
          })]),
          _: 1
        })]), _createVNode(DataCollectTable, {
          data: data.value,
          total: total.value,
          config: config,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"])]),
        _: 1
      })]);
    };
  }

};