import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.inc(0.2)
NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false })
NProgress.done()
const progress = {
    start: function() {
        NProgress.start()
    },
    done: function() {
        NProgress.done()
    }
}
export default progress