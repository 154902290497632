// 相册
import AlbumManager from '@view/memory/albumManager.vue'

export default [{
    path: '/albumManager',
    name: 'albumManager',
    component: AlbumManager,
    meta: {
        activeMainMenu: 'memory',
        activeSubMenu: '/albumManager'
    }
}]
