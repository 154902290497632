import { POST } from "@net/service.js";
import configApi from "./config"
import $ from "@util/base";
import page from "./page"

const URL = {
    list: 'line_area_config_list',
    add: 'line_area_config_add',
    del: 'line_area_config_del',
    update: 'line_area_config_update',
    chooseList: 'choose_line_area_config_list',
};

const api = {
    list(params, succ) {
        let paramsData = {}
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.list,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    add(params, succ) {
        let requestOptions = {
            path: URL.add,
            params: params,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    update(params, succ) {
        let requestOptions = {
            path: URL.update,
            params: params,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    del(code, succ) {
        let requestOptions = {
            path: URL.del,
            params: { code: code },
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    chooseList(succ) {
        let requestOptions = {
            path: URL.chooseList,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
}

export default api
