import { POST } from "@net/service.js";
import $ from "@util/base";
import page from "./page"

const URL = {
    goods: "choose_product",
    hotel: "choose_hotel",
    travel: "choose_travel",
    gift: "choose_gift_package",
    classify: "marketing_classify_list",
    hotelRoom: "choose_hotel_room",
    productSku: "choose_product_sku",
    travelPackage: "choose_travel_package",
    giftPackage: "choose_gift_package",
    coupon: "coupon_list",
};

const api = {
    chooseList(type, params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.code)) {
            paramsData.code = params.code
        }
        if ($.isNotEmpty(params.name)) {
            paramsData.name = params.name
        }
        if ($.isNotEmpty(params.codeList)) {
            paramsData.codeList = params.codeList
        }
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL[type],
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    }
}

export default api
