import { createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "stock_recode"
};
const _hoisted_2 = {
  class: "base_screen_header"
};
const _hoisted_3 = {
  class: "base_main_header"
};
import BackTitleView from "@com/BackTitleView.vue";
import DataCollectTable from "@com/table/DataCollectTable.vue";
import { ref } from "vue";
import { Search } from "@element-plus/icons-vue";
import useRoute from "@util/router";
import $ from "@util/base";
import api from "@api/goodsStock";
export default {
  __name: 'GoodsStockRecode',

  setup(__props) {
    const route = useRoute();
    const total = ref(0);
    const data = ref([]);
    const typeOptions = ref([]);
    const baseOptions = ref([]);
    const type = ref(route.query().type || "");
    const base = ref(route.query().base || "");
    const name = ref(route.query().name || "");
    const date = ref(route.query().date || []);

    function searchDate(val) {
      route.replaceQuery("date", val);
    }

    getConfig();

    function getConfig() {
      api.base(val => {
        baseOptions.value = val;
      });
      api.changeType(val => {
        typeOptions.value = val;
      });
    }

    const config = {
      showIndex: true,
      column: [{
        label: "商品名称",
        prop: "productName"
      }, {
        label: "规格名称",
        prop: "attrDetail",
        width: 180
      }, {
        label: "规格编码",
        prop: "skuCode",
        width: 140
      }, {
        label: "归属基地",
        prop: "shop",
        width: 100
      }, {
        label: "变更数量",
        prop: "changeNum",
        width: 120
      }, {
        label: "变更类型",
        prop: "changeTypeName",
        width: 120
      }, {
        label: "关联订单",
        prop: "changeInfo",
        width: 140
      }, {
        label: "变更时间",
        prop: "createTime",
        width: 150
      }, {
        label: "操作人",
        prop: "creator",
        width: 90
      }, {
        label: "备注",
        prop: "remark",
        width: 160
      }]
    };

    function searchType(val) {
      route.replaceQuery("type", val);
    }

    function searchBase(val) {
      route.replaceQuery("base", val);
    }

    function searchName(val) {
      route.replaceQuery("name", val);
    }

    function openImport() {
      route.push({
        name: "offlinestockimport"
      });
    }

    getData();
    route.watchRouter("goodsstockrecode", () => {
      getData();
    });

    function getData() {
      let routeQuery = route.query();
      api.stockChangeRecode({
        changeType: routeQuery.type,
        shopCode: routeQuery.base,
        productName: routeQuery.name,
        sku: routeQuery.sku,
        date: routeQuery.date,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        data.value = val.itemList;
        total.value = val.total;
      });
    }

    function getExport() {
      let routeQuery = route.query();
      api.stockChangeExport({
        changeType: routeQuery.type,
        shopCode: routeQuery.base,
        productName: routeQuery.name
      }, val => {
        $.download(val.url, "库存变更记录导出");
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_date_picker = _resolveComponent("el-date-picker");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_main = _resolveComponent("el-main");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createVNode(BackTitleView, {
          title: "库存变更记录"
        }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: openImport
        }, {
          default: _withCtx(() => [_createTextVNode("线下库存变更导入")]),
          _: 1
        }), _createVNode(_component_el_form, {
          inline: true,
          class: "base_search_form",
          onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_date_picker, {
              modelValue: date.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => date.value = $event),
              type: "daterange",
              "range-separator": "~",
              "start-placeholder": "开始时间",
              "end-placeholder": "结束时间",
              "value-format": "YYYY-MM-DD",
              onChange: searchDate
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: type.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => type.value = $event),
              onChange: searchType,
              placeholder: "请选择类型",
              clearable: ""
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(typeOptions.value, (item, index) => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: index,
                  label: item.label,
                  value: item.value
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: base.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => base.value = $event),
              onChange: searchBase,
              placeholder: "请选择基地",
              clearable: ""
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(baseOptions.value, (item, index) => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: index,
                  label: item.label,
                  value: item.value
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: name.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => name.value = $event),
              "onKeyup[native]": searchName,
              onChange: searchName,
              placeholder: "商品名称",
              "suffix-icon": _unref(Search),
              clearable: ""
            }, null, 8, ["modelValue", "suffix-icon"])]),
            _: 1
          })]),
          _: 1
        })]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_button, {
          type: "primary",
          text: "",
          onClick: getExport
        }, {
          default: _withCtx(() => [_createTextVNode("导出")]),
          _: 1
        })]), _createVNode(DataCollectTable, {
          data: data.value,
          config: config,
          total: total.value
        }, null, 8, ["data", "total"])]),
        _: 1
      })]);
    };
  }

};