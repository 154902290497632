import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "specs_manager"
};
const _hoisted_2 = {
  class: "base_screen_header"
};
const _hoisted_3 = {
  class: "btn-box"
};
import { $msg, $box } from "@ui/msg";
import DataCollectTable from "@com/table/DataCollectTable.vue";
import { OP_KEY, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import { ref } from "vue"; // import specsData from "@/test/specsData.json";

import api from "@api/singleField";
import useRoute from "@util/router";
export default {
  __name: 'SingleFieldManager',
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          name: "",
          type: "",
          route: "",
          extraButton: ""
        };
      }
    },
    customAdd: {
      type: Boolean,
      default: false
    }
  },
  emits: ["add", "show", "modify"],

  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const data = ref([]);
    const total = ref(0);
    const route = useRoute();

    function goodsOperations(data) {
      if (props.customAdd) {
        return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.del];
      } else {
        return [OPERATIONS.modify, OPERATIONS.del];
      }
    }

    init();

    function init() {
      if (props.config.extraButton === "foreEndFilter") {
        getFilterStatus();
      }
    }

    route.watchRouter(props.config.route, () => {
      getData();
    });
    const tableConfig = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 140,
        operations: goodsOperations
      },
      column: [{
        label: props.config.name + "名称",
        prop: "name"
      }]
    };
    getData();

    function getData() {
      let routeQuery = route.query();
      api.list(props.config.type, {
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        data.value = val.itemList;
        total.value = val.total;
      });
    }

    function add() {
      if (props.customAdd) {
        emit("add");
      } else {
        $box.input("新增", props.config.name + "名称", null, val => {
          api.add(props.config.type, val, () => {
            getData();
            $msg.succ("新增成功");
          });
        });
      }
    }

    const opHander = operationsHander();
    opHander.install(OP_KEY.modify, data => {
      if (props.customAdd) {
        emit("modify", data);
      } else {
        $box.input("修改", props.config.name + "名称", data.name, val => {
          api.updata(props.config.type, data.id, val, () => {
            getData();
            $msg.succ("修改成功");
          });
        });
      }
    });
    opHander.install(OP_KEY.delete, data => {
      $box.open("删除确认", `是否删除${data.name}`, () => {
        api.del(props.config.type, data.id, () => {
          getData();
          $msg.succ("删除成功");
        });
      });
    });
    opHander.install(OP_KEY.check, data => {
      emit("show", data);
    });
    var filterStatus = 0;
    const filterStatusVal = ref("开启");

    function filterStatusChange() {
      api.updForeEndFilter(props.config.type, filterStatus, () => {
        getFilterStatus();
      });
    }

    function getFilterStatus() {
      // 当前状态 0 不启用， 1 启用 ，按钮显示反方文案
      api.statusForeEndFilter(props.config.type, val => {
        filterStatus = val.isStart;

        if (val.isStart === 1) {
          filterStatusVal.value = "关闭";
        } else {
          filterStatusVal.value = "启用";
        }
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_main = _resolveComponent("el-main");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          onClick: add,
          type: "primary"
        }, {
          default: _withCtx(() => [_createTextVNode(" 新增" + _toDisplayString(__props.config.name), 1)]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('ADD')]]), __props.config.extraButton === 'foreEndFilter' ? _withDirectives((_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          onClick: filterStatusChange,
          type: "primary"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(`${filterStatusVal.value}前端筛选`), 1)]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('ENABLE')]]) : _createCommentVNode("", true)])]), _createVNode(DataCollectTable, {
          data: data.value,
          total: total.value,
          config: tableConfig,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"])]),
        _: 1
      })]);
    };
  }

};