import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "dialog-footer"
};
const _hoisted_3 = {
  class: "dynamic_params_layout"
};
const _hoisted_4 = {
  class: "pagination_layout"
};
const _hoisted_5 = {
  class: "dialog-footer"
};
import { ref, reactive } from "vue";
import $ from "@util/base";
import { $msg } from "@ui/msg";
import api from "@/api/marketingClassify";
import useRoute from "@/common/utils/router";
export default {
  __name: 'ChooseProduct2',
  props: {
    config: Object,
    data: Array,
    //数据源
    total: {
      type: Number,
      default: 0
    },
    //总条数
    isRoute: {
      type: Boolean,
      default: true
    },
    // 分页切换是否上路由 需要配合pageChange
    isRadio: {
      type: Boolean,
      default: true
    } // 是否是单选

  },
  emits: ["chooseProduct2"],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const emit = __emit;
    const dialogVisible = ref(false);
    const dialogVisible2 = ref(false);
    const addTableRef = ref(null);
    const tableRef = ref(null);
    const data = ref([]);
    const size = ref(20);
    const page = ref(1);
    const total = ref(0);
    const typeKey = ref(null);
    const title = ref(null);
    const props = __props;
    const route = useRoute();

    function show(val) {
      typeKey.value = val;

      switch (val) {
        case 'goods':
          title.value = '添加商品';
          break;

        case 'travel':
          title.value = '添加旅游';
          break;

        case 'hotel':
          title.value = '添加酒店';
          break;

        case 'gift':
          title.value = '添加礼包';
          break;

        case 'classify':
          title.value = '添加分类';
          break;
      }

      if (val == 'classify') {
        getList();
        dialogVisible2.value = true;

        if (!tableRef.value) {
          return;
        }

        tableRef.value.clearSelection();
      } else {
        dialogVisible.value = true;

        if (!addTableRef.value) {
          return;
        }

        addTableRef.value.clearSelection();
      }
    }

    function pagerChange(val) {
      if (props.isRoute) {
        route.replaceQuery("p", val);
      } else {
        emit("pageChange", "page", val);
      }
    }

    function pagerSizeChange(val) {
      if (props.isRoute) {
        route.replaceQuery("s", val);
      } else {
        emit("pageChange", "size", val);
      }
    }

    function getList() {
      api.list({
        page: page.value,
        size: size.value
      }, val => {
        total.value = val.total;

        if (val.parentItemList && val.parentItemList.length > 0) {
          data.value = val.parentItemList.map(item => {
            item.level = 1;

            if (item.children && item.children.length > 0) {
              item.children = item.children.map(child => {
                child.level = 2;
                return child;
              });
            }

            return item;
          });
        } else {
          data.value = val.parentItemList;
        }
      });
    }

    function close() {
      dialogVisible.value = false;
      dialogVisible2.value = false;
    }

    function saveProduct() {
      let data = addTableRef.value.getSelectData();

      if ($.isEmpty(data)) {
        $msg.error("请勾选需要添加的产品");
      } else {
        emit("chooseProduct2", {
          code: data[0].code,
          name: data[0].name,
          type: typeKey.value
        });
        close();
      }
    }

    function saveProduct2() {
      let data = tableRef.value.getSelectionRows();

      if ($.isEmpty(data)) {
        $msg.error("请勾选需要添加的产品");
      } else {
        emit("chooseProduct2", {
          code: data[0].code,
          name: data[0].name,
          type: typeKey.value
        });
        close();
      }
    }

    function handleSelectChange(selection, row) {
      if (props.isRadio) {
        if (selection.length > 1) {
          clearSelection();
          tableRef.value.toggleRowSelection(selection.pop());
        }
      }
    }

    function clearSelection() {
      tableRef.value.clearSelection();
    }

    var selectData = [];

    function handleSelectionChange(val) {
      selectData = val;
    }

    function getSelectData() {
      return selectData;
    }

    function empty() {
      total.value = 0;
      data.value = [];
    }

    __expose({
      show,
      getSelection,
      getSelectData,
      empty,
      clearSelection
    });

    return (_ctx, _cache) => {
      const _component_ProductTable = _resolveComponent("ProductTable");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_table = _resolveComponent("el-table");

      const _component_el_pagination = _resolveComponent("el-pagination");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => dialogVisible.value = $event),
        title: title.value,
        width: "720px",
        class: "base_dialog home_page_dialog",
        "destroy-on-close": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_2, [_createVNode(_component_el_button, {
          onClick: saveProduct,
          type: "primary"
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: close
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ProductTable, {
          type: "add",
          ref_key: "addTableRef",
          ref: addTableRef,
          isRadio: true,
          typeKey: typeKey.value
        }, null, 8, ["typeKey"])])]),
        _: 1
      }, 8, ["modelValue", "title"]), _createVNode(_component_el_dialog, {
        modelValue: dialogVisible2.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => dialogVisible2.value = $event),
        title: title.value,
        width: "720px",
        class: "base_dialog home_page_dialog",
        "destroy-on-close": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_5, [_createVNode(_component_el_button, {
          onClick: saveProduct2,
          type: "primary"
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: close
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_table, {
          ref_key: "tableRef",
          ref: tableRef,
          data: data.value,
          "row-key": "code",
          border: "",
          onSelect: handleSelectChange,
          onSelectionChange: handleSelectionChange
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            type: "selection",
            width: "55",
            align: "center"
          }), _createVNode(_component_el_table_column, {
            label: "区域编号",
            prop: "code",
            align: "center"
          }), _createVNode(_component_el_table_column, {
            label: "区域名称",
            prop: "name",
            align: "center"
          }), _createVNode(_component_el_table_column, {
            label: "排序",
            prop: "sort",
            align: "center"
          })]),
          _: 1
        }, 8, ["data"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_pagination, {
          background: "",
          layout: "total, prev, pager, next, sizes",
          total: total.value,
          currentPage: page.value,
          "onUpdate:currentPage": _cache[1] || (_cache[1] = $event => page.value = $event),
          "page-size": size.value,
          "onUpdate:pageSize": _cache[2] || (_cache[2] = $event => size.value = $event),
          "page-sizes": [10, 20, 50, 100],
          onCurrentChange: pagerChange,
          onSizeChange: pagerSizeChange
        }, null, 8, ["total", "currentPage", "page-size"])])]),
        _: 1
      }, 8, ["modelValue", "title"])], 64);
    };
  }

};