import { POST } from "@net/service.js";
import { TYPE, api as categoryApi } from "./category"
import configApi from "./config";

const URL = {
    natures: 'choose_travel_join_config_list',
    targetarea: 'choose_targetarea_list',
    content: "choose_travel_join_config_list"
};

const api = {
    base(succ) {
        configApi.base(succ);
    },
    category(succ) {
        categoryApi.getCategory(TYPE.travel, val => {
            succ(val.itemList.map(e => { return { label: e.catName, value: e.catCode } }))
        })
    },
    content(succ) {
        let requestOptions = {
            path: URL.content,
            succ: val => {
                succ(val.itemList.map(e => {
                    return { label: e.name, value: e.id + "" }
                }))
            }
        }
        POST(requestOptions)
    },
    targetarea(succ) {
        let requestOptions = {
            path: URL.targetarea,
            succ: val => {
                succ(val.itemList.map(e => {
                    return { label: e.name, value: e.code }
                }))
            }
        }
        POST(requestOptions)
    },
    label(succ) {
        configApi.label(TYPE.travel, succ)
    },
    natures(succ) {
        let requestOptions = {
            path: URL.natures,
            succ: val => {
                succ(val.itemList.map(e => {
                    return { label: e.name, value: e.id }
                }))
            }
        }
        POST(requestOptions)
    },
    province(succ) {
        configApi.area(null, 1, succ)
    }
};

export default api;