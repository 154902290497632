import { createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  class: "base_details_content"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  style: {
    "margin-bottom": "20px"
  }
};
const _hoisted_4 = {
  style: {
    "margin-bottom": "20px"
  }
};
const _hoisted_5 = {
  class: "base_screen_header"
};
const _hoisted_6 = {
  class: "dialog-footer"
};
import $ from "@/common/utils/base";
import { reactive, ref } from "vue";
import defaultPage from "@/api/page";
import api from "@api/marketingClassify";
import { $box, $msg } from "@/common/ui/msg";
import useRoute from "@/common/utils/router";
import BackTitleView from "@/components/BackTitleView.vue";
import DataCollectTable from "@/components/table/DataCollectTable.vue";
import { COLUMN_TYPE, OP_KEY, OPERATIONS, operationsHander } from "@/components/table/tableOperations";
import { Search } from "@element-plus/icons-vue";
import productApi from "@/api/productChoose";
const title = "设置产品";
export default {
  __name: 'CategoryProductManager',

  setup(__props) {
    const tableRef = ref(null);
    const data = ref([]);
    const dataConfig = ref({
      showIndex: true,
      operations: {
        label: '操作',
        width: 200,
        operations: getOperations
      },
      column: [{
        label: "产品图片",
        prop: "pic",
        type: COLUMN_TYPE.picture,
        width: 100
      }, {
        label: "产品名称",
        prop: "name"
      }, {
        label: "产品类型",
        prop: "typeName"
      }, {
        label: "产品编号",
        prop: "code"
      }]
    });
    const size = ref(20);
    const page = ref(1);
    const total = ref(0);
    const dialogVisible = ref(false);
    const dialogTitle = ref("");
    const formRef = ref(null);
    const route = useRoute();
    const dataHandler = operationsHander();
    dataHandler.install(OP_KEY.delete, data => {
      $box.open("移除", "确认移除产品：" + data.name, () => {
        api.productDel({
          id: data.id
        }, res => {
          $msg.succ("移除成功");
          getData();
        });
      });
    }); //1061001旅行 1061002酒店 1061003商品 1061004礼包

    const typeOptions = [{
      label: "商品",
      value: "1061003",
      fun: "goods"
    }, {
      label: "酒店",
      value: "1061002",
      fun: "hotel"
    }, {
      label: "旅行",
      value: "1061001",
      fun: "travel"
    }, {
      label: "礼包",
      value: "1061004",
      fun: "gift"
    }];
    const productDataTableRef = ref(null);
    const addProductDialog = ref(false);
    const productTableIf = ref(false);
    const productData = ref([]);
    const productName = ref("");
    const productType = ref("商品");
    const productTypeKey = ref("goods");
    const productTypeValue = ref("1061003");
    const productDataConfig = ref({
      showIndex: true,
      showSelection: true,
      column: [{
        label: "产品图片",
        prop: "mainPic",
        type: COLUMN_TYPE.picture,
        width: 100
      }, {
        label: "产品名称",
        prop: "name"
      }, {
        label: "产品编号",
        prop: "code",
        width: 100
      }, {
        label: "产品分类",
        prop: "category",
        width: 100
      }]
    });
    const productPageOpHandler = operationsHander();
    const productDataSize = ref(20);
    const productDataPage = ref(1);
    const productDataTotal = ref(0);
    const itemList = ref([]);
    getData();

    function getOperations(row) {
      return [OPERATIONS.del];
    }

    function pagerChange(type, val) {
      if (type == 'page') {
        page.value = val;
      } else if (type == 'size') {
        size.value = val;
      }

      getData();
    }

    function getData() {
      api.productGet({
        code: route.query().code,
        page: page.value,
        size: size.value
      }, res => {
        if (res && res.itemList) {
          data.value = res.itemList.map(item => {
            return { ...item,
              typeName: typeOptions.find(e => e.value === item.type).label
            };
          });
          total.value = res.total;
        }
      });
    }

    function showAddProduct() {
      getProductData();
      addProductDialog.value = true;
    }

    function typeOptionsChange(item) {
      typeOptions.find(e => {
        if (e.label === item) {
          productTypeKey.value = e.fun;
          productTypeValue.value = e.value;
          getProductData();
        }
      });
    }

    function getProductData() {
      productApi.chooseList(productTypeKey.value, {
        name: productName.value,
        page: productDataPage.value,
        size: productDataSize.value
      }, val => {
        productData.value = val.itemList;
        productDataTotal.value = val.total;
      });
    }

    function getProductPageChange(type, val) {
      if (type == 'page') {
        productDataPage.value = val;
      } else if (type == 'size') {
        productDataSize.value = val;
      }

      getProductData();
    }

    function productDataSelectedSubmit() {
      let selectedData = productDataTableRef.value.getSelectData();

      if ($.isEmpty(selectedData)) {
        $msg.error("请勾选需要添加的产品");
      } else {
        typeOptions.find(e => {
          if (e.label === productType.value) {
            const param = {
              code: route.query().code,
              itemList: selectedData.map(item => {
                return {
                  code: item.code,
                  name: item.name,
                  pic: item.mainPic,
                  type: productTypeValue.value
                };
              })
            };
            api.productAdd(param, res => {
              $msg.succ("添加成功");
              addProductDialog.value = false;
              getData();
            });
          }
        });
      }
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_main = _resolveComponent("el-main");

      const _component_el_footer = _resolveComponent("el-footer");

      const _component_el_container = _resolveComponent("el-container");

      const _component_el_radio_button = _resolveComponent("el-radio-button");

      const _component_el_radio_group = _resolveComponent("el-radio-group");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_container, {
        class: "base_details_container"
      }, {
        default: _withCtx(() => [_createVNode(BackTitleView, {
          title: title
        }), _createVNode(_component_el_main, null, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_button, {
            type: "primary",
            onClick: showAddProduct
          }, {
            default: _withCtx(() => [_createTextVNode("添加产品")]),
            _: 1
          })]), _createVNode(DataCollectTable, {
            ref: "categoryProductRef",
            data: data.value,
            config: dataConfig.value,
            page: page.value,
            size: size.value,
            total: total.value,
            "is-page": false,
            onOperationsHander: _unref(dataHandler).receive
          }, null, 8, ["data", "config", "page", "size", "total", "onOperationsHander"])])])]),
          _: 1
        }), _createVNode(_component_el_footer, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            onClick: _cache[0] || (_cache[0] = $event => _unref(route).go(-1))
          }, {
            default: _withCtx(() => [_createTextVNode("关闭")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_dialog, {
        title: "添加产品",
        modelValue: addProductDialog.value,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => addProductDialog.value = $event),
        width: "80%",
        "destroy-on-close": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_6, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: productDataSelectedSubmit
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: _cache[4] || (_cache[4] = $event => addProductDialog.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_radio_group, {
          modelValue: productType.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => productType.value = $event),
          size: "large",
          onChange: typeOptionsChange
        }, {
          default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(typeOptions, item => {
            return _createVNode(_component_el_radio_button, {
              key: item.value,
              label: item.label,
              value: item.value
            }, null, 8, ["label", "value"]);
          }), 64))]),
          _: 1
        }, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_form, {
          inline: true,
          class: "base_search_form",
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: productName.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => productName.value = $event),
              "onKeyup[native]": getProductData,
              onChange: getProductData,
              placeholder: "产品名称",
              clearable: "",
              "suffix-icon": _unref(Search)
            }, null, 8, ["modelValue", "suffix-icon"])]),
            _: 1
          })]),
          _: 1
        })]), _createVNode(DataCollectTable, {
          ref_key: "productDataTableRef",
          ref: productDataTableRef,
          data: productData.value,
          config: productDataConfig.value,
          isRoute: false,
          onOperationsHander: _unref(productPageOpHandler).receive,
          total: productDataTotal.value,
          onPageChange: getProductPageChange
        }, null, 8, ["data", "config", "onOperationsHander", "total"])]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }

};