import { useRouter, useRoute } from "vue-router";
import { watch } from 'vue'

export function useWatchRouter(route, routeName, func) {
    watch(
        () => route.params,
        () => {
            if (routeName != null) {
                if (route.name == routeName) {
                    func(route.params)
                }
            } else {
                func(route)
            }
        }
    )
}

export default function useR() {
    const router = useRouter();
    const route = useRoute();

    function watchRouter(name, func) {
        useWatchRouter(route, name, func)
    }

    function replace(path) {
        router.replace(path)
    }

    function push(path) {
        router.push(path)
    }

    function name() {
        return route.name
    }

    function query() {
        return route.query
    }

    function params() {
        return route.params
    }

    function go(step) {
        router.go(step)
    }

    function activeMeta(main, sub) {
        route.meta.activeMainMenu = main
        route.meta.activeSubMenu = '/' + sub
    }

    function replaceQuery(replaceKey, val) {
        let query = {}
        let oldQuery = this.query()
        for (let key in oldQuery) {
            if (key != replaceKey) {
                query[key] = oldQuery[key]
            }
        }
        if (val != undefined && val != null) {
            query[replaceKey] = val
        }
        this.replace({ name: this.name(), query: query })
    }

    function replaceQuerys(values) {
        let query = {}
        let oldQuery = this.query()
        for (const value of values) {
            for (let key in oldQuery) {
                if (key != value.replaceKey) {
                    query[key] = oldQuery[key]
                }
            }
            if (value.val != undefined && value.val != null) {
                query[value.replaceKey] = value.val
            }
            oldQuery = query
        }
        this.replace({ name: this.name(), query: query })
    }

    function clearQuerys(replaceKey, val) {
        var query = {}
        query[replaceKey] = val
        this.replace({ name: this.name(), query: query })
    }

    return {
        watchRouter,
        replace,
        push,
        name,
        params,
        query,
        go,
        replaceQuery,
        replaceQuerys,
        clearQuerys,
        activeMeta
    }
}
