import {createRouter, createWebHistory} from 'vue-router'

import overview from "./overview"
import goods from "./goods"
import travel from "./travel"
import hotel from "./hotel"
import order from "./order"
import marketing from "./marketing"
import finance from "./finance"
import examine from "./examine"
import member from "./member"
import setting from "./setting"
import teamBuilding from "./teamBuilding"
import memory from "./memory"

const routes = [].concat(
  overview,
  goods,
  travel,
  hotel,
  order,
  marketing,
  finance,
  examine,
  member,
  setting,
  teamBuilding,
  memory
)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
})

// router.beforeEach((to, from, next) => {
//   next()
// })

// router.afterEach((to) => {
// })

export default router
