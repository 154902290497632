import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "base_screen_header"
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  key: 0,
  class: "base_main_header"
};
const _hoisted_4 = {
  key: 1,
  class: "base_main_margin_top"
};
import StatusBar from "@com/StatusBar.vue";
import DataCollectTable from "@com/table/DataCollectTable.vue";
import CancelOrder from "@com/order/CancelOrder.vue";
import { OP_KEY, COLUMN_TYPE, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import { $msg, $box } from "@ui/msg";
import { ref, reactive } from "vue";
import { Search } from "@element-plus/icons-vue";
import useRoute from "@util/router";
import $ from "@util/base";
import configApi from "@api/config";
import api from "@api/order";
export default {
  __name: 'HotelOrder',
  props: {
    type: {
      type: String
    },
    routeName: {
      type: String
    }
  },

  setup(__props) {
    const props = __props;
    const route = useRoute();
    route.watchRouter(props.routeName, () => {
      getData();
    }); // 1023001 未付款 1023002 处理中 1023003 已取消 1023004 待入住 1023005 已完成

    const statusList = reactive([{
      label: "全部",
      value: "all",
      total: 0
    }, {
      label: "处理中",
      value: "1023002",
      total: 0
    }, {
      label: "待入住",
      value: "1023004",
      total: 0
    }, {
      label: "已完成",
      value: "1023005",
      total: 0
    }, {
      label: "订单取消",
      value: "1023003",
      total: 0
    }]);
    const baseOptions = ref([]);
    const base = ref(route.query().base || "");
    const main = ref(route.query().main || "");
    const orderCode = ref(route.query().orderCode || "");
    const hotel = ref(route.query().hotel || "");
    const name = ref(route.query().name || "");
    const date = ref(route.query().date || []);
    const total = ref(0);
    const data = ref([]);
    getConfig();

    function getConfig() {
      configApi.base(val => {
        baseOptions.value = val;
      });
    }

    function hotelOperations(data) {
      if (props.type == "order") {
        // 1023001 未付款 1023002 处理中 1023003 已取消 1023004 待入住 1023005 已完成
        switch (data.orderStatus) {
          case "1023002":
            // 处理中
            return [OPERATIONS.check, OPERATIONS.cancelOrder, OPERATIONS.acceptOrder];

          case "1023001":
            // 未付款
            return [OPERATIONS.check, OPERATIONS.cancelOrder];

          case "1023004":
            //待入住
            return [OPERATIONS.check, OPERATIONS.acceptHotelOrder];

          default:
            return [OPERATIONS.check];
        }
      } else {
        return [OPERATIONS.check];
      }
    }

    const config = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: hotelOperations
      },
      column: [// {
      //   label: "主订单编号",
      //   prop: "mainOrderCode",
      //   width: 110,
      // },
      {
        label: "订单编号",
        prop: "orderCode",
        width: 110
      }, {
        label: "礼包码编号",
        prop: "exchangeItemCode",
        width: 120
      }, {
        label: "酒店名称",
        prop: "hotelName"
      }, {
        label: "下单时间",
        prop: "createTime",
        width: 100
      }, {
        label: "订单状态",
        prop: "orderStatusStr",
        width: 100
      }, {
        label: "入住人数",
        prop: "persionNum",
        width: 90
      }, {
        label: "归属基地",
        prop: "shopName",
        width: 100
      }, {
        label: "预订人信息",
        prop: "guestStr",
        width: 180,
        type: COLUMN_TYPE.tooltip,
        rows: 3
      }, {
        label: "分销员",
        prop: "agentMemberName",
        width: 90
      }, // {
      //   label: "联系方式",
      //   prop: "phoneNumber",
      //   width: 120,
      // },
      {
        label: "承接人",
        prop: "confirmBy",
        width: 90
      }, {
        label: "承接时间",
        prop: "confirmTime",
        width: 100
      }, {
        label: "核销人",
        prop: "verifierName",
        width: 90
      }, {
        label: "核销时间",
        prop: "verificateTime",
        width: 100
      }]
    };
    const cancelOrderRef = ref(null);
    let selectOpData = null;
    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      route.push({
        name: "hotelorderdetails",
        query: {
          code: data.orderCode
        }
      });
    });
    opHander.install(OP_KEY.acceptOrder, data => {
      $box.open("二次已确认", "是否二次已确认？", () => {
        api.confirm("hotel", data.orderCode, () => {
          $msg.succ("二次已确认成功");
          getData();
        }, msg => {
          $box.open("二次已确认失败", msg);
        });
      });
    });
    opHander.install(OP_KEY.cancelOrder, data => {
      selectOpData = data;
      cancelOrderRef.value.show();
    });
    opHander.install(OP_KEY.acceptHotelOrder, data => {
      $box.open("确认入住", "是否确认入住？", () => {
        api.hotelConfirm(data.orderCode, () => {
          $msg.succ("确认入住成功");
          getData();
        }, msg => {
          $box.open("确认入住失败", msg);
        });
      });
    });

    function cancelOrder(data) {
      if (selectOpData != null) {
        api.cancel({
          type: "hotel",
          code: selectOpData.orderCode,
          // amount: data.amount,
          remark: data.reason
        }, () => {
          $msg.succ("取消成功");
          getData();
        });
      } else {
        $msg.error("请重新选择记录操作");
      }
    }

    function ticket() {
      $box.input("核销", "核销码", null, val => {
        api.ticket("hotel", val, () => {
          $box.open("提示", "核销成功");
          getData();
        }, msg => {
          $box.open("核销失败", msg);
        });
      });
    }

    function searchBase(val) {
      route.replaceQuery("base", val);
    }

    function searchMain(val) {
      route.replaceQuery("main", val);
    }

    function searchOrderCode(val) {
      route.replaceQuery("orderCode", val);
    }

    function searchHotel(val) {
      route.replaceQuery("hotel", val);
    }

    function searchName(val) {
      route.replaceQuery("name", val);
    }

    function searchDate(val) {
      route.replaceQuery("date", val);
    }

    getData();

    function getData() {
      getNum();
      getList();
    }

    function getNum() {
      let routeQuery = route.query();
      let params = {
        memberLoginName: routeQuery.login,
        shopCode: routeQuery.base,
        mainOrderCode: routeQuery.main,
        orderCode: routeQuery.orderCode,
        hotelName: routeQuery.hotel,
        guestName: routeQuery.name,
        status: routeQuery.status
      };

      if ($.isNotEmpty(routeQuery.date)) {
        params.startTime = routeQuery.date[0];
        params.endTime = routeQuery.date[1];
      }

      api.num(params, "hotel", val => {
        statusData([val.total, val.unconfirmed, val.unused, val.completed, val.cancel]);
      });
    }

    function statusData(val) {
      statusList.forEach((e, i) => {
        e.total = val[i];
      });
    }

    function getList() {
      let routeQuery = route.query();
      let params = {
        type: "hotel",
        memberLoginName: routeQuery.login,
        shopCode: routeQuery.base,
        mainOrderCode: routeQuery.main,
        orderCode: routeQuery.orderCode,
        hotelName: routeQuery.hotel,
        guestName: routeQuery.name,
        status: routeQuery.status,
        date: routeQuery.date,
        page: routeQuery.p,
        size: routeQuery.s
      };
      api.orderList(params, val => {
        total.value = val.total; // val.list = [{orderStatus: "1023001"}, {orderStatus: "1023002"}, {orderStatus: "1023003"}, {orderStatus: "1023004"}, {orderStatus: "1023005"}]

        data.value = val.list.map(e => {
          // 1023001 未付款 1023002 处理中 1023003 已取消 1023004 待入住 1023005 已完成
          switch (e.orderStatus) {
            case "1023001":
              e.status = "未付款";
              break;

            case "1023002":
              e.status = "处理中";
              break;

            case "1023003":
              e.status = "已取消";
              break;

            case "1023004":
              e.status = "待入住";
              break;

            case "1023005":
              e.status = "已完成";
              break;
          }

          return e;
        });
      });
    }

    function getExport() {
      let routeQuery = route.query();
      let params = {
        type: "hotel",
        memberLoginName: routeQuery.login,
        shopCode: routeQuery.base,
        mainOrderCode: routeQuery.main,
        orderCode: routeQuery.orderCode,
        hotelName: routeQuery.hotel,
        guestName: routeQuery.name,
        status: routeQuery.status,
        date: routeQuery.date
      };
      api.export(params, val => {
        $.download(val.url, "酒店订单导出");
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_date_picker = _resolveComponent("el-date-picker");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form = _resolveComponent("el-form");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [props.type == 'order' ? _withDirectives((_openBlock(), _createBlock(_component_el_button, {
        key: 0,
        onClick: ticket,
        type: "primary"
      }, {
        default: _withCtx(() => [_createTextVNode("核销码核销")]),
        _: 1
      })), [[_directive_auth, _ctx.getCurCode('USED')]]) : (_openBlock(), _createElementBlock("div", _hoisted_2)), _createVNode(_component_el_form, {
        inline: true,
        class: "base_search_form",
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_date_picker, {
            modelValue: date.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => date.value = $event),
            type: "daterange",
            "range-separator": "~",
            "start-placeholder": "开始时间",
            "end-placeholder": "结束时间",
            "value-format": "YYYY-MM-DD",
            onChange: searchDate
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: base.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => base.value = $event),
            onChange: searchBase,
            placeholder: "请选择基地",
            clearable: ""
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(baseOptions.value, (item, index) => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: index,
                label: item.label,
                value: item.value
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: orderCode.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => orderCode.value = $event),
            "onKeyup[native]": searchOrderCode,
            onChange: searchOrderCode,
            placeholder: "订单编号",
            "suffix-icon": _unref(Search),
            clearable: ""
          }, null, 8, ["modelValue", "suffix-icon"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: hotel.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => hotel.value = $event),
            "onKeyup[native]": searchHotel,
            onChange: searchHotel,
            placeholder: "酒店名称",
            clearable: "",
            "suffix-icon": _unref(Search)
          }, null, 8, ["modelValue", "suffix-icon"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: name.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => name.value = $event),
            "onKeyup[native]": searchName,
            onChange: searchName,
            placeholder: "预定人",
            clearable: "",
            "suffix-icon": _unref(Search)
          }, null, 8, ["modelValue", "suffix-icon"])]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(StatusBar, {
        data: statusList,
        default: "all"
      }, null, 8, ["data"]), props.type == 'order' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
        type: "primary",
        text: "",
        onClick: getExport
      }, {
        default: _withCtx(() => [_createTextVNode("导出")]),
        _: 1
      })), [[_directive_auth, _ctx.getCurCode('EXPORT')]])])) : (_openBlock(), _createElementBlock("div", _hoisted_4)), _createVNode(DataCollectTable, {
        data: data.value,
        total: total.value,
        config: config,
        onOperationsHander: _unref(opHander).receive
      }, null, 8, ["data", "total", "onOperationsHander"]), _createVNode(CancelOrder, {
        ref_key: "cancelOrderRef",
        ref: cancelOrderRef,
        onCancelOrder: cancelOrder
      }, null, 512)]);
    };
  }

};