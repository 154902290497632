import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "base_screen_header"
};
const _hoisted_2 = {
  class: "base_main_header"
};
import StatusBar from '@com/StatusBar.vue';
import GiftTable from "@com/marketing/GiftTable.vue";
import { ref, reactive } from 'vue';
import api from "@api/gift";
import { Search } from '@element-plus/icons-vue';
import $ from "@util/base";
import useRoute from "@util/router";
export default {
  __name: 'GiftManager',

  setup(__props) {
    const route = useRoute();
    const name = ref(route.query().name || '');
    const statusList = reactive([{
      label: '全部',
      value: 'all',
      total: 0
    }, {
      label: '已上架',
      value: '1004003',
      total: 0
    }, {
      label: '未上架',
      value: '1004002',
      total: 0
    }]);
    getNum();

    function getNum() {
      api.num(val => {
        numData(val);
      });
    }

    function numData(val) {
      statusList.forEach((e, i) => {
        e.total = val[i];
      });
    }

    function searchName(val) {
      route.replaceQuery('name', val);
    }

    function add() {
      route.push({
        name: 'giftdetails',
        query: {
          type: 'add'
        }
      });
    }

    function giftExport() {
      let routeQuery = route.query();
      api.export({
        name: routeQuery.name,
        status: routeQuery.status
      }, val => {
        $.download(val.url, "礼包导出");
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_main = _resolveComponent("el-main");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createBlock(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          onClick: add
        }, {
          default: _withCtx(() => [_createTextVNode("新增礼包")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('ADD')]]), _createVNode(_component_el_form, {
          inline: true,
          class: "base_search_form",
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: name.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => name.value = $event),
              "onKeyup[native]": searchName,
              onChange: searchName,
              placeholder: "礼包名称",
              clearable: "",
              "suffix-icon": _unref(Search)
            }, null, 8, ["modelValue", "suffix-icon"])]),
            _: 1
          })]),
          _: 1
        })]), _createVNode(StatusBar, {
          data: statusList,
          default: "all"
        }, null, 8, ["data"]), _createElementVNode("div", _hoisted_2, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          text: "",
          onClick: giftExport
        }, {
          default: _withCtx(() => [_createTextVNode("导出")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('EXPORT')]])]), _createVNode(GiftTable, {
          route: "giftmanager",
          onGetNum: getNum
        })]),
        _: 1
      });
    };
  }

};