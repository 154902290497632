import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { watch, ref } from "vue";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { GridComponent } from "echarts/components";
import VChart from "vue-echarts";
export default {
  __name: 'BarChart',
  props: {
    chartData: Array
  },

  setup(__props) {
    use([CanvasRenderer, BarChart, GridComponent]);
    const props = __props;
    const option = ref({});
    watch(() => props.chartData, val => {
      init();
    });
    init();

    function init() {
      option.value = getOption();
    }

    function getOption() {
      let xData = [];
      let yData = [];
      props.chartData.forEach(item => {
        xData.push(item.theDate);
        yData.push(item.num);
      });
      return {
        color: ["#409eff"],
        grid: {
          bottom: 50,
          right: 50,
          top: '5%',
          left: 40
        },
        xAxis: {
          type: "category",
          data: xData
        },
        yAxis: {
          type: "value"
        },
        series: [{
          label: {
            show: true,
            position: "top"
          },
          data: yData,
          type: "bar"
        }]
      };
    }

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_unref(VChart), {
        class: "chart",
        option: option.value
      }, null, 8, ["option"])]);
    };
  }

};