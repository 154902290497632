import { ElMessage } from "element-plus";
import "element-plus/es/components/message/style/css";

const message = {
  succ: function (msg) {
    ElMessage({
      message: msg,
      type: "success",
    });
  },
  error: function (msg) {
    ElMessage({
      message: msg,
      type: "error",
    });
  }
}
export default message