import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import DataCollectTable from "../table/DataCollectTable.vue";
import { OP_KEY, COLUMN_TYPE, OPERATIONS, operationsHander } from "../table/tableOperations";
import AppletsCode from "@com/AppletsCode.vue"; // import data from "@/test/goodsData.json"

import useRoute from "@util/router";
import { ref } from "vue";
import api from "@api/hotel";
import examineApi from "@api/examine";
import { $msg, $box } from "@/common/ui/msg";
export default {
  __name: 'HotelTable',
  props: {
    type: {
      type: String
    },
    route: {
      type: String
    },
    isIntegral: {
      type: Boolean,
      default: false
    }
  },
  emits: ['statusData'],

  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const props = __props;
    const data = ref([]);
    const total = ref(0);
    const route = useRoute();
    route.watchRouter(props.route, () => {
      getData();
    });

    function getOperations(data) {
      if (props.type == 'input') {
        switch (data.flowStatusCode) {
          case "1007001":
            //草稿
            return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.del, OPERATIONS.examine];

          case "1007003":
            //被驳回
            return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.del, OPERATIONS.reject];

          case "1007004":
            //审批通过
            return [OPERATIONS.check];

          default:
            return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.del];
        }
      } else {
        return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.del, OPERATIONS.appletsCode];
      }
    }

    const config = {
      showIndex: true,
      operations: {
        label: '操作',
        width: 120,
        operations: getOperations
      },
      column: [{
        label: '排序',
        prop: 'sortNum',
        type: COLUMN_TYPE.input,
        operationsKey: OPERATIONS.input,
        width: 100
      }, {
        label: '酒店图片',
        prop: 'mainPic',
        type: COLUMN_TYPE.picture,
        width: 100
      }, {
        label: '酒店名称',
        prop: 'hotelName'
      }, {
        label: '产品编号',
        prop: 'hotelCode',
        width: 100
      }, {
        label: '上架状态',
        prop: 'hotelStatusName',
        type: COLUMN_TYPE.switch,
        operationsKey: OPERATIONS.switch,
        activeText: '已上架',
        inactiveText: '未上架',
        activeValue: '已上架',
        inactiveValue: '未上架',
        switchWidth: 65,
        width: 100
      }, // {
      //   label: '库存',
      //   prop: 'totalStock',
      //   width: 80
      // },
      {
        label: '归属基地',
        prop: 'shopName',
        width: 100
      }, {
        label: '更新时间',
        prop: 'updateTime',
        width: 100
      }, {
        label: '更新人',
        prop: 'updateBy',
        width: 100
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 100
      }, {
        label: '创建人',
        prop: 'creator',
        width: 100
      }]
    };

    if (props.type == 'input') {
      config.column.shift();
      config.column[3] = {
        label: '审核状态',
        prop: 'flowStatus',
        width: 100
      };
    }

    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      if (props.isIntegral) {
        route.push({
          name: "integralHotelDetails",
          query: {
            type: "show",
            code: data.id
          }
        });
      } else {
        route.push({
          name: "hoteldetails",
          query: {
            type: "show",
            code: data.id
          }
        });
      }
    });
    opHander.install(OP_KEY.modify, data => {
      if (props.isIntegral) {
        route.push({
          name: "integralHotelDetails",
          query: {
            type: "modify",
            code: data.id
          }
        });
      } else {
        route.push({
          name: "hoteldetails",
          query: {
            type: "modify",
            code: data.id
          }
        });
      }
    });
    opHander.install(OP_KEY.delete, data => {
      $box.open("删除", "确认删除" + data.hotelName, () => {
        api.del(data.id, () => {
          $msg.succ("删除成功");
          getData();
        });
      });
    });
    opHander.install(OP_KEY.examine, data => {
      examineApi.examine("hotel", data.hotelCode, data.shopCode, () => {
        $msg.succ("提交成功");
        getData();
      });
    });
    opHander.install(OP_KEY.reject, data => {
      $box.open("驳回原因", data.rejected);
    });
    opHander.install(OP_KEY.switch, data => {
      // 1004003已上架 1004002未上架
      let state = data.data.hotelStatus;
      api.up(data.data.id, state, () => {
        data.resolve(true);

        if (state == '1004002') {
          $msg.succ("上架成功");
        } else {
          $msg.succ("下架成功");
        }

        getData();
      }, () => {
        data.reject();
      });
    });
    opHander.install(OP_KEY.input, data => {
      api.sort(data.data.id, data.value, () => {
        $msg.succ("设置排序成功");
      });
    });
    const appletsCodeRef = ref(null);
    opHander.install(OP_KEY.appletsCode, data => {
      appletsCodeRef.value.getUnlimited({
        code: data.hotelCode,
        type: "hotel"
      });
    });
    getData();

    function getData() {
      if (props.type == 'input') {
        getInputData();
      } else {
        getManagerData();
      }
    }

    function getManagerData() {
      let routeQuery = route.query();
      api.manager({
        categoryCode: routeQuery.c,
        shopCode: routeQuery.base,
        name: routeQuery.name,
        status: routeQuery.status,
        page: routeQuery.p,
        size: routeQuery.s,
        isIntegral: props.isIntegral
      }, val => {
        total.value = val.total;
        data.value = val.itemList;
        emit('statusData', [val.allNum, val.leadUpNum, val.leadDownNum]);
      });
    }

    function getInputData() {
      let routeQuery = route.query();
      api.input({
        categoryCode: routeQuery.c,
        shopCode: routeQuery.base,
        name: routeQuery.name,
        status: routeQuery.status,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        total.value = val.total;
        data.value = val.itemList;
        emit('statusData', [val.allNum, val.draftNum, val.notApprovalNum, val.isPassNum, val.rejectedNum]);
      });
    }

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", null, [_createVNode(DataCollectTable, {
        ref: "hotelTableRef",
        data: data.value,
        config: config,
        onOperationsHander: _unref(opHander).receive,
        total: total.value
      }, null, 8, ["data", "onOperationsHander", "total"]), _createVNode(AppletsCode, {
        ref_key: "appletsCodeRef",
        ref: appletsCodeRef
      }, null, 512)]);
    };
  }

};