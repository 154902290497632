import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
import { ref, reactive } from "vue";
export default {
  __name: 'OrderShipments',
  emits: ["shipments"],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const emit = __emit;
    const dialogVisible = ref(false);

    function show() {
      dialogVisible.value = true;
    }

    __expose({
      show
    });

    const formRef = ref(null);
    const form = reactive({
      number: null,
      company: null,
      express: null
    });
    const rules = {
      express: [{
        required: true,
        message: "请输入快递信息",
        trigger: "blur"
      }],
      number: [{
        required: true,
        message: "请输入快递编号",
        trigger: "blur"
      }],
      company: [{
        required: true,
        message: "请输入快递公司",
        trigger: "blur"
      }]
    };

    const submitForm = formEl => {
      if (!formEl) return;
      formEl.validate((valid, fields) => {
        if (valid) {
          emit("shipments", form);
          dialogVisible.value = false;
        }
      });
    };

    const resetForm = formEl => {
      if (!formEl) return;
      formEl.resetFields();
    };

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => dialogVisible.value = $event),
        title: "发货",
        width: "35%",
        top: "30vh",
        class: "base_dialog",
        onOpen: _cache[4] || (_cache[4] = $event => resetForm(formRef.value))
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[1] || (_cache[1] = $event => submitForm(formRef.value))
        }, {
          default: _withCtx(() => [_createTextVNode("确定")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: _cache[2] || (_cache[2] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "formRef",
          ref: formRef,
          model: form,
          rules: rules,
          "label-width": "80px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "发货信息",
            prop: "express"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: form.express,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.express = $event),
              type: "textarea",
              rows: 5,
              resize: "none"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};