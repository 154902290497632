import { POST } from "@net/service.js";
import configApi from "./config"
import $ from "@util/base";

const URL = {
  realTime: "overview_realtime",
  rankTop: "overview_rank_top",
  rankList: "overview_rank_list"
};

const api = {
  base(succ) {
    configApi.base(succ)
  },
  realTime(params, succ) {
    let paramsData = {}
    if ($.isNotEmpty(params.shopCode) && params.shopCode != 'all') {
      paramsData.shopCode = params.shopCode
    }
    let requestOptions = {
        path: URL.realTime,
        params: paramsData,
        isLoading: true,
        succ: succ
    }
    POST(requestOptions)
  },
  rankTop(params, succ) {
    let paramsData = {}
    if ($.isNotEmpty(params.shopCode) && params.shopCode != 'all') {
      paramsData.shopCode = params.shopCode
    }
    if ($.isNotEmpty(params.dateType)) {
      if (typeof params.dateType == 'string') {
        paramsData.dateType = parseInt(params.dateType)
      } else {
        paramsData.dateType = -1
        paramsData.startTime = params.dateType[0]
        paramsData.endTime = params.dateType[1]
      }
    } else {
      paramsData.dateType = 0
    }
    let requestOptions = {
        path: URL.rankTop,
        params: paramsData,
        isLoading: true,
        succ: succ
    }
    POST(requestOptions)
  },
  rankList(params, succ) {
    let paramsData = {}
    if ($.isNotEmpty(params.shopCode) && params.shopCode != 'all') {
      paramsData.shopCode = params.shopCode
    }
    if ($.isNotEmpty(params.dateType)) {
      if (typeof params.dateType == 'string') {
        paramsData.dateType = parseInt(params.dateType)
      } else {
        paramsData.dateType = -1
        paramsData.startTime = params.dateType[0]
        paramsData.endTime = params.dateType[1]
      }
    } else {
      paramsData.dateType = 0
    }
    paramsData.which = parseInt(params.which)
    let requestOptions = {
        path: URL.rankList,
        params: paramsData,
        isLoading: true,
        succ: succ
    }
    POST(requestOptions)
  },
}

export default api