import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref } from "vue";
const _hoisted_1 = {
  class: "group_remain_manager"
};
const _hoisted_2 = {
  class: "base_screen_header"
};
const _hoisted_3 = {
  class: "base_main_margin_top"
};
import BackTitleView from "@com/BackTitleView.vue";
import DataCollectTable from '@com/table/DataCollectTable.vue';
import { OP_KEY, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import { ref, reactive } from 'vue';
import { Search } from '@element-plus/icons-vue';
import useRoute from "@util/router";
import api from "@api/groupRemain";
import $ from "@util/base";
import { $msg } from '@/common/ui/msg';
export default {
  __name: 'TravelStockDetails',

  setup(__props) {
    const route = useRoute();
    const baseOptions = ref([]);
    getConfig();

    function getConfig() {
      api.base(val => {
        baseOptions.value = val;
      });
    }

    const total = ref(0);
    const data = ref([]);
    const base = ref(route.query().base || '');

    function searchBase(val) {
      route.replaceQuery('base', val);
    }

    function getOperations(item) {
      var operation = [];

      if ($.isNotEmpty(item.groupCode)) {
        operation = [OPERATIONS.registration, OPERATIONS.occupy, OPERATIONS.export]; //关闭状态 1002001是 1002002否

        if (item.closeStatus == "1002001") {
          operation.push(OPERATIONS.openGroup);
        } else {
          operation.push(OPERATIONS.closeGroup);
        }

        return operation;
      } else {
        //关闭状态 1002001是 1002002否
        if (item.closeStatus == "1002001") {
          operation.push(OPERATIONS.openGroup);
        } else {
          operation.push(OPERATIONS.closeGroup);
        }

        return operation;
      }
    }

    const tableConfig = {
      showIndex: true,
      operations: null,
      column: [{
        label: '发团日期',
        prop: 'startGroupTime'
      }, {
        label: '套餐名称+团号',
        prop: 'nameAndCode'
      }, {
        label: '最大收客',
        prop: 'maxHoldVisitor',
        width: 100
      }, {
        label: '未签/已签合同',
        prop: 'whetherSign',
        width: 150
      }, {
        label: '未收款/已收款',
        prop: 'whetherReceived',
        width: 150
      }, {
        label: '占位/已确认',
        prop: 'whetherHolder',
        width: 150
      }, {
        label: '余位',
        prop: 'surplusSeat',
        width: 100
      }, {
        label: '已成团/未成团',
        prop: 'isGroup',
        width: 150
      }]
    };
    const opHander = operationsHander();
    opHander.install(OP_KEY.registration, data => {
      route.push({
        name: 'registrationlist',
        query: {
          code: data.groupCode
        }
      });
    });
    opHander.install(OP_KEY.occupy, data => {
      route.push({
        name: 'occupymanager',
        query: {
          code: data.groupCode,
          package: data.packageCode
        }
      });
    });
    opHander.install(OP_KEY.export, data => {
      api.registrationExport2(data.groupCode, val => {
        $.download(val.url, "报名情况导出");
      });
    });
    opHander.install(OP_KEY.closeGroup, data => {
      console.log(productData.value[0]);
      api.groupRemainClose(data.startGroupTime, data.packageCode, productData.value[0].travelCode, true, () => {
        $msg.succ("关闭成功");
        getData();
      });
    });
    opHander.install(OP_KEY.openGroup, data => {
      api.groupRemainClose(data.startGroupTime, data.packageCode, productData.value[0].travelCode, false, () => {
        $msg.succ("打开成功");
        getData();
      });
    });
    const productData = ref([]);
    const productColumn = [{
      label: '产品名称',
      prop: 'travelName'
    }, {
      label: '产品编号',
      prop: 'travelCode',
      width: 150
    }, {
      label: '现有团期',
      prop: 'nowGroupNum',
      width: 150
    }, {
      label: '发团日期',
      prop: 'startGroupTime',
      width: 150
    }, {
      label: '结束日期',
      prop: 'endGroupTime',
      width: 150
    }, {
      label: '基地所属',
      prop: 'shopName',
      width: 150
    }];
    getData();
    route.watchRouter("travelstockdetails", () => {
      getData();
    });

    function getData() {
      let routeQuery = route.query();
      api.orderList({
        shopCode: routeQuery.base,
        code: routeQuery.code,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        data.value = val.itemList.map(e => {
          // 套餐名称+团号
          e.nameAndCode = `${e.packageName ? e.packageName : '-'}/${e.groupCode}`; // 未签/已签合同

          e.whetherSign = `${e.noSign}/${e.isSign}`; // 未收款/已收款

          e.whetherReceived = `${e.uncollected}/${e.received}`; // 占位/已确认

          e.whetherHolder = `${e.holder}/${e.isHolder}`;
          return e;
        });
        total.value = val.total;
        productData.value = [val.productItem];
      });
    }

    function getExport() {
      let routeQuery = route.query();
      api.groupRemainExport({
        shopCode: routeQuery.base,
        code: routeQuery.code,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        $.download(val.url, "团期列表");
      });
    }

    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_table = _resolveComponent("el-table");

      const _component_el_main = _resolveComponent("el-main");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(BackTitleView, {
          title: "团期列表"
        })]), _createVNode(_component_el_table, {
          data: productData.value,
          border: ""
        }, {
          default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(productColumn, (item, index) => {
            return _createVNode(_component_el_table_column, {
              key: index,
              label: item.label,
              prop: item.prop,
              width: item.width,
              align: "center"
            }, null, 8, ["label", "prop", "width"]);
          }), 64))]),
          _: 1
        }, 8, ["data"]), _createElementVNode("div", _hoisted_3, [_createVNode(DataCollectTable, {
          data: data.value,
          total: total.value,
          config: tableConfig,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"])])]),
        _: 1
      })]);
    };
  }

};