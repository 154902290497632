import {
    POST
} from '@net/service.js'
import $ from "@util/base";
import page from "./page";

const URL = {
    getCategory: 'category_list',
    addCategory: 'category_add',
    delCategory: 'category_delete',
    updateCategory: 'category_update',
    getList: 'category_list_page'
}

const TYPE = {
    goods: '1003001',
    travel: '1003002',
    hotel: '1003003'
}

const api = {
    getList(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.type)) {
            paramsData.busiType = params.type;
        }
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.getList,
            params: paramsData,
            succ: succ
        }
        POST(requestOptions)
    },
    getCategory(type, succ) {
        let params = {
            busiType: type
        }
        let requestOptions = {
            path: URL.getCategory,
            params: params,
            succ: succ
        }
        POST(requestOptions)
    },
    addCategory(name,sort, type, succ) {
        let params = {
            busiType: type,
            catName: name,
            catLevel: 1,
            sort:sort
        }
        let requestOptions = {
            path: URL.addCategory,
            params: params,
            succ: succ
        }
        POST(requestOptions)
    },
    delCategory(id, succ) {
        let params = {
            id: id,
        }
        let requestOptions = {
            path: URL.delCategory,
            params: params,
            succ: succ
        }
        POST(requestOptions)
    },
    updateCategory(id, name,sort, succ) {
        let params = {
            id: id,
            catName: name,
            sort:sort
        }
        let requestOptions = {
            path: URL.updateCategory,
            params: params,
            succ: succ
        }
        POST(requestOptions)
    }
}

export {
    api,
    TYPE,
}