import { POST } from "@net/service.js";
import $ from "@util/base";
import page from "./page";
import examineApi from "./examine";

const URL = {
    num: "integral_balance_num",
    statusNum: "member_integral_status_num",
    list: "member_integral_list",
    details: "integral_get",
    delete: "member_integral_del",
    add: "member_integral_add",
    downloadTemplate: "download_Integral_change",
    importDeposit: "integral_list_import",
    moneyList: "member_money_list",
    exportData: "member_integral_list_export",
    delRefuseData: "integral_one_touch_del"
};

const api = {
    num(succ) {
        let requestOptions = {
            path: URL.num,
            succ: (val) => {
                succ([val.totalNum, val.usedNum]);
            },
        };
        POST(requestOptions);
    },
    statusNum(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.name)) {
            paramsData.memberName = params.name;
        }
        if ($.isNotEmpty(params.phone)) {
            paramsData.phone = params.phone;
        }
        if ($.isNotEmpty(params.changeReason)) {
            paramsData.changeReason = params.changeReason;
        }
        if ($.isNotEmpty(params.remark)) {
            paramsData.remark = params.remark;
        }
        if ($.isNotEmpty(params.date)) {
            paramsData.startTime = params.date[0];
            paramsData.endTime = params.date[1];
        }
        let requestOptions = {
            path: URL.statusNum,
            params: paramsData,
            succ: (val) => {
                succ([val.total, val.pending, val.accepted, val.rejected]);
            },
        };
        POST(requestOptions);
    },
    list(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.depositType)) {
            paramsData.depositType = params.depositType;
        }
        if ($.isNotEmpty(params.phone)) {
            paramsData.phone = params.phone;
        }
        if ($.isNotEmpty(params.changeReason)) {
            paramsData.changeReason = params.changeReason;
        }
        if ($.isNotEmpty(params.remark)) {
            paramsData.remark = params.remark;
        }
        if ($.isNotEmpty(params.date)) {
            paramsData.startTime = params.date[0];
            paramsData.endTime = params.date[1];
        }
        if ($.isNotEmpty(params.status) && params.status != "all") {
            paramsData.validStatus = params.status;
        }
        if ($.isNotEmpty(params.memberCode)) {
            paramsData.memberCode = params.memberCode;
        }
        if ($.isNotEmpty(params.name)) {
            paramsData.memberName = params.name;
        }
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.list,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    exportData(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.depositType)) {
            paramsData.depositType = params.depositType;
        }
        if ($.isNotEmpty(params.phone)) {
            paramsData.phone = params.phone;
        }
        if ($.isNotEmpty(params.changeReason)) {
            paramsData.changeReason = params.changeReason;
        }
        if ($.isNotEmpty(params.remark)) {
            paramsData.remark = params.remark;
        }
        if ($.isNotEmpty(params.date)) {
            paramsData.startTime = params.date[0];
            paramsData.endTime = params.date[1];
        }
        if ($.isNotEmpty(params.status) && params.status != "all") {
            paramsData.validStatus = params.status;
        }
        if ($.isNotEmpty(params.memberCode)) {
            paramsData.memberCode = params.memberCode;
        }
        if ($.isNotEmpty(params.name)) {
            paramsData.memberName = params.name;
        }
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.exportData,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    details(code, batch, page, pageTotal, succ) {
        let paramsData = {};
        if ($.isNotEmpty(code)) {
            paramsData.id = code;
        }
        if ($.isNotEmpty(batch)) {
            paramsData.batchCode = batch;
        }
        if ($.isNotEmpty(page)) {
            paramsData.page = parseInt(page);
        } else {
            paramsData.page = 1
        }
        if ($.isNotEmpty(pageTotal)) {
            paramsData.pageTotal = parseInt(pageTotal);
        }
        let requestOptions = {
            path: URL.details,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    delete(code, succ) {
        let requestOptions = {
            path: URL.delete,
            params: { id: code },
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    add(params, succ) {
        let requestOptions = {
            path: URL.add,
            params: params,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    downloadTemplate(succ) {
        let requestOptions = {
            path: URL.downloadTemplate,
            succ: succ,
        };
        POST(requestOptions);
    },
    importDeposit(file, succ, fail) {
        let requestOptions = {
            path: URL.importDeposit,
            params: { fileUrl: file },
            isToast: false,
            succ: succ,
            fail: fail
        };
        POST(requestOptions);
    },
    approval(code, status, remark, succ) {
        examineApi.approval(code, status, remark, succ);
    },
    moneyList(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.type)) {
            paramsData.type = params.type;
        }
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.moneyList,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    delRefuseData(succ) {
        let requestOptions = {
            path: URL.delRefuseData,
            succ: succ,
        };
        POST(requestOptions);
    },
};

export default api;