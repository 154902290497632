import { openBlock as _openBlock, createBlock as _createBlock } from "vue";
import SingleFieldManager from "@com/SingleFieldManager.vue";
export default {
  __name: 'GroupManager',

  setup(__props) {
    const config = {
      name: '产品内容',
      type: 'group',
      route: 'groupmanager',
      extraButton: 'foreEndFilter'
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(SingleFieldManager, {
        config: config
      });
    };
  }

};