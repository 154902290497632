import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref, watch, nextTick } from "vue";
import HotelStockPrice from "./roomPrice/HotelStockPrice.vue";
import DynamicParams from "@com/DynamicParams.vue";
import $ from "@util/base";
import { $msg } from "@ui/msg";
export default {
  __name: 'StockRoomConfigure',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },

  setup(__props, {
    expose: __expose
  }) {
    const props = __props;
    const data = ref([]);
    const roomParams = ref(null);
    const hotelPriceRef = ref(null);
    const drawer = ref(false);
    watch(() => props.data, val => {
      data.value = val;
    });
    watch(() => drawer.value, () => {
      if (drawer.value == false) {
        hotelPriceRef.value.reset();
      }
    });

    function operation() {
      return {
        copy: false,
        move: false,
        modify: false,
        del: false,
        add: false,
        check: true
      };
    }

    function open() {
      drawer.value = true;
    }

    function close() {
      drawer.value = false;
    }

    function save() {
      hotelPriceRef.value.save(res => {
        if (res != false) {
          console.log(res);

          if (modifyIndex != null) {
            roomParams.value.modifyData(modifyIndex, res);
          } else {
            roomParams.value.addData(res);
          }

          close();
        }
      });
    }

    var modifyIndex = null;

    function showDetails(index) {
      modifyIndex = null;
      open();
      nextTick(() => {
        hotelPriceRef.value.improtData(roomParams.value.getValue()[index]);
      });
    }

    function modify(index) {
      modifyIndex = index;
      open();
      nextTick(() => {
        hotelPriceRef.value.improtData(roomParams.value.getValue()[index]);
      });
    }

    function getValue() {
      let value = roomParams.value.getValue();

      if (value.length <= 0) {
        $msg.error("请添加房型");
        return false;
      }

      return value;
    }

    __expose({
      getValue
    });

    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_drawer = _resolveComponent("el-drawer");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(DynamicParams, {
        ref_key: "roomParams",
        ref: roomParams,
        data: data.value,
        onCheck: showDetails,
        operation: operation(),
        onModify: modify
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "房型名称",
          prop: "roomName",
          align: "center"
        }), _createVNode(_component_el_table_column, {
          label: "可住人数",
          prop: "peopleNum",
          align: "center"
        })]),
        _: 1
      }, 8, ["data", "operation"]), _createVNode(_component_el_drawer, {
        "custom-class": "base_drawer add_room_layout",
        modelValue: drawer.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => drawer.value = $event),
        "close-on-click-modal": false,
        title: "设置库存",
        "with-header": true,
        size: 1000
      }, {
        footer: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_el_button, {
          onClick: _cache[0] || (_cache[0] = $event => drawer.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(HotelStockPrice, {
          ref_key: "hotelPriceRef",
          ref: hotelPriceRef
        }, null, 512)]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }

};