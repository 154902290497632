import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue";
import UploadPicture from "@com/upload/UploadPicture.vue";
import { ref, reactive, watch } from "vue";
import $ from "@util/base";
import { $msg } from "@ui/msg";
export default {
  __name: 'GiftBasicForm',
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    }
  },

  setup(__props, {
    expose: __expose
  }) {
    const props = __props;
    watch(() => props.data, val => {
      setData(val);
    });

    function setData(val) {
      basicForm.name = val.name;
      basicForm.price = val.price;
      basicForm.mainPic = [val.mainPic];
      basicForm.homePic = [val.homePic];
      basicForm.descPicList = val.descPicList;

      if (unitOptions.includes(val.sellUnit)) {
        basicForm.sellUnit = val.sellUnit;
      } else {
        basicForm.sellUnit = "其他";
        otherUnit.value = val.sellUnit;
      }

      basicForm.validTime = val.validTime;
      basicForm.payTypeList = val.payTypeList;
    }

    const otherUnit = ref();
    const unitOptions = ['个', '份', '箱', '其他'];
    const basicFormRef = ref(null);
    const basicForm = reactive({
      name: null,
      price: null,
      mainPic: [],
      homePic: [],
      descPicList: [],
      sellUnit: null,
      validTime: null,
      payType: '',
      payTypeList: []
    });
    const basicRules = {
      name: [{
        required: true,
        message: '请输入礼包名称',
        trigger: 'blur'
      }],
      price: [{
        required: true,
        message: '请输入礼包价（元）',
        trigger: 'blur'
      }, {
        type: "number",
        message: "请输入数字"
      }],
      mainPic: [{
        required: true,
        type: 'array',
        message: '请上传礼包封面',
        trigger: 'change'
      }],
      homePic: [{
        required: true,
        type: 'array',
        message: '请上传首页推荐图片',
        trigger: 'change'
      }],
      descPicList: [{
        required: true,
        type: 'array',
        message: '请上传礼包简介',
        trigger: 'change'
      }],
      sellUnit: [{
        required: true,
        message: '请选择礼包单位',
        trigger: 'change'
      }],
      validTime: [{
        required: true,
        message: '请选择失效时间',
        trigger: 'change'
      }],
      payTypeList: [{
        required: true,
        message: '请选择支付方式',
        trigger: 'change'
      }]
    };

    function check() {
      return basicFormRef.value.validate(valid => {
        if (valid && checkUnit()) {
          return true;
        }

        return false;
      });
    }

    function checkUnit() {
      if (basicForm.sellUnit == "其他" && $.isEmpty(otherUnit.value)) {
        $msg.error("请输入单位");
        return false;
      }

      return true;
    }

    function getUnit() {
      if (basicForm.sellUnit != "其他") {
        return basicForm.sellUnit;
      } else {
        return otherUnit.value;
      }
    }

    function getValue() {
      let data = $.deepCopy(basicForm);
      data.mainPic = data.mainPic[0];
      data.homePic = data.homePic[0];
      data.sellUnit = getUnit();
      return data;
    }

    __expose({
      check,
      getValue
    });

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_input_number = _resolveComponent("el-input-number");

      const _component_el_radio = _resolveComponent("el-radio");

      const _component_el_radio_group = _resolveComponent("el-radio-group");

      const _component_el_date_picker = _resolveComponent("el-date-picker");

      const _component_el_checkbox = _resolveComponent("el-checkbox");

      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");

      const _component_el_form = _resolveComponent("el-form");

      return _openBlock(), _createBlock(_component_el_form, {
        ref_key: "basicFormRef",
        ref: basicFormRef,
        model: basicForm,
        disabled: __props.readOnly,
        rules: basicRules,
        "label-width": "120px",
        class: "base_details_form"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "礼包名称",
          prop: "name"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: basicForm.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => basicForm.name = $event),
            placeholder: "请输入"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "礼包价（元）",
          prop: "price"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input_number, {
            modelValue: basicForm.price,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => basicForm.price = $event),
            modelModifiers: {
              number: true
            },
            min: 0,
            precision: 2,
            disabled: __props.readOnly,
            controls: false,
            "controls-position": "right"
          }, null, 8, ["modelValue", "disabled"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "礼包封面",
          prop: "mainPic"
        }, {
          default: _withCtx(() => [_createVNode(UploadPicture, {
            modelValue: basicForm.mainPic,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => basicForm.mainPic = $event),
            limit: 1,
            imgSize: _unref($).describeImgSize(),
            fileSize: _unref($).imgFileSize(),
            disabled: __props.readOnly
          }, null, 8, ["modelValue", "imgSize", "fileSize", "disabled"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "首页推荐图片",
          prop: "homePic"
        }, {
          default: _withCtx(() => [_createVNode(UploadPicture, {
            modelValue: basicForm.homePic,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => basicForm.homePic = $event),
            limit: 1,
            imgSize: _unref($).homePackageSize(),
            fileSize: _unref($).imgFileSize(),
            disabled: __props.readOnly
          }, null, 8, ["modelValue", "imgSize", "fileSize", "disabled"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "礼包简介",
          prop: "descPicList"
        }, {
          default: _withCtx(() => [_createVNode(UploadPicture, {
            modelValue: basicForm.descPicList,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => basicForm.descPicList = $event),
            limit: 50,
            fileSize: _unref($).imgFileSize(),
            disabled: __props.readOnly
          }, null, 8, ["modelValue", "fileSize", "disabled"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "礼包单位",
          prop: "sellUnit"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_radio_group, {
            modelValue: basicForm.sellUnit,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => basicForm.sellUnit = $event)
          }, {
            default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(unitOptions, (item, index) => {
              return _createVNode(_component_el_radio, {
                key: index,
                label: item
              }, null, 8, ["label"]);
            }), 64))]),
            _: 1
          }, 8, ["modelValue"]), _createVNode(_component_el_input, {
            class: "unit_input",
            modelValue: otherUnit.value,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => otherUnit.value = $event),
            placeholder: "单位"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "失效时间",
          prop: "validTime"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_date_picker, {
            modelValue: basicForm.validTime,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => basicForm.validTime = $event),
            type: "date",
            "value-format": "YYYY-MM-DD"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "支付方式",
          prop: "payTypeList"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_checkbox_group, {
            modelValue: basicForm.payTypeList,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => basicForm.payTypeList = $event)
          }, {
            default: _withCtx(() => [_createVNode(_component_el_checkbox, {
              label: "1021001"
            }, {
              default: _withCtx(() => [_createTextVNode("微信支付")]),
              _: 1
            }), _createVNode(_component_el_checkbox, {
              label: "1021002"
            }, {
              default: _withCtx(() => [_createTextVNode("储值卡支付")]),
              _: 1
            })]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["model", "disabled"]);
    };
  }

};