import { POST } from "@net/service.js";
import $ from "@util/base";
import configApi, {TYPE as labetType} from "./config";
import examineApi from "./examine";
import { TYPE, api as categoryApi } from "./category";
import page from "./page";

const URL = {
    input: "hotel_add_list",
    manager: "hotel_list",
    facility: "choose_hotel_facility_config_list",
    catering: "choose_hotel_catering_config_list",
    add: "hotel_add",
    details: "hotel_get",
    update: "hotel_update",
    updateState: "hotel_update_way",
    roomList: "hotel_room_list",
    roomConfigure: "choose_hotel_room_catering_config_list",
    inputExport: "hotel_add_list_export",
    managerExport: "",
};

const api = {
    input(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.categoryCode) && params.categoryCode != "all") {
            paramsData.categoryCode = params.categoryCode;
        }
        if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
            paramsData.shopCode = params.shopCode;
        }
        if ($.isNotEmpty(params.name)) {
            paramsData.hotelName = params.name;
        }
        paramsData.flowStatusCode = params.status ? parseInt(params.status) : 1;
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.input,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    manager(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.categoryCode) && params.categoryCode != "all") {
            paramsData.categoryCode = params.categoryCode;
        }
        if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
            paramsData.shopCode = params.shopCode;
        }
        if ($.isNotEmpty(params.name)) {
            paramsData.productName = params.name;
        }
        if (params.isIntegral) {
            paramsData.belongMall = 1066002
        } else {
            paramsData.belongMall = 1066001
        }
        paramsData.productStatus = params.status ? parseInt(params.status) : 1;
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.manager,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    roomList(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.name)) {
            paramsData.hotelName = params.name;
        }
        if ($.isNotEmpty(params.roomName)) {
            paramsData.hotelRoomName = params.roomName;
        }
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.roomList,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    add(params, succ) {
        let requestOptions = {
            path: URL.add,
            params: params,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    update(params, succ) {
        let requestOptions = {
            path: URL.update,
            params: params,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    details(id, succ) {
        let requestOptions = {
            path: URL.details,
            params: { id: id },
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    sort(id, val, succ) {
        val = parseInt(val);
        let requestOptions = {
            path: URL.updateState,
            params: { id: id, sortNum: val },
            succ: succ,
        };
        POST(requestOptions);
    },
    up(id, state, succ) {
        let requestOptions = {
            path: URL.updateState,
            params: {
                id: id,
                productStatus: state,
            },
            succ: succ,
        };
        POST(requestOptions);
    },
    del(id, succ) {
        let requestOptions = {
            path: URL.updateState,
            params: {
                id: id,
                flag: 0,
            },
            succ: succ,
        };
        POST(requestOptions);
    },
    star(succ) {
        configApi.dict("1033", succ);
    },
    windowConfig(succ) {
        configApi.dict("1036", succ);
    },
    breakfastConfig(succ) {
        configApi.dict("1037", succ);
    },
    cancelConfig(succ) {
        configApi.dict("1038", succ);
    },
    catering(succ) {
        let requestOptions = {
            path: URL.catering,
            succ: (val) => {
                succ(val.itemList);
            },
        };
        POST(requestOptions);
    },
    facility(succ) {
        let requestOptions = {
            path: URL.facility,
            succ: (val) => {
                succ(val.itemList);
            },
        };
        POST(requestOptions);
    },
    label(succ) {
        configApi.label(labetType.hotel, succ)
    },
    base(succ) {
        configApi.base(succ);
    },
    category(succ) {
        categoryApi.getCategory(TYPE.hotel, (val) => {
            succ(
                val.itemList.map((e) => {
                    return { label: e.catName, value: e.catCode };
                })
            );
        });
    },
    roomConfigure(succ) {
        let requestOptions = {
            path: URL.roomConfigure,
            succ: (val) => {
                succ(
                    val.itemList.map((e) => {
                        return { label: e.name, value: e.id };
                    })
                );
            },
        };
        POST(requestOptions);
    },
    inputExport(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.categoryCode) && params.categoryCode != "all") {
            paramsData.categoryCode = params.categoryCode;
        }
        if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
            paramsData.shopCode = params.shopCode;
        }
        if ($.isNotEmpty(params.name)) {
            paramsData.hotelName = params.name;
        }
        paramsData.flowStatusCode = params.status ? parseInt(params.status) : 1;
        let requestOptions = {
            path: URL.inputExport,
            params: paramsData,
            succ: succ,
        };
        POST(requestOptions);
    },
    managerExport(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.categoryCode) && params.categoryCode != "all") {
            paramsData.categoryCode = params.categoryCode;
        }
        if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
            paramsData.shopCode = params.shopCode;
        }
        if ($.isNotEmpty(params.name)) {
            paramsData.productName = params.name;
        }
        if (params.isIntegral) {
            paramsData.belongMall = 1066002
        } else {
            paramsData.belongMall = 1066001
        }
        paramsData.productStatus = params.status ? parseInt(params.status) : 1;
        let requestOptions = {
            path: URL.managerExport,
            params: paramsData,
            succ: succ,
        };
        POST(requestOptions);
    },
    approval(code, status, remark, succ) {
        examineApi.approval(code, status, remark, succ);
    },
};

export default api;
