import {POST} from "@net/service.js";
import $ from "@util/base";
import page from "./page"

const URL = {
  list: "equity_list",
  get: "equity_get",
  add: "equity_add",
  del: "equity_del",
  update: "equity_update",
}

const api = {
  list(params, succ) {
    let paramsData = {}
    paramsData.page = params.page ? params.page : 1
    paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
    let requestOptions = {
      path: URL.list,
      params: paramsData,
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  },
  get(equityCode, succ) {
    let requestOptions = {
      path: URL.get,
      params: { equityCode: equityCode },
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  },
  del(equityCode, succ) {
    let requestOptions = {
      path: URL.del,
      params: { equityCode: equityCode },
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  },
  save(equity, succ) {
    let requestOptions = {
      path: URL.add,
      params: equity,
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  },
  update(equity, succ) {
    let requestOptions = {
      path: URL.update,
      params: equity,
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  }
}
export default api
