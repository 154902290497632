import { POST } from "@net/service.js";
import page from "@/api/page";

const URL = {
    list: 'line_share_list',
    add: 'line_share_add',
    del: 'line_share_del',
    get: 'line_share_get',
    update: 'line_share_update'
};

const api = {
    list(params, succ) {
        let paramsData = {}
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.list,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    add(params, succ) {
        let requestOptions = {
            path: URL.add,
            params: params,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    get(id, succ) {
        let requestOptions = {
            path: URL.get,
            params: { id: id },
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    update(params, succ) {
        let requestOptions = {
            path: URL.update,
            params: params,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    del(id, succ) {
        let requestOptions = {
            path: URL.del,
            params: { id: id },
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
}

export default api
