import { POST } from "@net/service.js";
import $ from "@util/base";

const URL = {
  banner: {
    list: "home_banner_list",
    add: "home_banner_add",
    del: "home_banner_delete",
    update: "home_banner_update",
    sort: "home_banner_sort_change",
  },
  recommend: {
    list: "home_recommend_list",
    add: "home_recommend_add",
    del: "home_recommend_delete",
    update: "home_recommend_update",
    sort: "home_recommend_sort_change",
  },
};

const api = {
  list(type, succ) {
    let requestOptions = {
      path: URL[type].list,
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  },
  del(type, id, succ) {
    let requestOptions = {
      path: URL[type].del,
      params: { id: id },
      succ: succ,
    };
    POST(requestOptions);
  },
  sort(type, idList, succ) {
    let requestOptions = {
      path: URL[type].sort,
      params: { idList: idList },
      succ: succ,
    };
    POST(requestOptions);
  },
  add(type, params, succ) {
    let requestOptions = {
      path: URL[type].add,
      params: params,
      succ: succ,
    };
    POST(requestOptions);
  },
  update(type, params, succ) {
    let requestOptions = {
      path: URL[type].update,
      params: params,
      succ: succ,
    };
    POST(requestOptions);
  }
};

export default api;
