import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "additional_fees_manager"
};
const _hoisted_2 = {
  class: "base_screen_header"
};
const _hoisted_3 = {
  class: "dialog-footer"
};
import DataCollectTable from "@com/table/DataCollectTable.vue";
import { OP_KEY, COLUMN_TYPE, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import UploadSinglePicture from "@com/upload/UploadSinglePicture.vue";
import { ref, reactive } from "vue";
import $ from "@util/base";
import { $msg, $box } from '@/common/ui/msg';
import useRoute from "@util/router";
import api from "@api/additionalFees";
export default {
  __name: 'AdditionalFeesManager',

  setup(__props) {
    const route = useRoute();
    const total = ref(0);
    const data = ref([]);

    function getOperations() {
      return [OPERATIONS.modify, OPERATIONS.del];
    }

    const tableConfig = {
      showIndex: true,
      operations: {
        label: '操作',
        width: 120,
        operations: getOperations
      },
      column: [{
        label: '一级名称',
        prop: 'groupName'
      }, {
        label: '二级名称',
        prop: 'extraName'
      }, {
        label: '费用金额',
        prop: 'extraPrice',
        width: 150
      }, {
        label: '供应商',
        prop: 'supplier'
      }, {
        label: '费用详情',
        prop: 'picUrl',
        type: COLUMN_TYPE.picture,
        width: 100
      }]
    };
    const dialogVisible = ref(false);
    const title = ref("");
    const formRef = ref(null);
    const additionalFeesForm = ref({});
    var additionalFeesId = null;
    const opHander = operationsHander();
    opHander.install(OP_KEY.modify, data => {
      title.value = "修改附加费用";
      additionalFeesId = data.id;
      dialogVisible.value = true;
      resetForm();
      api.details(data.id, val => {
        additionalFeesForm.value = val;
      });
    });
    opHander.install(OP_KEY.delete, data => {
      $box.open("删除确认", `是否删除该附加费用`, () => {
        api.delete(data.id, () => {
          $msg.succ("删除成功");
          getList();
        });
      });
    });
    const additionalFeesRules = {
      groupName: [{
        required: true,
        message: '请输入一级名称',
        trigger: 'blur'
      }],
      extraName: [{
        required: true,
        message: '请输入二级名称',
        trigger: 'blur'
      }],
      extraPrice: [{
        required: true,
        message: '请输入费用金额',
        trigger: 'blur'
      }],
      picUrl: [{
        required: true,
        message: '请上传费用详情',
        trigger: 'change'
      }]
    };

    const submitForm = formEl => {
      formEl.validate((valid, fields) => {
        if (valid) {
          if (additionalFeesId) {
            api.update(additionalFeesForm.value, additionalFeesId, () => {
              $msg.succ("修改成功");
              dialogVisible.value = false;
              getList();
            });
          } else {
            api.add(additionalFeesForm.value, () => {
              $msg.succ("新增成功");
              dialogVisible.value = false;
              getList();
            });
          }
        }
      });
    };

    const resetForm = () => {
      if (!formRef.value) return;
      formRef.value.resetFields();
    };

    function add() {
      title.value = "新增附加费用";
      additionalFeesId = null;
      dialogVisible.value = true;
      resetForm();
    }

    getList();

    function getList() {
      let routeQuery = route.query();
      api.list({
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        total.value = val.total;
        data.value = val.itemList;
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_main = _resolveComponent("el-main");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_input_number = _resolveComponent("el-input-number");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
          onClick: add,
          type: "primary"
        }, {
          default: _withCtx(() => [_createTextVNode("新增附加费用")]),
          _: 1
        })]), _createVNode(DataCollectTable, {
          data: data.value,
          total: total.value,
          config: tableConfig,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"])]),
        _: 1
      }), _createVNode(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => dialogVisible.value = $event),
        title: title.value,
        width: "35%",
        top: "10vh",
        class: "base_dialog additional_fees_dialog"
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_3, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[5] || (_cache[5] = $event => submitForm(formRef.value))
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: _cache[6] || (_cache[6] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "formRef",
          ref: formRef,
          model: additionalFeesForm.value,
          rules: additionalFeesRules,
          "label-width": "80px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "一级名称",
            prop: "groupName"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: additionalFeesForm.value.groupName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => additionalFeesForm.value.groupName = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "二级名称",
            prop: "extraName"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: additionalFeesForm.value.extraName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => additionalFeesForm.value.extraName = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "费用金额",
            prop: "extraPrice"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input_number, {
              modelValue: additionalFeesForm.value.extraPrice,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => additionalFeesForm.value.extraPrice = $event),
              modelModifiers: {
                number: true
              },
              min: 0,
              precision: 2,
              "controls-position": "right"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "供应商"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: additionalFeesForm.value.supplier,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => additionalFeesForm.value.supplier = $event),
              modelModifiers: {
                number: true
              }
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "费用详情",
            prop: "picUrl"
          }, {
            default: _withCtx(() => [_createVNode(UploadSinglePicture, {
              modelValue: additionalFeesForm.value.picUrl,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => additionalFeesForm.value.picUrl = $event),
              width: 200
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }

};