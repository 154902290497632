import { POST } from "@net/service.js";
import configApi from "./config"
import $ from "@util/base";
import page from "./page"

const URL = {
    approval: 'approvalflow_status_change',
    goods: {
        list: "approvalflow_product_list",
        num: "approvalflow_product_status_num"
    },
    travel: {
        list: "approvalflow_travel_list",
        num: "approvalflow_travel_status_num",
    },
    hotel: {
        list: "approvalflow_hotel_list",
        num: "approvalflow_hotel_status_num",
    },
    deposit: {
        list: "approvalflow_deposit_list",
        num: "approvalflow_deposit_status_num",
    },
    points: {
        list: "approvalflow_rewardpoints_list",
        num: "approvalflow_rewardpoints_status_num",
    },
    line: {
        list: "approvalflow_line_list",
        num: "approvalflow_line_status_num",
    },
    submitExamine: "submit_for_review",
    updateShop: "update_shop",
};

const api = {
    base(succ) {
        configApi.base(succ)
    },
    approval(code, status, remark, succ) {
        let paramsData = {}
        paramsData.flowCode = code
        paramsData.flowStatus = status
        paramsData.flowRemark = remark
        let requestOptions = {
            path: URL.approval,
            params: paramsData,
            isLoading: true,
            succ: succ,
        }
        POST(requestOptions)
    },
    list(type, params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.transactionType) && params.transactionType != 'all') {
            paramsData.transactionType = parseInt(params.transactionType)
        }
        if ($.isNotEmpty(params.mainBusiType) && params.mainBusiType != 'all') {
            paramsData.mainBusiType = params.mainBusiType
        }
        if ($.isNotEmpty(params.classify) && params.classify != 'all') {
            paramsData.catCode = params.classify
        }
        if ($.isNotEmpty(params.flowStatus) && params.flowStatus != 'all') {
            paramsData.flowStatus = parseInt(params.flowStatus)
        }
        if ($.isNotEmpty(params.shopCode) && params.shopCode != 'all') {
            paramsData.shopCode = parseInt(params.shopCode)
        }
        if ($.isNotEmpty(params.name)) {
            paramsData.name = params.name
        }
        if ($.isNotEmpty(params.memberName)) {
            paramsData.memberName = params.memberName
        }
        if ($.isNotEmpty(params.memberLoginName)) {
            paramsData.memberLoginName = params.memberLoginName
        }
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL[type].list,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    num(type, catCode, base, name, succ) {
        let paramsData = {}
        if ($.isNotEmpty(catCode) && catCode != 'all') {
            paramsData.catCode = catCode
        }
        if ($.isNotEmpty(name)) {
            paramsData.name = name
        }
        if ($.isNotEmpty(base)) {
            paramsData.shopCode = base
        }

        let requestOptions = {
            path: URL[type].num,
            params: paramsData,
            succ: val => {
                succ([val.total, val.pending, val.accepted, val.rejected])
            }
        }
        POST(requestOptions)
    },
    depositNum(name, login, succ) {
        let paramsData = {}
        if ($.isNotEmpty(name)) {
            paramsData.memberName = name
        }
        if ($.isNotEmpty(login)) {
            paramsData.memberLoginName = login
        }

        let requestOptions = {
            path: URL.deposit.num,
            params: paramsData,
            succ: val => {
                succ([val.total, val.pending, val.accepted, val.rejected])
            }
        }
        POST(requestOptions)
    },
    pointsNum(name, login, succ) {
        let paramsData = {}
        if ($.isNotEmpty(name)) {
            paramsData.memberName = name
        }
        if ($.isNotEmpty(login)) {
            paramsData.memberLoginName = login
        }

        let requestOptions = {
            path: URL.points.num,
            params: paramsData,
            succ: val => {
                succ([val.total, val.pending, val.accepted, val.rejected])
            }
        }
        POST(requestOptions)
    },
    lineNum(succ) {
        let paramsData = {}
        let requestOptions = {
            path: URL.line.num,
            params: paramsData,
            succ: val => {
                succ([val.total, val.pending, val.accepted, val.rejected])
            }
        }
        POST(requestOptions)
    },

    examine(type, code, shopCode, succ) {
        let paramsData = {
            code: code,
            shopCode: shopCode,
        }
        switch (type) {
            case "goods":
                paramsData.flowType = "1006001"
                break
            case "hotel":
                paramsData.flowType = "1006003"
                break
            case "travel":
                paramsData.flowType = "1006002"
                break
        }
        let requestOptions = {
            path: URL.submitExamine,
            params: paramsData,
            succ: succ
        }
        POST(requestOptions)
    },

    updateShop(type, flowCode, code, shopCode, succ) {
        let paramsData = {
            flowCode: flowCode,
            code: code,
            shopCode: shopCode,
        }
        switch (type) {
            case "goods":
                paramsData.type = "1003001"
                break
            case "hotel":
                paramsData.type = "1003003"
                break
            case "travel":
                paramsData.type = "1003002"
                break
        }
        let requestOptions = {
            path: URL.updateShop,
            params: paramsData,
            succ: succ
        }
        POST(requestOptions)
    }
}

export default api