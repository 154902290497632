import {
    POST
} from '@net/service.js'
import $ from "@util/base";

const URL = {
    base: 'choose_shop_list',
    label: 'choos_tag_config_list',
    dict: 'choose_dict_list',
    area: 'choose_area',
    code: 'get_unlimited',
    sku: 'choose_sku_search'
}

const TYPE = {
    goods: '1003001',
    travel: '1003002',
    hotel: '1003003'
}

const api = {
    base(succ) {
        let requestOptions = {
            path: URL.base,
            succ: val => {
                succ(val.itemList.map(e => {
                    return { label: e.name, value: e.code, id: e.id }
                }))
            }
        }
        POST(requestOptions)
    },
    label(type, succ) {
        let params = {
            busiType: type
        }
        let requestOptions = {
            path: URL.label,
            params: params,
            succ: val => {
                succ(val.itemList.map(e => {
                    return { label: e.name, value: e.id, type: val.busiType }
                }))
            }
        }
        POST(requestOptions)
    },
    dict(code, succ) {
        let requestOptions = {
            path: URL.dict,
            params: { parentCode: code },
            succ: val => {
                succ(val.itemList.map(e => {
                    return { label: e.name, value: e.code, id: e.id }
                }))
            }
        }
        POST(requestOptions)
    },
    area(parentCode, level, succ) {
        let paramsData = {}
        if ($.isNotEmpty(parentCode)) {
            paramsData.parentCode = parentCode
        }
        if ($.isNotEmpty(level)) {
            paramsData.level = level
        }
        let requestOptions = {
            path: URL.area,
            params: paramsData,
            succ: val => {
                succ(val.itemList.map(e => {
                    return { label: e.name, value: e.code }
                }))
            }
        }
        POST(requestOptions)
    },
    code(code, page, succ) {
        let paramsData = {}
        paramsData.code = `code=${code}`;
        paramsData.page = parseInt(page);
        let requestOptions = {
            path: URL.code,
            params: paramsData,
            succ: succ
        }
        POST(requestOptions)
    },
    sku(name, succ) {
        let requestOptions = {
            path: URL.sku,
            params: { name: name },
            succ: (val) => {
                succ(val.itemList.map(e => {
                    e.value = e.productName
                    return e
                }))
            }
        }
        POST(requestOptions)
    }
}

export default api
export { TYPE }