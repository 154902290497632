import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import AfterSaleOrder from "@com/order/AfterSaleOrder.vue";
export default {
  __name: 'AfterSaleOrder',

  setup(__props) {
    return (_ctx, _cache) => {
      const _component_el_main = _resolveComponent("el-main");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createVNode(AfterSaleOrder, {
          type: "order",
          routeName: "aftersaleorder"
        })]),
        _: 1
      })]);
    };
  }

};