import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-3387201d"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["src"];
const _hoisted_2 = ["onClick"];
const _hoisted_3 = ["onClick"];
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  key: 0,
  class: "pagination_layout"
};
import $ from "@util/base";
import { ref, watch } from "vue";
import { COLUMN_TYPE } from "./tableOperations";
import useRoute from "@util/router";
import defaultPage from "@api/page";
export default {
  __name: 'DataCollectTable',
  props: {
    /**
     * config:{
     *      showSelection:false,
     *      showIndex:false,
     *      operations:{
     *          label:'操作',       操作列名，如果没有设置则不显示操作栏
     *          width:200,         操作列宽度
     *          operations:[        操作列表
     *              {
     *                  key:1,
     *                  label:'修改',
     *                  color:'#ffffff'
     *              }
     *          ]
     *      },
     *      column:[ 列配置
     *          {
     *              label:'姓名',   列名
     *              prop:'name',   列数据字段
     *              width:180,     列宽度
     *          }
     *      ]
     * }
     */
    config: Object,
    data: Array,
    //数据源
    total: {
      type: Number,
      default: 0
    },
    //总条数
    isRoute: {
      type: Boolean,
      default: true
    },
    // 分页切换是否上路由 需要配合pageChange
    isRadio: {
      type: Boolean,
      default: false
    },
    // 是否是单选
    isPage: {
      type: Boolean,
      default: true
    } // 是否显示分页

  },
  emits: ["operationsHander", "pageChange"],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const route = useRoute();
    const props = __props;
    let routeQuery = route.query();
    const size = ref(routeQuery.s ? parseInt(routeQuery.s) : defaultPage.size);
    const page = ref(routeQuery.p ? routeQuery.p : 1);
    const data = ref(props.data || []);
    const total = ref(props.total);
    watch(() => props.data, val => {
      data.value = val;
    });
    watch(() => props.total, val => {
      total.value = val;
    }); // watch(
    //   () => route.params,
    //   (val) => {
    //     console.log(val)
    //     if (val.s) {
    //       size.value = val.s
    //     }
    //     if (val.p) {
    //       page.value = val.p
    //     }
    //   }
    // );

    /**
     * 操作列 操作按钮事件,
     * operationsHander(type,rowData)
     * 分页栏 事件
     * pageChange
     */

    const emit = __emit;

    function indexMethod(index) {
      return (page.value - 1) * size.value + index + 1;
    }

    function pagerChange(val) {
      if (props.isRoute) {
        route.replaceQuery("p", val);
      } else {
        emit("pageChange", "page", val);
      }
    }

    function pagerSizeChange(val) {
      if (props.isRoute) {
        route.replaceQuery("s", val);
      } else {
        emit("pageChange", "size", val);
      }
    }

    function switchBeforeChange(val, key) {
      return () => {
        return new Promise((resolve, reject) => {
          emit("operationsHander", key, {
            data: val,
            resolve: resolve,
            reject: reject
          });
        });
      };
    }

    const tableRef = ref(null);
    var selectData = [];

    function handleSelectionChange(val) {
      selectData = val;
    }

    function handleSelectChange(selection, row) {
      if (props.isRadio) {
        if (selection.length > 1) {
          clearSelection();
          tableRef.value.toggleRowSelection(selection.pop());
        }
      }
    }

    function getSelectData() {
      return selectData;
    }

    function clearSelection() {
      tableRef.value.clearSelection();
    }

    __expose({
      getSelectData,
      clearSelection
    });

    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_switch = _resolveComponent("el-switch");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_input_number = _resolveComponent("el-input-number");

      const _component_el_tooltip = _resolveComponent("el-tooltip");

      const _component_el_table = _resolveComponent("el-table");

      const _component_el_pagination = _resolveComponent("el-pagination");

      const _directive_auth = _resolveDirective("auth");

      const _directive_horizontal_scroll = _resolveDirective("horizontal-scroll");

      return _openBlock(), _createElementBlock(_Fragment, null, [_withDirectives((_openBlock(), _createBlock(_component_el_table, {
        ref_key: "tableRef",
        ref: tableRef,
        data: data.value,
        border: "",
        style: {
          "width": "100%"
        },
        onSelect: handleSelectChange,
        onSelectionChange: handleSelectionChange
      }, {
        default: _withCtx(() => [__props.config.showSelection ? (_openBlock(), _createBlock(_component_el_table_column, {
          key: 0,
          type: "selection",
          width: "55",
          align: "center"
        })) : _createCommentVNode("", true), _unref($).isNotNull(__props.config.operations) ? (_openBlock(), _createBlock(_component_el_table_column, {
          key: 1,
          label: __props.config.operations.label,
          width: __props.config.operations.width,
          align: "center"
        }, {
          default: _withCtx(operationsScope => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.config.operations.operations(operationsScope.row), (item, index) => {
            return _withDirectives((_openBlock(), _createBlock(_component_el_button, {
              key: index,
              size: "small",
              type: "primary",
              text: "",
              onClick: $event => _ctx.$emit('operationsHander', item, operationsScope.row)
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label), 1)]),
              _: 2
            }, 1032, ["onClick"])), [[_directive_auth, _ctx.getCurCode(item.key)]]);
          }), 128))]),
          _: 1
        }, 8, ["label", "width"])) : _createCommentVNode("", true), __props.config.showIndex ? (_openBlock(), _createBlock(_component_el_table_column, {
          key: 2,
          type: "index",
          label: "序号",
          index: indexMethod,
          width: "60",
          align: "center"
        })) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.config.column, (column, index) => {
          return _openBlock(), _createBlock(_component_el_table_column, {
            key: index,
            prop: column.prop,
            label: column.label,
            width: column.width,
            align: "center",
            "min-width": 150
          }, _createSlots({
            _: 2
          }, [column.type === _unref(COLUMN_TYPE).picture ? {
            name: "default",
            fn: _withCtx(scope => [_createElementVNode("img", {
              src: scope.row[column.prop],
              style: _normalizeStyle({
                width: column.width - 25 + 'px',
                height: column.width - 25 + 'px'
              })
            }, null, 12, _hoisted_1)]),
            key: "0"
          } : column.type === _unref(COLUMN_TYPE).switch ? {
            name: "default",
            fn: _withCtx(scope => [_withDirectives(_createVNode(_component_el_switch, {
              modelValue: scope.row[column.prop],
              "onUpdate:modelValue": $event => scope.row[column.prop] = $event,
              "inline-prompt": "",
              "active-text": column.activeText,
              "inactive-text": column.inactiveText,
              "active-value": column.activeValue,
              "inactive-value": column.inactiveValue,
              size: "large",
              width: column.switchWidth,
              "before-change": switchBeforeChange(scope.row, column.operationsKey)
            }, null, 8, ["modelValue", "onUpdate:modelValue", "active-text", "inactive-text", "active-value", "inactive-value", "width", "before-change"]), [[_directive_auth, _ctx.getCurCode(_unref(COLUMN_TYPE).switch)]])]),
            key: "1"
          } : column.type === _unref(COLUMN_TYPE).input ? {
            name: "default",
            fn: _withCtx(scope => [_createVNode(_component_el_input, {
              type: "number",
              onkeyup: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
              modelValue: scope.row[column.prop],
              "onUpdate:modelValue": $event => scope.row[column.prop] = $event,
              modelModifiers: {
                number: true
              },
              onChange: val => {
                _ctx.$emit('operationsHander', column.operationsKey, {
                  value: val,
                  data: scope.row
                });
              }
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])]),
            key: "2"
          } : column.type === _unref(COLUMN_TYPE).inputPrice ? {
            name: "default",
            fn: _withCtx(scope => [_createVNode(_component_el_input_number, {
              modelValue: scope.row[column.prop],
              "onUpdate:modelValue": $event => scope.row[column.prop] = $event,
              modelModifiers: {
                number: true
              },
              min: 0,
              precision: 2,
              controls: false,
              "controls-position": "right",
              onChange: val => {
                _ctx.$emit('operationsHander', column.operationsKey, {
                  value: val,
                  data: scope.row
                });
              }
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])]),
            key: "3"
          } : column.type === _unref(COLUMN_TYPE).linkText ? {
            name: "default",
            fn: _withCtx(scope => [_createElementVNode("p", {
              style: {
                "cursor": "pointer"
              },
              onClick: $event => _ctx.$emit('operationsHander', column.operationsKey, scope.row)
            }, _toDisplayString(scope.row[column.prop]), 9, _hoisted_2)]),
            key: "4"
          } : column.type === _unref(COLUMN_TYPE).tooltip ? {
            name: "default",
            fn: _withCtx(scope => [_createVNode(_component_el_tooltip, {
              content: scope.row[column.prop],
              "popper-class": "table_tooltip_desc",
              placement: "top"
            }, {
              default: _withCtx(() => [_createElementVNode("p", {
                class: "descStyle;cursor: pointer;",
                style: _normalizeStyle({
                  '-webkit-line-clamp': column.rows
                })
              }, _toDisplayString(scope.row[column.prop]), 5)]),
              _: 2
            }, 1032, ["content"])]),
            key: "5"
          } : column.type === _unref(COLUMN_TYPE).depositMoney ? {
            name: "default",
            fn: _withCtx(scope => [_createElementVNode("p", {
              style: {
                "cursor": "pointer"
              },
              onClick: $event => _ctx.$emit('operationsHander', column.operationsKey, scope.row)
            }, _toDisplayString(scope.row[column.prop]), 9, _hoisted_3)]),
            key: "6"
          } : column.type === _unref(COLUMN_TYPE).freezeMoney ? {
            name: "default",
            fn: _withCtx(scope => [_createElementVNode("p", {
              style: {
                "cursor": "pointer"
              },
              onClick: $event => _ctx.$emit('operationsHander', column.operationsKey, scope.row)
            }, _toDisplayString(scope.row[column.prop]), 9, _hoisted_4)]),
            key: "7"
          } : {
            name: "default",
            fn: _withCtx(scope => [_createElementVNode("p", null, _toDisplayString(scope.row[column.prop]), 1)]),
            key: "8"
          }]), 1032, ["prop", "label", "width"]);
        }), 128))]),
        _: 1
      }, 8, ["data"])), [[_directive_horizontal_scroll, 'always']]), __props.isPage ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_el_pagination, {
        background: "",
        layout: "total, prev, pager, next, sizes",
        total: total.value,
        currentPage: page.value,
        "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => page.value = $event),
        "page-size": size.value,
        "onUpdate:pageSize": _cache[1] || (_cache[1] = $event => size.value = $event),
        "page-sizes": [10, 20, 50, 100],
        onCurrentChange: pagerChange,
        onSizeChange: pagerSizeChange
      }, null, 8, ["total", "currentPage", "page-size"])])) : _createCommentVNode("", true)], 64);
    };
  }

};