import { POST } from "@net/service.js";
import page from "@/api/page";

const URL = {
    lineTypeSpecs: 'choose_line_type_config_list',
    add: 'line_type_config_add',
    del: 'line_type_config_del',
    get: 'line_type_config_get',
    update: 'line_type_config_update'
};

const api = {
    lineTypeSpecs(succ) {
        let requestOptions = {
            path: URL.lineTypeSpecs,
            succ: (val) => {
                succ(val.itemList);
            },
        };
        POST(requestOptions);
    },


}

export default api
