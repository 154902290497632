import { POST } from "@net/service.js";
import configApi from "./config"
import $ from "@util/base";
import page from "./page"

const URL = {
    roleList: "choose_role_list",
    userList: "account_list",
    details: "account_get",
    add: "account_add",
    update: "account_update",
    delete: "account_delete",
    status: "account_update_status",
    export: "account_list_export",
    resetPassword: "account_password_reset"
};

const api = {
    base(succ) {
        configApi.base(succ)
    },
    roleList(succ) {
        let requestOptions = {
            path: URL.roleList,
            succ: val => {
                succ(val.itemList.map(e => {
                    return { label: e.name, value: e.id }
                }))
            }
        }
        POST(requestOptions)
    },
    userList(params, succ) {
        let paramsData = {}
        paramsData.flag = params.flag ? parseInt(params.flag) : 2
        if ($.isNotEmpty(params.shopId) && params.shopId != 'all') {
            paramsData.shopId = parseInt(params.shopId)
        }
        if ($.isNotEmpty(params.loginName)) {
            paramsData.loginName = params.loginName
        }
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.userList,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    details(id, succ) {
        let requestOptions = {
            path: URL.details,
            params: { id: parseInt(id) },
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    add(params, succ) {
        let requestOptions = {
            path: URL.add,
            params: params,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    update(params, succ) {
        delete params.createTime
        delete params.creatorName
        delete params.loginPassword
        let requestOptions = {
            path: URL.update,
            params: params,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    delete(id, succ) {
        let requestOptions = {
            path: URL.delete,
            params: { id: parseInt(id) },
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    resetPassword(id, succ) {
        let requestOptions = {
            path: URL.resetPassword,
            params: { id: parseInt(id) },
            succ: succ
        }
        POST(requestOptions)
    },
    status(id, flag, succ, fail) {
        let paramsData = {}
        paramsData.id = parseInt(id)
        paramsData.flag = flag ? 0 : 1
        let requestOptions = {
            path: URL.status,
            params: paramsData,
            isLoading: true,
            succ: succ,
            fail: fail
        }
        POST(requestOptions)
    },
    export (params, succ) {
        let paramsData = {}
        paramsData.flag = params.flag ? parseInt(params.flag) : 2
        if ($.isNotEmpty(params.shopId) && params.shopId != 'all') {
            paramsData.shopId = parseInt(params.shopId)
        }
        if ($.isNotEmpty(params.loginName)) {
            paramsData.loginName = params.loginName
        }
        let requestOptions = {
            path: URL.export,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
}

export default api