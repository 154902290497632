import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "deposit_details"
};
const _hoisted_2 = {
  class: "base_details_content"
};
const _hoisted_3 = {
  key: 0,
  class: "content",
  style: {
    "margin-top": "20px"
  }
};
const _hoisted_4 = {
  key: 1,
  class: "content"
};
const _hoisted_5 = {
  class: "base_details_content_second"
};
import { ref, reactive } from "vue";
import $ from "@util/base";
import { $msg, $box } from "@/common/ui/msg";
import BackTitleView from "@com/BackTitleView.vue";
import useRoute from "@util/router";
import api from "@api/deposit";
import DataCollectTable from "@com/table/DataCollectTable.vue";
export default {
  __name: 'DepositDetails',

  setup(__props) {
    const route = useRoute();
    const urlQuery = route.query();
    var isApproval = false;
    route.watchRouter("depositdetails", () => {
      getData();
    });
    $.handleCRU(urlQuery.type, {
      show: () => {
        getData();
      },
      examine: () => {
        isApproval = true;
        getData();
      }
    });
    const data = ref([{}]);
    const total = ref(0);
    const config = {
      showIndex: true,
      column: [{
        label: "用户手机号",
        prop: "phone"
      }, {
        label: "储值金额（元）",
        prop: "changeMoney"
      }, {
        label: "姓名/单位",
        prop: "persionName"
      }, {
        label: "说明",
        prop: "changeReason"
      }, {
        label: "备注",
        prop: "remark"
      }, {
        label: "失效日期",
        prop: "depositExpireTime"
      }]
    };

    function getData() {
      let routeQuery = route.query();
      console.log(routeQuery.s, routeQuery.p);
      api.details(routeQuery.code, routeQuery.batch, routeQuery.p, routeQuery.s, val => {
        total.value = val.total;
        data.value = val.itemList.map(e => {
          if (e.changeMoney > 0) {
            e.changeMoney = `+${e.changeMoney}`;
          }

          return e;
        });
      });
    } // 审批通过


    function approvalPass() {
      $box.open("通过", "确认该储值审批通过", () => {
        api.approval(data.value[0].flowCode, "1007004", "", () => {
          $msg.succ("审批成功");
          route.go(-1);
        });
      });
    } // 驳回


    function approvalRefusal() {
      console.log(data.value);
      $box.input("确认驳回", "驳回原因", null, val => {
        api.approval(data.value[0].flowCode, "1007003", val, () => {
          $msg.succ("驳回成功");
          route.go(-1);
        });
      });
    }

    function back() {
      route.go(-1);
    }

    return (_ctx, _cache) => {
      const _component_el_descriptions_item = _resolveComponent("el-descriptions-item");

      const _component_el_descriptions = _resolveComponent("el-descriptions");

      const _component_el_main = _resolveComponent("el-main");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_footer = _resolveComponent("el-footer");

      const _component_el_container = _resolveComponent("el-container");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, {
        class: "base_details_container"
      }, {
        default: _withCtx(() => [_createVNode(BackTitleView, {
          title: "储值详情"
        }), _createVNode(_component_el_main, null, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_unref(isApproval) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(DataCollectTable, {
            data: data.value,
            total: total.value,
            config: config
          }, null, 8, ["data", "total"])])) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_descriptions, {
            column: 1,
            class: "base_descriptions_one"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_descriptions_item, {
              label: "用户手机号"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(data.value[0].phone), 1)]),
              _: 1
            }), _createVNode(_component_el_descriptions_item, {
              label: "储值金额（元）"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(data.value[0].changeMoney), 1)]),
              _: 1
            }), _createVNode(_component_el_descriptions_item, {
              label: "姓名/单位"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(data.value[0].persionName), 1)]),
              _: 1
            }), _createVNode(_component_el_descriptions_item, {
              label: "说明"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(data.value[0].changeReason), 1)]),
              _: 1
            }), _createVNode(_component_el_descriptions_item, {
              label: "备注"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(data.value[0].remark), 1)]),
              _: 1
            }), _createVNode(_component_el_descriptions_item, {
              label: "失效日期"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(data.value[0].depositExpireTime), 1)]),
              _: 1
            })]),
            _: 1
          })])]))])]),
          _: 1
        }), _createVNode(_component_el_footer, null, {
          default: _withCtx(() => [_unref(isApproval) ? _withDirectives((_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            onClick: approvalPass,
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode("审批通过")]),
            _: 1
          }, 512)), [[_vShow, data.value[0] != null && data.value[0].flowStatus == '1007002']]) : _createCommentVNode("", true), _unref(isApproval) ? _withDirectives((_openBlock(), _createBlock(_component_el_button, {
            key: 1,
            onClick: approvalRefusal,
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode("审批驳回")]),
            _: 1
          }, 512)), [[_vShow, data.value[0] != null && data.value[0].flowStatus == '1007002']]) : _createCommentVNode("", true), _createVNode(_component_el_button, {
            onClick: back
          }, {
            default: _withCtx(() => [_createTextVNode("关闭")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]);
    };
  }

};