import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  __name: 'LineTypeConfig',

  setup(__props) {
    const config = {
      name: '类型',
      type: 'lineType',
      route: 'lineTypeConfig'
    };
    return (_ctx, _cache) => {
      const _component_SingleFieldManager = _resolveComponent("SingleFieldManager");

      return _openBlock(), _createBlock(_component_SingleFieldManager, {
        config: config
      });
    };
  }

};