import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
import { $msg, $box } from "@ui/msg";
import { reactive, ref } from "vue";
import { TYPE, api } from "@api/category";
import CategoryView from '@/components/CategoryView.vue';
import $ from "@/common/utils/base";
export default {
  __name: 'CategoryManager',
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: ''
        };
      }
    }
  },

  setup(__props) {
    const dialogVisible = ref(false);
    const dialogTitle = ref("");
    const formRef = ref(null);
    const form = reactive({
      name: null,
      sort: null,
      id: null
    });
    const props = __props;
    const rules = {
      name: [{
        required: true,
        message: '请输入分类名称',
        trigger: 'blur'
      }],
      sort: [{
        required: true,
        message: '请输入排序值',
        trigger: 'blur'
      }]
    };
    let typeName = '商品';

    switch (props.config.type) {
      case 'goods':
        typeName = "商品";
        break;

      case 'hotel':
        typeName = "酒店";
        break;

      case 'travel':
        typeName = "旅游";
        break;
    }

    const data = ref([{
      id: 'all',
      name: '全部',
      code: 'all'
    }]);
    getCategory();

    function getCategory() {
      api.getCategory(TYPE[props.config.type], val => {
        let list = val.itemList.map(e => {
          return {
            id: e.id,
            name: e.catName,
            code: e.catCode,
            sort: e.sort
          };
        });
        list.unshift({
          id: 'all',
          name: '全部',
          code: 'all'
        });
        data.value = list;
      });
    }

    function modifyCategory(data) {
      form.id = data.id;
      form.name = data.name;
      form.sort = data.sort;
      dialogTitle.value = "修改分类";
      dialogVisible.value = true; // $box.input("修改", '分类名称', data.name, val => {
      //   api.updateCategory(data.id, val, () => {
      //     getCategory()
      //     $msg.succ("修改成功");
      //   })
      // })
    }

    function delCategory(data) {
      $box.open("删除确认", `是否删除${data.name}`, () => {
        api.delCategory(data.id, () => {
          getCategory();
          $msg.succ("删除成功");
        });
      });
    }

    function addCategory(data) {
      dialogTitle.value = "新增分类";
      dialogVisible.value = true; // $box.alert("添加", '<div>分类名称</div><div> <el-input /></div><div>排序</div><div> <el-input /></div>', val => {
      //   api.addCategory(val, TYPE[props.config.type], () => {
      //     getCategory()
      //     $msg.succ("添加成功");
      //   })
      // })
    }

    const submitForm = async formEl => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          const data = {};
          Object.assign(data, form);

          if (data.id) {
            // 有id为编辑
            api.updateCategory(data.id, data.name, data.sort, () => {
              $msg.succ("修改成功");
              dialogVisible.value = false;
              getCategory();
            });
          } else {
            // 新增
            api.addCategory(data.name, data.sort, TYPE[props.config.type], () => {
              $msg.succ("新增成功");
              dialogVisible.value = false;
              getCategory();
            });
          }
        }
      });
    };

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(CategoryView, {
        title: _unref(typeName) + '分类',
        data: data.value,
        onModify: modifyCategory,
        onDel: delCategory,
        onAdd: addCategory
      }, null, 8, ["title", "data"]), _createVNode(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => dialogVisible.value = $event),
        title: dialogTitle.value,
        width: "35%",
        top: "35vh",
        class: "base_dialog"
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[2] || (_cache[2] = $event => submitForm(formRef.value))
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: _cache[3] || (_cache[3] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: form,
          "label-width": "92px",
          rules: rules,
          ref_key: "formRef",
          ref: formRef
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "分类名称:",
            prop: "name"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: form.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.name = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "排序:",
            prop: "sort"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: form.sort,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.sort = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue", "title"])], 64);
    };
  }

};