import {
  POST
} from '@net/service.js'
import $ from "@util/base"
import { TYPE, api as categoryApi } from "./category"
import configApi, { TYPE as labetType } from "./config"

const URL = {
  goodsSpecs: 'choose_attr_config_list',
  base: 'choose_shop_list',
  express: 'choose_postage_templete_list',
  station: 'choose_self_delivery_station_list'
}

const api = {
  goodsSpecs(succ) {
    let requestOptions = {
      path: URL.goodsSpecs,
      succ: val => {
        succ(val.itemList.map(e => {
          return { label: e.name, value: e.name }
        }))
      }
    }
    POST(requestOptions)
  },
  station(succ) {
    let requestOptions = {
      path: URL.station,
      succ: val => {
        succ(val.itemList.map(e => {
          return { label: e.name, value: e.id }
        }))
      }
    }
    POST(requestOptions)
  },
  base(succ) {
    configApi.base(succ)
  },
  label(succ) {
    configApi.label(labetType.goods, succ)
  },
  express(succ) {
    let requestOptions = {
      path: URL.express,
      succ: val => {
        succ(val.itemList.map(e => {
          return { label: e.name, value: e.code }
        }))
      }
    }
    POST(requestOptions)
  },
  category(succ) {
    categoryApi.getCategory(TYPE.goods, val => {
      succ(val.itemList.map(e => { return { label: e.catName, value: e.catCode } }))
    })
  },

}

export default api