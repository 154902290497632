import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "right m_b_15"
};
import { ref } from "vue";
import { OP_KEY, COLUMN_TYPE, OPERATIONS, operationsHander } from "@com/table/tableOperations"; // import data from "@/test/selectRoom.json"

import { Search } from '@element-plus/icons-vue';
import api from "@api/hotel";
export default {
  __name: 'HotelSelect',
  props: {
    title: String
  },
  emits: ['select'],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const data = ref([]);
    const total = ref(0);
    const drawer = ref(false);

    function open() {
      drawer.value = true;
    }

    function close() {
      drawer.value = false;
    }

    function goodsOperations() {
      return [OPERATIONS.select];
    }

    const config = {
      showIndex: true,
      operations: {
        label: '选择',
        width: 60,
        operations: goodsOperations
      },
      column: [{
        label: '酒店图片',
        prop: 'hotelMainPic',
        type: COLUMN_TYPE.picture,
        width: 100
      }, {
        label: '酒店名称',
        prop: 'hotelName'
      }, {
        label: '房型名称',
        prop: 'hotelRoomName',
        width: 100
      }, {
        label: '可住人数',
        prop: 'peopleNum',
        width: 100
      }]
    };
    const opHander = operationsHander();
    opHander.install(OP_KEY.select, data => {
      emit('select', data);
      close();
    });
    var page = null;
    var size = null;
    const name = ref();

    function pageChange(type, val) {
      if (type == 'page') {
        page = val;
      } else if (type == 'size') {
        size = val;
      }

      getData();
    }

    function searchName() {
      getData();
    }

    getData();

    function getData() {
      api.roomList({
        name: name.value,
        page: page,
        size: size
      }, val => {
        total.value = val.total;
        data.value = val.itemList;
      });
    }

    __expose({
      open
    });

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_DataCollectTable = _resolveComponent("DataCollectTable");

      const _component_el_drawer = _resolveComponent("el-drawer");

      return _openBlock(), _createBlock(_component_el_drawer, {
        "custom-class": "base_drawer",
        modelValue: drawer.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => drawer.value = $event),
        "close-on-click-modal": false,
        title: __props.title,
        "with-header": true,
        size: 720
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_input, {
          class: "input_width",
          modelValue: name.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => name.value = $event),
          onChange: searchName,
          placeholder: "酒店名称",
          "suffix-icon": _unref(Search)
        }, null, 8, ["modelValue", "suffix-icon"])]), _createVNode(_component_DataCollectTable, {
          ref: "hotelSelectTableRef",
          data: data.value,
          isRoute: false,
          total: total.value,
          config: config,
          onOperationsHander: _unref(opHander).receive,
          onPageChange: pageChange
        }, null, 8, ["data", "total", "onOperationsHander"])]),
        _: 1
      }, 8, ["modelValue", "title"]);
    };
  }

};