// 团建
import LineManager from '@view/teamBuilding/LineManager.vue'
import LineAreaConfig from '@/views/teamBuilding/LineAreaConfig.vue'
import LineTypeConfig from '@/views/teamBuilding/LineTypeConfig.vue'
import IntroduceInfo from '@view/teamBuilding/IntroduceInfo.vue'
import LineSharing from '@/views/teamBuilding/LineSharing.vue'
import LineSharingDetails from '@/views/teamBuilding/LineSharingDetails.vue'
import PartnershipInfo from '@view/teamBuilding/PartnershipInfo.vue'
import LineDetails from "@/views/teamBuilding/LineDetails.vue";


export default [{
    path: '/lineManager',
    name: 'lineManager',
    component: LineManager,
    meta: {
        activeMainMenu: 'teamBuilding',
        activeSubMenu: '/lineManager'
    }
}, {
    path: '/lineDetails',
    name: 'lineDetails',
    component: LineDetails,
    meta: {
        activeMainMenu: 'teamBuilding',
        activeSubMenu: '/lineManager'
    }
},{
    path: '/lineAreaConfig',
    name: 'lineAreaConfig',
    component: LineAreaConfig,
    meta: {
        activeMainMenu: 'teamBuilding',
        activeSubMenu: '/lineAreaConfig'
    }
},{
    path: '/lineTypeConfig',
    name: 'lineTypeConfig',
    component: LineTypeConfig,
    meta: {
        activeMainMenu: 'teamBuilding',
        activeSubMenu: '/lineTypeConfig'
    }
},{
    path: '/introduceInfo',
    name: 'introduceInfo',
    component: IntroduceInfo,
    meta: {
        activeMainMenu: 'teamBuilding',
        activeSubMenu: '/introduceInfo'
    }
},{
    path: '/lineSharing',
    name: 'lineSharing',
    component: LineSharing,
    meta: {
        activeMainMenu: 'teamBuilding',
        activeSubMenu: '/lineSharing'
    }
}, {
    path: '/lineSharingDetails',
    name: 'lineSharingDetails',
    component: LineSharingDetails,
    meta: {
        activeMainMenu: 'teamBuilding',
        activeSubMenu: '/lineSharing'
    }
},{
    path: '/partnershipInfo',
    name: 'partnershipInfo',
    component: PartnershipInfo,
    meta: {
        activeMainMenu: 'teamBuilding',
        activeSubMenu: '/partnershipInfo'
    }
}]
