import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import DataCollectTable from "@com/table/DataCollectTable.vue";
import { OP_KEY, COLUMN_TYPE, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import ChangeShop from "@com/examine/ChangeShop.vue";
import { ref } from "vue";
import useRoute from "@util/router";
import { $msg, $box } from "@/common/ui/msg";
import api from "@api/examine";
export default {
  __name: 'ExamineTable',
  props: {
    type: {
      type: String
    },
    data: {
      type: Array
    },
    total: {
      type: Number
    }
  },
  emits: ["getData"],

  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const props = __props;

    const getConfig = function () {
      switch (props.type) {
        case "goods":
          return goodsConfig;

        case "travel":
          return travelConfig;

        case "hotel":
          return hotelConfig;

        case "deposit":
          return depositConfig;

        case "points":
          return pointsConfig;

        case "line":
          return lineConfig;
      }
    };

    function examineOperations(data) {
      // 1007001草稿 1007002待审批 1007003被驳回 1007004审批通过
      switch (data.flowStatus) {
        // 只有待审批可以操作，其余都是只能查看
        case "1007002":
          if (props.type === "deposit") {
            return [OPERATIONS.check, OPERATIONS.pass, OPERATIONS.refusal];
          } else {
            var operations = [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.pass, OPERATIONS.refusal];

            if (props.type === "goods" || props.type === "travel" || props.type === "hotel") {
              operations.push(OPERATIONS.changeShop);
            }

            return operations;
          }

        default:
          return [OPERATIONS.check];
      }
    }

    const route = useRoute();
    const goodsConfig = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: examineOperations
      },
      column: [{
        label: "商品图片",
        prop: "mainPic",
        type: COLUMN_TYPE.picture,
        width: 100
      }, {
        label: "商品名称",
        prop: "name"
      }, {
        label: "商品编号",
        prop: "flowData",
        width: 100
      }, {
        label: "审核状态",
        prop: "state",
        width: 100
      }, {
        label: "驳回原因",
        prop: "flowRemark",
        width: 100
      }, {
        label: "总库存",
        prop: "stockNum",
        width: 160
      }, {
        label: "归属基地",
        prop: "shopName",
        width: 100
      }, {
        label: "更新时间",
        prop: "updateTime",
        width: 100
      }, {
        label: "更新人",
        prop: "updateBy",
        width: 100
      }, {
        label: "创建时间",
        prop: "createTime",
        width: 100
      }, {
        label: "创建人",
        prop: "creator",
        width: 100
      }]
    };
    const travelConfig = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: examineOperations
      },
      column: [{
        label: "产品图片",
        prop: "mainPic",
        type: COLUMN_TYPE.picture,
        width: 100
      }, {
        label: "产品名称",
        prop: "name"
      }, {
        label: "产品编号",
        prop: "flowData",
        width: 100
      }, {
        label: "审核状态",
        prop: "state",
        width: 100
      }, {
        label: "驳回原因",
        prop: "flowRemark",
        width: 100
      }, {
        label: "归属基地",
        prop: "shopName",
        width: 100
      }, {
        label: "更新时间",
        prop: "updateTime",
        width: 100
      }, {
        label: "更新人",
        prop: "updateBy",
        width: 100
      }, {
        label: "创建时间",
        prop: "createTime",
        width: 100
      }, {
        label: "创建人",
        prop: "creator",
        width: 100
      }]
    };
    const hotelConfig = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: examineOperations
      },
      column: [{
        label: "酒店图片",
        prop: "mainPic",
        type: COLUMN_TYPE.picture,
        width: 100
      }, {
        label: "酒店名称",
        prop: "name"
      }, {
        label: "产品编号",
        prop: "flowData",
        width: 100
      }, {
        label: "审核状态",
        prop: "state",
        width: 100
      }, {
        label: "驳回原因",
        prop: "flowRemark",
        width: 100
      }, {
        label: "归属基地",
        prop: "shopName",
        width: 100
      }, {
        label: "更新时间",
        prop: "updateTime",
        width: 100
      }, {
        label: "更新人",
        prop: "updateBy",
        width: 100
      }, {
        label: "创建时间",
        prop: "createTime",
        width: 100
      }, {
        label: "创建人",
        prop: "creator",
        width: 100
      }]
    };
    const depositConfig = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: examineOperations
      },
      column: [{
        label: "用户手机号",
        prop: "memberLoginName"
      }, {
        label: "审批类型",
        prop: "flowTypeVal",
        width: 120
      }, {
        label: "储值类型",
        prop: "changeVal",
        width: 100
      }, {
        label: "金额（元）",
        prop: "changeMoney",
        width: 100
      }, {
        label: "审核状态",
        prop: "state",
        width: 100
      }, {
        label: "更新时间",
        prop: "updateTime",
        width: 100
      }, {
        label: "更新人",
        prop: "updateBy",
        width: 100
      }, {
        label: "创建时间",
        prop: "createTime",
        width: 100
      }, {
        label: "创建人",
        prop: "creator",
        width: 100
      }]
    };
    const pointsConfig = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: examineOperations
      },
      column: [{
        label: "用户手机号",
        prop: "memberLoginName"
      }, {
        label: "积分类型",
        prop: "changeVal",
        width: 100
      }, {
        label: "积分（分）",
        prop: "changeMoney",
        width: 100
      }, {
        label: "审核状态",
        prop: "state",
        width: 100
      }, {
        label: "更新时间",
        prop: "updateTime",
        width: 100
      }, {
        label: "更新人",
        prop: "updateBy",
        width: 100
      }, {
        label: "创建时间",
        prop: "createTime",
        width: 100
      }, {
        label: "创建人",
        prop: "creator",
        width: 100
      }]
    };
    const lineConfig = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: examineOperations
      },
      column: [{
        label: "审批编号",
        prop: "flowCode",
        width: 120
      }, {
        label: "审核状态",
        prop: "flowStatusName",
        width: 120
      }, {
        label: "线路主图",
        type: COLUMN_TYPE.picture,
        prop: "mainPic",
        width: 100
      }, {
        label: "线路名称",
        prop: "lineName"
      }, {
        label: "线路编号",
        prop: "lineCode",
        width: 120
      }, // {
      //   label: "上架状态",
      //   prop: "lineStatusName",
      //   width: 120,
      // },
      {
        label: "更新时间",
        prop: "updateTime",
        width: 160
      }, {
        label: "更新人",
        prop: "updateBy",
        width: 120
      }, {
        label: "创建时间",
        prop: "createTime",
        width: 160
      }, {
        label: "创建人",
        prop: "creator",
        width: 120
      }]
    };
    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      // 查看
      if (props.type === "goods") {
        route.push({
          name: `${props.type}details`,
          query: {
            type: "examine",
            code: data.flowData
          }
        });
      } else if (props.type === "deposit") {
        if (data.memberDepositChangeId) {
          route.push({
            name: `${props.type}details`,
            query: {
              type: "examine",
              code: data.memberDepositChangeId
            }
          });
        } else {
          route.push({
            name: `${props.type}details`,
            query: {
              type: "examine",
              batch: data.flowData
            }
          });
        }
      } else if (props.type === "points") {
        if (data.memberRewardPointsChangeId) {
          route.push({
            name: `${props.type}details`,
            query: {
              type: "examine",
              code: data.memberRewardPointsChangeId
            }
          });
        } else {
          route.push({
            name: `${props.type}details`,
            query: {
              type: "examine",
              batch: data.flowData
            }
          });
        }
      } else if (props.type === "line") {
        route.push({
          name: `${props.type}Details`,
          query: {
            type: "examine",
            lineCode: data.flowData
          }
        });
      } else {
        route.push({
          name: `${props.type}details`,
          query: {
            type: "examine",
            code: data.id
          }
        });
      }
    });
    opHander.install(OP_KEY.modify, data => {
      // 修改
      if (props.type === "goods") {
        route.push({
          name: `${props.type}details`,
          query: {
            type: "modify",
            code: data.flowData
          }
        });
      } else if (props.type === "line") {
        route.push({
          name: `${props.type}Details`,
          query: {
            type: "examine",
            lineCode: data.flowData
          }
        });
      } else {
        route.push({
          name: `${props.type}details`,
          query: {
            type: "modify",
            code: data.id
          }
        });
      }
    });
    opHander.install(OP_KEY.pass, data => {
      // 通过
      $box.open("通过", `确认该条记录审批通过`, () => {
        api.approval(data.flowCode, "1007004", "", () => {
          $msg.succ("审批成功");
          emit("getData");
        });
      });
    });
    opHander.install(OP_KEY.refusal, data => {
      // 驳回
      $box.input("确认驳回", "驳回原因", null, val => {
        api.approval(data.flowCode, "1007003", val, () => {
          $msg.succ("驳回成功");
          emit("getData");
        });
      });
    });
    const changeShopRef = ref(null);
    let selectOpData = null;
    opHander.install(OP_KEY.changeShop, data => {
      // 变更所属基地
      selectOpData = data;
      changeShopRef.value.show();
    });

    function changeShop(data) {
      api.updateShop(props.type, selectOpData.flowCode, selectOpData.flowData, data.base, () => {
        $msg.succ("变更成功");
        emit("getData");
      });
    }

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", null, [_createVNode(DataCollectTable, {
        ref: "examineTableRef",
        data: __props.data,
        total: __props.total,
        config: getConfig(),
        onOperationsHander: _unref(opHander).receive
      }, null, 8, ["data", "total", "config", "onOperationsHander"]), _createVNode(ChangeShop, {
        ref_key: "changeShopRef",
        ref: changeShopRef,
        onChangeShop: changeShop
      }, null, 512)]);
    };
  }

};