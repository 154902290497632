import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "traffic_manager"
};
const _hoisted_2 = {
  class: "base_screen_header"
};
const _hoisted_3 = {
  class: "dialog-footer"
};
import { $msg, $box } from "@ui/msg";
import DataCollectTable from '@com/table/DataCollectTable.vue';
import { OP_KEY, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import { ref, reactive } from 'vue';
import trafficData from "@/test/trafficData.json"; // const trafficData = reactive()

export default {
  __name: 'TrafficManager',

  setup(__props) {
    function goodsOperations(data) {
      return [OPERATIONS.modify, OPERATIONS.del];
    }

    const config = {
      showIndex: true,
      operations: {
        label: '操作',
        width: 120,
        operations: goodsOperations
      },
      column: [{
        label: '交通方式名称',
        prop: 'name'
      }, {
        label: '范围',
        prop: 'scope'
      }]
    };

    function addTraffic() {
      dialogTitle.value = '新增';
      dialogVisible.value = true;
    }

    const opHander = operationsHander();
    opHander.install(OP_KEY.modify, data => {
      dialogTitle.value = '修改';
      trafficForm.name = data.name;
      trafficForm.scope = data.scope;
      dialogVisible.value = true;
    });
    opHander.install(OP_KEY.delete, data => {
      $box.open("删除确认", `是否删除${data.name}`, () => {
        $msg.succ("删除成功");
      });
    });
    const dialogVisible = ref(false);
    const dialogTitle = ref('新增');
    const trafficFormRef = ref('');
    const trafficForm = reactive({
      name: '',
      scope: []
    });
    const trafficRules = {
      name: [{
        required: true,
        message: '请输入交通方式名称',
        trigger: 'blur'
      }],
      scope: [{
        required: true,
        type: 'array',
        message: '请选择范围',
        trigger: 'change'
      }]
    };

    const submitForm = async formEl => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          console.log(trafficForm);
          console.log('submit!');
        } else {
          console.log('error submit!', fields);
        }
      });
    };

    const resetForm = formEl => {
      if (!formEl) return;
      formEl.resetFields();
    };

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_main = _resolveComponent("el-main");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_checkbox = _resolveComponent("el-checkbox");

      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
          onClick: addTraffic,
          type: "primary"
        }, {
          default: _withCtx(() => [_createTextVNode("新增交通方式")]),
          _: 1
        })]), _createVNode(DataCollectTable, {
          data: _unref(trafficData),
          config: config,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "onOperationsHander"])]),
        _: 1
      }), _createVNode(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => dialogVisible.value = $event),
        title: dialogTitle.value,
        width: "35%",
        top: "35vh",
        class: "base_dialog"
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_3, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[2] || (_cache[2] = $event => submitForm(trafficFormRef.value))
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: _cache[3] || (_cache[3] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "trafficFormRef",
          ref: trafficFormRef,
          model: trafficForm,
          rules: trafficRules,
          "label-width": "120px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "交通方式名称",
            prop: "name"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: trafficForm.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => trafficForm.name = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "范围",
            prop: "scope"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_checkbox_group, {
              modelValue: trafficForm.scope,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => trafficForm.scope = $event)
            }, {
              default: _withCtx(() => [_createVNode(_component_el_checkbox, {
                label: "去程"
              }), _createVNode(_component_el_checkbox, {
                label: "返程"
              })]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }

};