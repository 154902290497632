import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "role_manager"
};
const _hoisted_2 = {
  class: "base_screen_header"
};
import DataCollectTable from '@com/table/DataCollectTable.vue';
import { OP_KEY, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import { ref } from 'vue';
import { $msg, $box } from "@ui/msg";
import { Search } from '@element-plus/icons-vue';
import useRoute from "@util/router";
import api from "@api/role";
export default {
  __name: 'LogManager',

  setup(__props) {
    const route = useRoute();
    route.watchRouter('logmanager', () => {
      getLogList();
    });
    const name = ref(route.query().name || '');

    function searchName(val) {
      route.replaceQuery('name', val);
    }

    const data = ref([]);
    const total = ref(0);

    function roleOperations(data) {
      // return [OPERATIONS.check]
      return "";
    }

    const config = {
      showIndex: true,
      // operations: {
      //   label: '操作',
      //   width: 120,
      //   operations: roleOperations
      // },
      column: [{
        label: '操作人名称',
        prop: 'operationUsername',
        width: 140
      }, {
        label: '操作类型',
        prop: 'operation',
        width: 140
      }, {
        label: '操作模块',
        prop: 'operationModule',
        width: 140
      }, {
        label: '操作事件',
        prop: 'operationEvents'
      }, {
        label: '操作结果',
        prop: 'operationResult',
        width: 150
      }, {
        label: '操作所在IP',
        prop: 'operationIp',
        width: 120
      }, {
        label: '操作时间',
        prop: 'addTime',
        width: 160
      }]
    };
    const opHander = operationsHander();
    opHander.install(OP_KEY.modify, data => {
      route.push({
        name: 'logdetails',
        query: {
          type: 'check',
          code: data.id
        }
      });
    });
    opHander.install(OP_KEY.delete, data => {
      $box.open("删除确认", `是否删除${data.name}`, () => {
        api.delete(data.id, () => {
          $msg.succ("删除成功");
          getLogList();
        });
      });
    });

    function addRole() {
      route.push({
        name: 'roledetails',
        query: {
          type: 'add'
        }
      });
    }

    getLogList();

    function getLogList() {
      let routeQuery = route.query();
      api.logList({
        name: routeQuery.name,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        total.value = val.total;
        data.value = val.itemList;
      });
    }

    return (_ctx, _cache) => {
      const _component_el_form = _resolveComponent("el-form");

      const _component_el_main = _resolveComponent("el-main");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_form, {
          inline: true,
          class: "base_search_form",
          onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
        })]), _createVNode(DataCollectTable, {
          data: data.value,
          total: total.value,
          config: config,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"])]),
        _: 1
      })]);
    };
  }

};