import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import DataCollectTable from "@com/table/DataCollectTable.vue";
import { OP_KEY, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import { $msg, $box } from "@ui/msg";
import { ref, reactive } from "vue";
import { Search } from "@element-plus/icons-vue";
import useRoute from "@util/router";
import $ from "@util/base";
export default {
  __name: 'InvoiceManager',

  setup(__props) {
    const route = useRoute(); // route.watchRouter("transactiondetail", () => {
    //     getData();
    // });
    // const baseOptions = ref([]);
    // getConfig();
    // function getConfig() {
    //   api.base((val) => {
    //     baseOptions.value = val;
    //   });
    // }

    const deal = ref(route.query().deal || "");
    const order = ref(route.query().order || ""); // const base = ref(route.query().base || "");
    // const number = ref(route.query().number || "");

    const date = ref(route.query().date || []);
    const data = ref([]);
    const total = ref(0);

    function transactionOperations(data) {
      return [OPERATIONS.check];
    }

    const config = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: transactionOperations
      },
      column: [{
        label: "交易号",
        prop: "transactionNo"
      }, {
        label: "金额（元）",
        prop: "transactionMoney",
        width: 150
      }, {
        label: "关联订单",
        prop: "orderCode",
        width: 150
      }, {
        label: "订单类型",
        prop: "mainBusiType",
        width: 90
      }, {
        label: "支付方式",
        prop: "payType",
        width: 100
      }, {
        label: "交易类型",
        prop: "transactionType",
        width: 100
      }, // {
      //   label: "归属基地",
      //   prop: "a7",
      //   width: 100,
      // },
      // {
      //   label: "交易状态",
      //   prop: "a8",
      //   width: 100,
      // },
      {
        label: "交易时间",
        prop: "createTime",
        width: 150
      }, {
        label: "分销员",
        prop: "agentMemberName",
        width: 90
      }]
    };
    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      route.push({
        name: "detailsview",
        query: {
          code: data.transactionNo
        }
      });
    });

    function searchDeal(val) {
      route.replaceQuery("deal", val);
    }

    function searchOrder(val) {
      route.replaceQuery("order", val);
    }

    function searchBase(val) {
      console.log(val);
      route.replaceQuery("base", val);
    }

    function searchNumber(val) {
      console.log(val);
      route.replaceQuery("number", val);
    }

    function searchDate(val) {
      route.replaceQuery("date", val);
    }

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", null, "发票管理");
    };
  }

};