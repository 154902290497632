import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "travel_input"
};
const _hoisted_2 = {
  class: "base_screen_header"
};
const _hoisted_3 = {
  class: "base_main_header"
};
import StatusBar from '@com/StatusBar.vue';
import TravelTable from '@com/travel/TravelTable.vue';
import { ref, reactive } from 'vue';
import { Search } from '@element-plus/icons-vue';
import useRoute from "@util/router";
import CategoryManager from '@com/CategoryManager.vue';
import travelConfigApi from "@api/travelConfig";
import api from "@api/travel";
import $ from "@util/base";
export default {
  __name: 'TravelInput',

  setup(__props) {
    const route = useRoute();
    const categoryConfig = {
      type: 'travel'
    };
    const targetarea = ref(route.query().target || '');
    const category = ref(route.query().c || '');
    const content = ref(route.query().content || '');
    const baseOptions = ref([]);
    const categoryOptions = ref([]);
    const targetareaOptions = ref([]);
    const contentOptions = ref([]);
    getConfig();

    function getConfig() {
      travelConfigApi.base(val => {
        baseOptions.value = val;
      });
      travelConfigApi.category(val => {
        categoryOptions.value = val;
      });
      travelConfigApi.targetarea(val => {
        targetareaOptions.value = val;
      });
      travelConfigApi.content(val => {
        contentOptions.value = val;
      });
    }

    const base = ref(route.query().base || '');
    const name = ref(route.query().name || '');
    const statusList = reactive([{
      label: '全部',
      value: 1,
      total: 0
    }, {
      label: '草稿',
      value: 1007001,
      total: 0
    }, {
      label: '待审批',
      value: 1007002,
      total: 0
    }, {
      label: '已通过',
      value: 1007004,
      total: 0
    }, {
      label: '已驳回',
      value: 1007003,
      total: 0
    }]);

    function statusData(val) {
      statusList.forEach((e, i) => {
        e.total = val[i];
      });
    }

    function searchCategory(val) {
      route.replaceQuery('c', val);
    }

    function searchContent(val) {
      route.replaceQuery('content', val);
    }

    function searchTargetarea(val) {
      route.replaceQuery('target', val);
    }

    function searchBase(val) {
      route.replaceQuery('base', val);
    }

    function searchName(val) {
      route.replaceQuery('name', val);
    }

    function add() {
      route.push({
        name: 'traveldetails',
        query: {
          type: 'add'
        }
      });
    }

    function getExport() {
      let routeQuery = route.query();
      api.travelInputExport({
        categoryCode: routeQuery.c,
        shopCode: routeQuery.base,
        name: routeQuery.name,
        status: routeQuery.status,
        targetArea: routeQuery.target,
        jointype: routeQuery.content
      }, val => {
        $.download(val.url, "旅游产品录入导出");
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_main = _resolveComponent("el-main");

      const _component_el_container = _resolveComponent("el-container");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, null, {
        default: _withCtx(() => [_createVNode(_component_el_main, {
          class: "base_main_content"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            onClick: add,
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode("新增旅游")]),
            _: 1
          })), [[_directive_auth, _ctx.getCurCode('ADD')]]), _createVNode(_component_el_form, {
            inline: true,
            class: "base_search_form",
            onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [_createVNode(_component_el_select, {
                modelValue: category.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => category.value = $event),
                onChange: searchCategory,
                placeholder: "请选择分类",
                clearable: ""
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(categoryOptions.value, (item, index) => {
                  return _openBlock(), _createBlock(_component_el_option, {
                    key: index,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]);
                }), 128))]),
                _: 1
              }, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [_createVNode(_component_el_select, {
                modelValue: content.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => content.value = $event),
                onChange: searchContent,
                placeholder: "请选择内容",
                clearable: ""
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(contentOptions.value, (item, index) => {
                  return _openBlock(), _createBlock(_component_el_option, {
                    key: index,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]);
                }), 128))]),
                _: 1
              }, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [_createVNode(_component_el_select, {
                modelValue: targetarea.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => targetarea.value = $event),
                onChange: searchTargetarea,
                placeholder: "请选择目的地",
                clearable: ""
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(targetareaOptions.value, (item, index) => {
                  return _openBlock(), _createBlock(_component_el_option, {
                    key: index,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]);
                }), 128))]),
                _: 1
              }, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [_createVNode(_component_el_select, {
                modelValue: base.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => base.value = $event),
                onChange: searchBase,
                placeholder: "请选择基地",
                clearable: ""
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(baseOptions.value, (item, index) => {
                  return _openBlock(), _createBlock(_component_el_option, {
                    key: index,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]);
                }), 128))]),
                _: 1
              }, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: name.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => name.value = $event),
                "onKeyup[native]": searchName,
                onChange: searchName,
                placeholder: "产品名称",
                clearable: "",
                "suffix-icon": _unref(Search)
              }, null, 8, ["modelValue", "suffix-icon"])]),
              _: 1
            })]),
            _: 1
          })]), _createVNode(StatusBar, {
            data: statusList,
            default: 1
          }, null, 8, ["data"]), _createElementVNode("div", _hoisted_3, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            text: "",
            onClick: getExport
          }, {
            default: _withCtx(() => [_createTextVNode("导出")]),
            _: 1
          })), [[_directive_auth, _ctx.getCurCode('EXPORT')]])]), _createVNode(TravelTable, {
            type: "input",
            route: "travelinput",
            onStatusData: statusData
          })]),
          _: 1
        })]),
        _: 1
      })]);
    };
  }

};