import { POST } from "@net/service.js";
import $ from "@util/base";
import page from "./page"

const URL = {
    baseList: "shop_list",
    details: "shop_get",
    add: "shop_add",
    update: "shop_update",
    delete: "shop_delete",
};

const api = {
    baseList(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.name)) {
            paramsData.name = params.name
        }
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.baseList,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    details(id, succ) {
        let requestOptions = {
            path: URL.details,
            params: { id: parseInt(id) },
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    add(params, succ) {
        let requestOptions = {
            path: URL.add,
            params: params,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    update(id, params, succ) {
        params.id = parseInt(id)
        let requestOptions = {
            path: URL.update,
            params: params,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    delete(id, succ) {
        let requestOptions = {
            path: URL.delete,
            params: { id: parseInt(id) },
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
};

export default api