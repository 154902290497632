import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "nav_menu"
};
import { computed } from "vue";
import { useRoute } from "vue-router";
export default {
  __name: 'NavMenu',
  props: {
    data: {
      type: Array,
      required: true
    }
  },

  setup(__props) {
    const route = useRoute();
    const props = __props;
    const active = computed(() => {
      if (route.meta.activeSubMenu != null) {
        const multiple = route.meta.activeSubMenu.split(",");

        if (multiple.length > 0) {
          for (const r of multiple) {
            if (props.data.indexOf(r) != -1) {
              return r;
            }
          }

          return multiple[0];
        }
      }

      return route.meta.activeSubMenu;
    });
    return (_ctx, _cache) => {
      const _component_el_menu_item = _resolveComponent("el-menu-item");

      const _component_el_menu_item_group = _resolveComponent("el-menu-item-group");

      const _component_el_sub_menu = _resolveComponent("el-sub-menu");

      const _component_el_menu = _resolveComponent("el-menu");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_menu, {
        "default-active": active.value,
        "default-openeds": [0],
        router: "",
        "text-color": "#000",
        "active-text-color": "#409eff"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.data, (menu, index) => {
          return _openBlock(), _createBlock(_component_el_sub_menu, {
            key: index,
            index: index + ''
          }, {
            title: _withCtx(() => [_createTextVNode(_toDisplayString(menu.title), 1)]),
            default: _withCtx(() => [_createVNode(_component_el_menu_item_group, null, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.itemList, (item, inds) => {
                return _openBlock(), _createBlock(_component_el_menu_item, {
                  key: inds,
                  index: item.route
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(item.title), 1)]),
                  _: 2
                }, 1032, ["index"]);
              }), 128))]),
              _: 2
            }, 1024)]),
            _: 2
          }, 1032, ["index"]);
        }), 128))]),
        _: 1
      }, 8, ["default-active"])]);
    };
  }

};