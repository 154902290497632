import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "stored_value_manager"
};
const _hoisted_2 = {
  class: "base_screen_header"
};
const _hoisted_3 = {
  class: "btn-box"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "tdm_title"
};
const _hoisted_6 = {
  class: "base_main_margin_top"
};
const _hoisted_7 = {
  class: "base_main_header"
};
const _hoisted_8 = {
  class: "dialog-footer"
};
import StatusBar from "@com/StatusBar.vue";
import { $msg, $box } from "@ui/msg";
import { ref, reactive } from "vue";
import { Search } from "@element-plus/icons-vue";
import useRoute from "@util/router";
import DataCollectTable from "@com/table/DataCollectTable.vue";
import { OP_KEY, COLUMN_TYPE, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import $ from "@util/base";
import api from "@api/points";
export default {
  __name: 'PointsManager',

  setup(__props) {
    const route = useRoute();
    route.watchRouter("pointsmanager", () => {
      getData();
    });
    const numList = reactive([// {
    //   label: "储值总额",
    //   total: 0,
    //   sel: false,
    // },
    {
      label: "总积分",
      total: 0,
      sel: "totalNum"
    }, {
      label: "已消费积分",
      total: 0,
      sel: "usedNum"
    }]);
    const statusList = reactive([{
      label: "全部",
      value: "all",
      total: 0
    }, {
      label: "待审批",
      value: "1047002",
      total: 0
    }, {
      label: "已通过",
      value: "1047001",
      total: 0
    }, {
      label: "已驳回",
      value: "1047003",
      total: 0
    }]);
    const data = ref([]);
    const total = ref(0);

    function getOperations(data) {
      if (data.validStatus == "1047003") {
        return [OPERATIONS.check, OPERATIONS.del];
      } else {
        return [OPERATIONS.check];
      }
    }

    const config = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: getOperations
      },
      column: [{
        label: "姓名",
        prop: "memberName",
        width: 120
      }, {
        label: "账号",
        prop: "memberLoginName"
      }, {
        label: "积分余额（分）",
        prop: "balanceMoney",
        width: 130
      }, {
        label: "操作积分（分）",
        prop: "changeMoney",
        width: 140
      }, {
        label: "备注",
        prop: "remark"
      }, {
        label: "操作人",
        prop: "creatorName",
        width: 120
      }, {
        label: "审核状态",
        prop: "validStatusName",
        width: 100
      }, {
        label: "操作时间",
        prop: "createTime",
        width: 180
      }]
    };
    const dialogVisible = ref(false);
    const formRef = ref(null);
    const form = reactive({
      phone: null,
      integral: null,
      changeReason: null,
      remark: null
    });

    function show() {
      dialogVisible.value = true;
    }

    function openMoneyList(val) {
      console.log(val);
    }

    function add() {
      show();
    }

    const rules = {
      phone: [{
        required: true,
        message: "请输入用户手机号",
        trigger: "blur"
      }],
      integral: [{
        required: true,
        message: "请输入积分",
        trigger: "blur"
      }, {
        type: "number",
        message: "请输入数字"
      }],
      changeReason: [{
        required: true,
        message: "请输入说明",
        trigger: "blur"
      }],
      remark: [{
        required: true,
        message: "请输入备注",
        trigger: "blur"
      }]
    };

    const submitForm = formEl => {
      if (!formEl) return;
      formEl.validate((valid, fields) => {
        if (valid) {
          userStoredValue(form);
        }
      });
    };

    function userStoredValue(data) {
      api.add(data, () => {
        $msg.succ("新增成功");
        dialogVisible.value = false;
        getData();
      });
    }

    const resetForm = formEl => {
      if (!formEl) return;
      formEl.resetFields();
    };

    function openImport() {
      route.push({
        name: "batchpointsimport"
      });
    }

    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      route.push({
        name: "pointsdetails",
        query: {
          type: "show",
          code: data.id
        }
      });
    });
    opHander.install(OP_KEY.delete, data => {
      $box.open("删除确认", `是否删除${data.memberLoginName}账号，该笔积分记录`, () => {
        api.delete(data.id, () => {
          $msg.succ("删除成功");
          getData();
        });
      });
    });
    const phone = ref(route.query().phone || "");
    const reason = ref(route.query().reason || "");
    const remark = ref(route.query().remark || "");
    const date = ref(route.query().date || []); // const activeName = ref('deposit');

    function searchPhone(val) {
      route.replaceQuery("phone", val);
    }

    function searchReason(val) {
      route.replaceQuery("reason", val);
    }

    function searchRemark(val) {
      route.replaceQuery("remark", val);
    }

    function searchDate(val) {
      route.replaceQuery("date", val);
    }

    getData();

    function getData() {
      getList();
      getNum();
      getStatusNum();
    }

    function getList() {
      let routeQuery = route.query();
      api.list({
        depositType: routeQuery.type === "freeze" ? "1048002" : "1048001",
        phone: routeQuery.phone,
        changeReason: routeQuery.reason,
        remark: routeQuery.remark,
        date: routeQuery.date,
        status: routeQuery.status,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        total.value = val.total;
        data.value = val.list.map(e => {
          if (e.changeMoney > 0) {
            e.changeMoney = `+${e.changeMoney}`;
          }

          return e;
        });
      });
    }

    function delRefuseData() {
      api.delRefuseData(() => {
        $msg.succ("删除成功");
        getData();
      });
    }

    function exportData() {
      let routeQuery = route.query();
      api.exportData({
        depositType: routeQuery.type === "freeze" ? "1048002" : "1048001",
        phone: routeQuery.phone,
        changeReason: routeQuery.reason,
        remark: routeQuery.remark,
        date: routeQuery.date,
        status: routeQuery.status,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        $.download(val.url, "积分列表导出");
      });
    }

    function getStatusNum() {
      let routeQuery = route.query();
      api.statusNum({
        phone: routeQuery.phone,
        changeReason: routeQuery.reason,
        remark: routeQuery.remark,
        date: routeQuery.date,
        status: routeQuery.status
      }, val => {
        statusData(val, "status");
      });
    }

    function getNum() {
      api.num(val => {
        statusData(val, "num");
      });
    }

    function statusData(val, type) {
      console.log(val);
      console.log(type);

      if (type == "num") {
        numList.forEach((e, i) => {
          e.total = val[i];
        });
        console.log(numList);
      } else {
        statusList.forEach((e, i) => {
          e.total = val[i];
        });
      }
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_date_picker = _resolveComponent("el-date-picker");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_dialog = _resolveComponent("el-dialog");

      const _component_el_main = _resolveComponent("el-main");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          onClick: add
        }, {
          default: _withCtx(() => [_createTextVNode("单个用户积分")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('ADD')]]), _withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          onClick: openImport
        }, {
          default: _withCtx(() => [_createTextVNode("批量用户积分")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('ADDS')]])]), _createVNode(_component_el_form, {
          inline: true,
          class: "base_search_form",
          onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_date_picker, {
              modelValue: date.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => date.value = $event),
              type: "daterange",
              "range-separator": "~",
              "start-placeholder": "开始时间",
              "end-placeholder": "结束时间",
              "value-format": "YYYY-MM-DD",
              onChange: searchDate
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: phone.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => phone.value = $event),
              "onKeyup[native]": searchPhone,
              onChange: searchPhone,
              placeholder: "手机号",
              clearable: "",
              "suffix-icon": _unref(Search)
            }, null, 8, ["modelValue", "suffix-icon"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: reason.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => reason.value = $event),
              "onKeyup[native]": searchReason,
              onChange: searchReason,
              placeholder: "说明",
              clearable: "",
              "suffix-icon": _unref(Search)
            }, null, 8, ["modelValue", "suffix-icon"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: remark.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => remark.value = $event),
              "onKeyup[native]": searchRemark,
              onChange: searchRemark,
              placeholder: "备注",
              clearable: "",
              "suffix-icon": _unref(Search)
            }, null, 8, ["modelValue", "suffix-icon"])]),
            _: 1
          })]),
          _: 1
        })]), _createVNode(_component_el_row, null, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(numList, (item, index) => {
            return _openBlock(), _createBlock(_component_el_col, {
              key: index,
              span: 12
            }, {
              default: _withCtx(() => [_createElementVNode("div", {
                onClick: $event => openMoneyList(item.sel),
                class: _normalizeClass({
                  td_money_item: true,
                  td_money_item_sel: item.sel
                })
              }, [_createElementVNode("div", _hoisted_5, _toDisplayString(item.label), 1), _createElementVNode("h2", null, _toDisplayString(item.total), 1)], 10, _hoisted_4)]),
              _: 2
            }, 1024);
          }), 128))]),
          _: 1
        }), _createElementVNode("div", _hoisted_6, [_createVNode(StatusBar, {
          data: statusList,
          default: "all"
        }, null, 8, ["data"])]), _createElementVNode("div", _hoisted_7, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          text: "",
          onClick: delRefuseData
        }, {
          default: _withCtx(() => [_createTextVNode("删除未通过记录")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('DELETED')]]), _withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          text: "",
          onClick: exportData
        }, {
          default: _withCtx(() => [_createTextVNode("导出")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('EXPORT')]])]), _createVNode(DataCollectTable, {
          data: data.value,
          config: config,
          total: total.value,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"]), _createVNode(_component_el_dialog, {
          modelValue: dialogVisible.value,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => dialogVisible.value = $event),
          title: "单个用户积分",
          width: "35%",
          top: "20vh",
          class: "base_dialog",
          onOpen: _cache[12] || (_cache[12] = $event => resetForm(formRef.value))
        }, {
          footer: _withCtx(() => [_createElementVNode("span", _hoisted_8, [_createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[9] || (_cache[9] = $event => submitForm(formRef.value))
          }, {
            default: _withCtx(() => [_createTextVNode("提交")]),
            _: 1
          }), _createVNode(_component_el_button, {
            onClick: _cache[10] || (_cache[10] = $event => dialogVisible.value = false)
          }, {
            default: _withCtx(() => [_createTextVNode("取消")]),
            _: 1
          })])]),
          default: _withCtx(() => [_createVNode(_component_el_form, {
            ref_key: "formRef",
            ref: formRef,
            model: form,
            rules: rules,
            "label-width": "100px"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "用户手机号",
              prop: "phone"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: form.phone,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => form.phone = $event),
                placeholder: "请输入用户手机号"
              }, null, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "积分",
              prop: "integral"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: form.integral,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => form.integral = $event),
                modelModifiers: {
                  number: true
                },
                clearable: "",
                placeholder: "请输入储值金额（正数为增加，负数为减少）"
              }, null, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "说明",
              prop: "changeReason"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: form.changeReason,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => form.changeReason = $event),
                type: "textarea",
                clearable: "",
                placeholder: "请输入说明",
                resize: "none"
              }, null, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "备注",
              prop: "remark"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: form.remark,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => form.remark = $event),
                type: "textarea",
                clearable: "",
                placeholder: "请输入备注",
                resize: "none"
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          }, 8, ["model"])]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      })]);
    };
  }

};