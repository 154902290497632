import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: ""
};
const _hoisted_2 = {
  class: "base_screen_header"
};
import { $msg, $box } from "@ui/msg";
import DataCollectTable from "@com/table/DataCollectTable.vue";
import { OP_KEY, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import { ref } from "vue";
import { TYPE, api } from "@api/category";
import useRoute from "@util/router";
export default {
  __name: 'TravelClassifyManager',

  setup(__props) {
    const route = useRoute();
    const total = ref(0);
    const data = ref([]);

    function getOperations() {
      return [OPERATIONS.modify, OPERATIONS.del];
    }

    const tableConfig = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 140,
        operations: getOperations
      },
      column: [{
        label: "产品分类名称",
        prop: "catName"
      }]
    };
    const opHander = operationsHander();
    opHander.install(OP_KEY.modify, data => {
      $box.input("修改", '分类名称', data.catName, val => {
        api.updateCategory(data.id, val, () => {
          getList();
          $msg.succ("修改成功");
        });
      });
    });
    opHander.install(OP_KEY.delete, data => {
      $box.open("删除确认", `是否删除${data.catName}`, () => {
        api.delCategory(data.id, () => {
          getList();
          $msg.succ("删除成功");
        });
      });
    });

    function addCategory(data) {
      $box.input("添加", '分类名称', null, val => {
        api.addCategory(val, TYPE.travel, () => {
          getList();
          $msg.succ("添加成功");
        });
      });
    }

    function getList() {
      let routeQuery = route.query();
      api.getList({
        type: TYPE.travel,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        data.value = val.itemList;
        total.value = val.total;
      });
    }

    getList();
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_main = _resolveComponent("el-main");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
          onClick: addCategory,
          type: "primary"
        }, {
          default: _withCtx(() => [_createTextVNode("新增产品分类")]),
          _: 1
        })]), _createVNode(DataCollectTable, {
          data: data.value,
          total: total.value,
          config: tableConfig,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"])]),
        _: 1
      })]);
    };
  }

};