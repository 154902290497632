// 订单
import GoodsOrder from "@/views/order/GoodsOrder.vue";
import TravelOrder from "@/views/order/TravelOrder.vue";
import HotelOrder from "@/views/order/HotelOrder.vue";
import AfterSaleOrder from "@/views/order/AfterSaleOrder.vue";
import LineOrder from "@/views/order/LineOrder.vue";

import GoodsOrderDetails from "@view/order/GoodsOrderDetails.vue";
import TravelOrderDetails from "@view/order/TravelOrderDetails.vue";
import TravelGroupDetails from "@view/order/TravelGroupDetails.vue";
import HotelOrderDetails from "@view/order/HotelOrderDetails.vue";

export default [{
        path: "/goodsorder",
        name: "goodsorder",
        component: GoodsOrder,
        meta: {
            activeMainMenu: "order",
            activeSubMenu: "/goodsorder",
        },
    },
    {
        path: "/travelorder",
        name: "travelorder",
        component: TravelOrder,
        meta: {
            activeMainMenu: "order",
            activeSubMenu: "/travelorder,/travelgrouporder",
        },
    },
    {
        path: "/travelgrouporder",
        name: "travelgrouporder",
        component: TravelOrder,
        meta: {
            activeMainMenu: "order",
            activeSubMenu: "/travelorder,/travelgrouporder",
        },
    },
    {
        path: "/hotelorder",
        name: "hotelorder",
        component: HotelOrder,
        meta: {
            activeMainMenu: "order",
            activeSubMenu: "/hotelorder",
        },
    },
    {
        path: "/lineorder",
        name: "lineorder",
        component: LineOrder,
        meta: {
            activeMainMenu: "order",
            activeSubMenu: "/lineorder",
        },
    },
    {
        path: "/aftersaleorder",
        name: "aftersaleorder",
        component: AfterSaleOrder,
        meta: {
            activeMainMenu: "order",
            activeSubMenu: "/aftersaleorder",
        },
    },
    {
        path: "/goodsorderdetails",
        name: "goodsorderdetails",
        component: GoodsOrderDetails,
        meta: {
            activeMainMenu: "order",
            activeSubMenu: "/goodsorder",
        },
    },
    {
        path: "/travelorderdetails",
        name: "travelorderdetails",
        component: TravelOrderDetails,
        meta: {
            activeMainMenu: "order",
            activeSubMenu: "/travelgrouporder",
        },
    },
    {
        path: "/travelgroupdetails",
        name: "travelgroupdetails",
        component: TravelGroupDetails,
        meta: {
            activeMainMenu: "order",
            activeSubMenu: "/travelgrouporder",
        },
    },
    {
        path: "/hotelorderdetails",
        name: "hotelorderdetails",
        component: HotelOrderDetails,
        meta: {
            activeMainMenu: "order",
            activeSubMenu: "/hotelorder",
        },
    },
];