import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "travel_price_operate"
};
const _hoisted_2 = {
  class: "operate_layout"
};
const _hoisted_3 = {
  class: "operate_item"
};
const _hoisted_4 = {
  class: "picker"
};
const _hoisted_5 = {
  class: "operate_item"
};
import { ref, reactive, watch } from "vue";
import msg from "@ui/message";
import DynamicParams from "@com/DynamicParams.vue";
export default {
  __name: 'HotelPriceOperate',
  emits: ["execute", "checkOperateChange", "calendarSwitch"],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const emit = __emit;
    const priceTemplateOperation = {
      move: false,
      modify: false,
      del: false,
      add: false,
      check: false
    };
    const calendarSwitch = ref((localStorage.getItem("calendarNumber") || 6) == 6);
    const priceTemplateRef = ref(null);
    const priceTemplate = ref([]);
    const priceTemplateTemplate = {
      stockNum: null,
      costPrice: null,
      competitorPrice: null,
      markingPrice: null,
      sellPrice: null
    };
    const selectWeek = ref([0, 1, 2, 3, 4, 5, 6]);
    const selectOperate = ref("add");
    const selectDate = ref();
    const pickerOptions = [{
      text: "一个月",

      value() {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
        return [end, start];
      }

    }, {
      text: "三个月",

      value() {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() + 3600 * 1000 * 24 * 90);
        return [end, start];
      }

    }, {
      text: "半年",

      value() {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() + 3600 * 1000 * 24 * 182);
        return [end, start];
      }

    }, {
      text: "一年",

      value() {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() + 3600 * 1000 * 24 * 365);
        return [end, start];
      }

    }];

    const disabledDate = date => {
      return date.getTime() < Date.now() - 3600 * 1000 * 24 || date.getTime() > Date.now() + 3600 * 1000 * 24 * 365;
    };

    const weeks = [{
      id: 1,
      name: "周一"
    }, {
      id: 2,
      name: "周二"
    }, {
      id: 3,
      name: "周三"
    }, {
      id: 4,
      name: "周四"
    }, {
      id: 5,
      name: "周五"
    }, {
      id: 6,
      name: "周六"
    }, {
      id: 0,
      name: "周日"
    }];
    const operate = [{
      id: "clear",
      name: "清空"
    }, {
      id: "modify",
      name: "覆盖"
    }, {
      id: "add",
      name: "添加"
    }];
    watch(() => calendarSwitch.value, val => {
      emit("calendarSwitch", val);
    });

    function getOperate() {
      return {
        date: selectDate.value,
        template: priceTemplateRef.value.getValue(),
        week: selectWeek.value,
        operate: selectOperate.value
      };
    }

    function isNotNull(value) {
      if (value != null && value != undefined) {
        if (value.length) {
          return value.length > 0;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    function validate() {
      for (const price of priceTemplateRef.value.getValue()) {
        for (const templateKey in price) {
          if (!isNotNull(price[templateKey])) {
            msg.error("请选择完善价格");
            return false;
          }
        }
      }

      return true;
    }

    function execute() {
      const data = {
        date: selectDate.value,
        template: priceTemplateRef.value.getValue(),
        week: selectWeek.value,
        operate: selectOperate.value
      };

      if (selectOperate.value != "clear") {
        for (const price of data.template) {
          for (const templateKey in price) {
            if (!isNotNull(price[templateKey])) {
              msg.error("请选择完善价格");
              return;
            }
          }
        }

        if (data.date == null) {
          msg.error("请选择时间范围");
          return;
        }

        if (data.week.length <= 0) {
          msg.error("请选择星期");
          return;
        }
      }

      emit("execute", data);
    }

    function addTemplate() {
      emit("checkOperateChange", res => {
        if (res) {
          var data = {};

          for (const key in priceTemplateTemplate) {
            data[key] = priceTemplateTemplate[key];
          }

          priceTemplateRef.value.addData(data);
        } else {
          msg.error("已经生成了日期价格数据，请先清空后再添加");
        }
      });
    }

    function importData(data) {
      priceTemplate.value = data;
    }

    function resetData() {
      priceTemplateRef.value.clear();
    }

    function getData() {
      return priceTemplateRef.value.getValue();
    }

    __expose({
      getOperate,
      importData,
      resetData,
      getData,
      validate
    });

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_input_number = _resolveComponent("el-input-number");

      const _component_el_date_picker = _resolveComponent("el-date-picker");

      const _component_el_radio = _resolveComponent("el-radio");

      const _component_el_radio_group = _resolveComponent("el-radio-group");

      const _component_el_checkbox = _resolveComponent("el-checkbox");

      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");

      const _component_el_switch = _resolveComponent("el-switch");

      const _component_el_button = _resolveComponent("el-button");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(DynamicParams, {
        ref_key: "priceTemplateRef",
        ref: priceTemplateRef,
        template: priceTemplateTemplate,
        data: priceTemplate.value,
        operation: priceTemplateOperation
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "库存",
          align: "center"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_input, {
            modelValue: scope.row.stockNum,
            "onUpdate:modelValue": $event => scope.row.stockNum = $event,
            type: "number",
            onkeyup: "value=value.replace(/^[^\\d]+/g,'')"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "成本价(元)",
          align: "center"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_input_number, {
            modelValue: scope.row.costPrice,
            "onUpdate:modelValue": $event => scope.row.costPrice = $event,
            modelModifiers: {
              number: true
            },
            min: 0,
            precision: 2,
            controls: false,
            "controls-position": "right"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "同行价(元)",
          align: "center"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_input_number, {
            modelValue: scope.row.competitorPrice,
            "onUpdate:modelValue": $event => scope.row.competitorPrice = $event,
            modelModifiers: {
              number: true
            },
            min: 0,
            precision: 2,
            controls: false,
            "controls-position": "right"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "销售价(元)",
          align: "center"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_input_number, {
            modelValue: scope.row.sellPrice,
            "onUpdate:modelValue": $event => scope.row.sellPrice = $event,
            modelModifiers: {
              number: true
            },
            min: 0,
            precision: 2,
            controls: false,
            "controls-position": "right"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "划线价(元)",
          align: "center"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_input_number, {
            modelValue: scope.row.markingPrice,
            "onUpdate:modelValue": $event => scope.row.markingPrice = $event,
            modelModifiers: {
              number: true
            },
            min: 0,
            precision: 2,
            controls: false,
            "controls-position": "right"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_date_picker, {
        modelValue: selectDate.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => selectDate.value = $event),
        type: "daterange",
        "range-separator": "~",
        "start-placeholder": "开始日期",
        "end-placeholder": "结束日期",
        "value-format": "YYYY-MM-DD",
        shortcuts: pickerOptions,
        "disabled-date": disabledDate
      }, null, 8, ["modelValue"])]), _createVNode(_component_el_radio_group, {
        modelValue: selectOperate.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => selectOperate.value = $event)
      }, {
        default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(operate, item => {
          return _createVNode(_component_el_radio, {
            key: item.id,
            label: item.id
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
            _: 2
          }, 1032, ["label"]);
        }), 64))]),
        _: 1
      }, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_checkbox_group, {
        modelValue: selectWeek.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => selectWeek.value = $event)
      }, {
        default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(weeks, item => {
          return _createVNode(_component_el_checkbox, {
            key: item.id,
            label: item.id
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
            _: 2
          }, 1032, ["label"]);
        }), 64))]),
        _: 1
      }, 8, ["modelValue"]), _createElementVNode("div", null, [_createVNode(_component_el_switch, {
        class: "calendar_switch",
        modelValue: calendarSwitch.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => calendarSwitch.value = $event),
        style: {
          "--el-switch-on-color": "#13ce66",
          "--el-switch-off-color": "#409eff"
        },
        "active-text": "6月",
        "inactive-text": "12月"
      }, null, 8, ["modelValue"]), _createVNode(_component_el_button, {
        type: "primary",
        onClick: execute
      }, {
        default: _withCtx(() => [_createTextVNode("批量执行")]),
        _: 1
      })])])])]);
    };
  }

};