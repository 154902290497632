// 会员
import MemberManager from '@/views/member/MemberManager.vue'
import DistributorManager from '@/views/member/DistributorManager.vue'

import MemberDetails from '@/views/member/MemberDetails.vue'
import DistributorDetails from '@/views/member/DistributorDetails.vue'
import OrderDetails from '@/views/member/OrderDetails.vue'
import DepositDetails from '@/views/member/DepositDetails.vue'
import MemberGradeManager from '@/views/member/MemberGradeManager.vue'
import MemberGradePowerManager from '@/views/member/MemberGradePowerManager.vue'
import MemberGradePowerDetails from '@/views/member/MemberGradePowerDetails.vue'
import hotel from './hotel'

export default [{
  path: '/membermanager',
  name: 'membermanager',
  component: MemberManager,
  meta: {
    activeMainMenu: 'member',
    activeSubMenu: '/membermanager'
  }
}, {
  path: '/distributormanager',
  name: 'distributormanager',
  component: DistributorManager,
  meta: {
    activeMainMenu: 'member',
    activeSubMenu: '/distributormanager'
  }
}, {
  path: '/memberdetails',
  name: 'memberdetails',
  component: MemberDetails,
  meta: {
    activeMainMenu: 'member',
    activeSubMenu: '/membermanager'
  }
}, {
  path: '/distributordetails',
  name: 'distributordetails',
  component: DistributorDetails,
  meta: {
    activeMainMenu: 'member',
    activeSubMenu: '/distributormanager'
  }
}, {
  path: '/membergoodsorder',
  name: 'membergoodsorder',
  component: OrderDetails,
  meta: {
    activeMainMenu: 'member',
    activeSubMenu: '/membermanager'
  }
}, {
  path: '/memberhotelorder',
  name: 'memberhotelorder',
  component: OrderDetails,
  meta: {
    activeMainMenu: 'member',
    activeSubMenu: '/membermanager'
  }
}, {
  path: '/membertravelorder',
  name: 'membertravelorder',
  component: OrderDetails,
  meta: {
    activeMainMenu: 'member',
    activeSubMenu: '/membermanager'
  }
},
{
  path: '/deposittabledetails',
  name: 'deposittabledetails',
  component: DepositDetails,
  meta: {
    activeMainMenu: 'member',
    activeSubMenu: '/membermanager'
  }
},
{
  path: '/memberGradeManager',
  name: 'memberGradeManager',
  component: MemberGradeManager,
  meta: {
    activeMainMenu: 'member',
    activeSubMenu: '/depositmanager'
  }
},
{
  path: '/memberGradePowerManager',
  name: 'memberGradePowerManager',
  component: MemberGradePowerManager,
  meta: {
    activeMainMenu: 'member',
    activeSubMenu: '/depositmanager'
  }
},
{
  path: '/memberGradePowerDetails',
  name: 'memberGradePowerDetails',
  component: MemberGradePowerDetails,
  meta: {
    activeMainMenu: 'member',
    activeSubMenu: '/depositmanager'
  }
}
]
