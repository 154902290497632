import { POST } from "@net/service.js";

const URL = {
    details: 'self_delivery_station_get',
    add: 'self_delivery_station_add',
    update: 'self_delivery_station_update'
};

const api = {
    details(id, succ) {
        let requestOptions = {
            path: URL.details,
            params: { id: id },
            isLoading: true,
            succ: succ,
        }
        POST(requestOptions)
    },
    add(params, succ) {
        let requestOptions = {
            path: URL.add,
            params: params,
            isLoading: true,
            succ: succ,
        }
        POST(requestOptions)
    },
    update(params, succ) {
        let requestOptions = {
            path: URL.update,
            params: params,
            isLoading: true,
            succ: succ,
        }
        POST(requestOptions)
    }
}

export default api