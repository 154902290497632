const TOKEN = "token";
const OVERVIEW = "overview";
const TRAVELORDER = "travelOrder";

let token = null;
let overview = {
  isAll: true,
  shopCode: null,
  shopName: null
}
let travelOrder = {
  isCombo: true,
  isMember: true
}

const auth = {
  setToken(value) {
    localStorage.setItem(TOKEN, value);
    token = value;
  },
  removeToken() {
    localStorage.removeItem(TOKEN);
    token = null;
  },
  getToken() {
    if (token == null) {
      token = localStorage.getItem(TOKEN);
    }
    return token;
  },
  setOverView(isAll, code, name) {
    overview.isAll = isAll
    overview.shopCode = code
    overview.shopName = name
    localStorage.setItem(OVERVIEW, JSON.stringify(overview));
  },
  getOverView() {
    if (overview == null) {
      overview = JSON.parse(localStorage.getItem(OVERVIEW));
    }
    return overview;
  },
  setTravelOrder(isCombo, isMember) {
    travelOrder.isCombo = isCombo
    travelOrder.isMember = isMember
    localStorage.setItem(TRAVELORDER, JSON.stringify(travelOrder));
  },
  getTravelOrder() {
    if (travelOrder == null) {
      travelOrder = JSON.parse(localStorage.getItem(TRAVELORDER));
    }
    return travelOrder;
  },
};

export default auth;
