import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import DataCollectTable from "../table/DataCollectTable.vue";
import { OP_KEY, COLUMN_TYPE, OPERATIONS, operationsHander } from "../table/tableOperations";
import AppletsCode from "@com/AppletsCode.vue";
import useRoute from "@util/router";
import { $msg, $box } from "@/common/ui/msg";
import { ref } from "vue";
import api from "@api/travel2";
import examineApi from "@api/examine";
export default {
  __name: 'TravelTable2',
  props: {
    type: {
      type: String
    },
    route: {
      type: String
    }
  },
  emits: ['statusData'],

  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const props = __props;
    const data = ref([]);
    const total = ref(0);
    const route = useRoute();
    route.watchRouter(props.route, () => {
      getData();
    });

    function getOperations(data) {
      if (props.type == 'input') {
        switch (data.flowStatusCode) {
          case "1007001":
            //草稿
            return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.del, OPERATIONS.examine];

          case "1007003":
            //被驳回
            return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.del, OPERATIONS.reject];

          case "1007004":
            //审批通过
            return [OPERATIONS.check];

          default:
            return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.del];
        }
      } else {
        return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.del, OPERATIONS.appletsCode];
      }
    }

    const getConfig = function () {
      if (props.type == 'input') {
        return inputConfig;
      } else {
        return managerConfig;
      }
    };

    const inputConfig = {
      showIndex: true,
      operations: {
        label: '操作',
        width: 120,
        operations: getOperations
      },
      column: [{
        label: '产品图片',
        prop: 'mainPic',
        type: COLUMN_TYPE.picture,
        width: 100
      }, {
        label: '产品名称',
        prop: 'travelName'
      }, {
        label: '产品编号',
        prop: 'travelCode',
        width: 100
      }, {
        label: '审核状态',
        prop: 'flowStatusName',
        width: 100
      }, {
        label: '归属基地',
        prop: 'shopName',
        width: 100
      }, {
        label: '更新时间',
        prop: 'updateTime',
        width: 100
      }, {
        label: '更新人',
        prop: 'updateBy',
        width: 100
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 100
      }, {
        label: '创建人',
        prop: 'creator',
        width: 100
      }]
    };
    const managerConfig = {
      showIndex: true,
      operations: {
        label: '操作',
        width: 120,
        operations: getOperations
      },
      column: [{
        label: '排序',
        prop: 'sortNum',
        type: COLUMN_TYPE.input,
        operationsKey: OPERATIONS.input,
        width: 100
      }, {
        label: '产品图片',
        prop: 'mainPic',
        type: COLUMN_TYPE.picture,
        width: 100
      }, {
        label: '产品名称',
        prop: 'travelName'
      }, {
        label: '产品编号',
        prop: 'travelCode',
        width: 100
      }, {
        label: '上架状态',
        prop: 'travelStatusName',
        type: COLUMN_TYPE.switch,
        operationsKey: OPERATIONS.switch,
        activeText: '已上架',
        inactiveText: '未上架',
        activeValue: '已上架',
        inactiveValue: '未上架',
        switchWidth: 65,
        width: 100
      }, {
        label: '归属基地',
        prop: 'shopName',
        width: 100
      }, {
        label: '更新时间',
        prop: 'updateTime',
        width: 100
      }, {
        label: '更新人',
        prop: 'updateBy',
        width: 100
      }, {
        label: '创建时间',
        prop: 'createTime',
        width: 100
      }, {
        label: '创建人',
        prop: 'creator',
        width: 100
      }]
    };
    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      route.push({
        name: 'traveldetails2',
        query: {
          type: 'show',
          code: data.id
        }
      });
    });
    opHander.install(OP_KEY.modify, data => {
      route.push({
        name: 'traveldetails2',
        query: {
          type: 'modify',
          code: data.id
        }
      });
    });
    opHander.install(OP_KEY.delete, data => {
      $box.open("删除", "确认删除" + data.travelName, () => {
        api.del(data.id, () => {
          $msg.succ("删除成功");
          getData();
        });
      });
    });
    opHander.install(OP_KEY.examine, data => {
      examineApi.examine("travel", data.travelCode, data.shopCode, () => {
        $msg.succ("提交成功");
        getData();
      });
    });
    opHander.install(OP_KEY.reject, data => {
      $box.open("驳回原因", data.rejected);
    });
    opHander.install(OP_KEY.switch, data => {
      // 1004003已上架 1004002未上架
      let state = data.data.travelStatus;
      api.up(data.data.id, state, () => {
        data.resolve(true);

        if (state == '1004002') {
          $msg.succ("上架成功");
        } else {
          $msg.succ("下架成功");
        }

        getData();
      }, () => {
        data.reject();
      });
    });
    opHander.install(OP_KEY.input, data => {
      api.sort(data.data.id, data.value, () => {
        $msg.succ("排序修改成功");
        getData();
      });
    });
    const appletsCodeRef = ref(null);
    opHander.install(OP_KEY.appletsCode, data => {
      appletsCodeRef.value.getUnlimited({
        code: data.travelCode,
        type: "travel"
      });
    });
    getData();

    function getData() {
      if (props.type == 'input') {
        getInputData();
      } else {
        getManagerData();
      }
    }

    function getInputData() {
      let routeQuery = route.query();
      api.travelInput({
        categoryCode: routeQuery.c,
        shopCode: routeQuery.base,
        name: routeQuery.name,
        status: routeQuery.status,
        page: routeQuery.p,
        size: routeQuery.s,
        targetArea: routeQuery.target,
        jointype: routeQuery.content
      }, val => {
        total.value = val.total;
        data.value = val.itemList;
        emit('statusData', [val.allNum, val.draftNum, val.notApprovalNum, val.isPassNum, val.rejectedNum]);
      });
    }

    function getManagerData() {
      let routeQuery = route.query();
      api.travelManager({
        categoryCode: routeQuery.c,
        shopCode: routeQuery.base,
        name: routeQuery.name,
        status: routeQuery.status,
        page: routeQuery.p,
        size: routeQuery.s,
        targetArea: routeQuery.target,
        jointype: routeQuery.content
      }, val => {
        total.value = val.total;
        data.value = val.itemList;
        emit('statusData', [val.allNum, val.leadUpNum, val.leadDownNum]);
      });
    }

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", null, [_createVNode(DataCollectTable, {
        data: data.value,
        total: total.value,
        config: getConfig(),
        onOperationsHander: _unref(opHander).receive
      }, null, 8, ["data", "total", "config", "onOperationsHander"]), _createVNode(AppletsCode, {
        ref_key: "appletsCodeRef",
        ref: appletsCodeRef
      }, null, 512)]);
    };
  }

};