import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "base_screen_header"
};
import StatusBar from "@com/StatusBar.vue";
import MemberTable from '@com/member/MemberTable.vue';
import { ref, reactive } from "vue";
import { Search } from "@element-plus/icons-vue";
import api from "@api/equity";
import $ from "@util/base";
import useRoute from "@util/router";
import DataCollectTable from "@/components/table/DataCollectTable.vue";
import { COLUMN_TYPE, OP_KEY, OPERATIONS, operationsHander } from "@/components/table/tableOperations";
import { $box, $msg } from "@/common/ui/msg";
export default {
  __name: 'MemberGradePowerManager',

  setup(__props) {
    const data = ref([]);
    const total = ref(0);
    const route = useRoute();
    const config = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: getOperations
      },
      column: [{
        label: "权益名称",
        prop: "name",
        width: 100
      }, {
        label: "旅行折扣",
        prop: "discountTravel"
      }, {
        label: "酒店折扣",
        prop: "discountHotel"
      }, {
        label: "商品折扣",
        prop: "discountProduct"
      }, {
        label: "积分翻倍",
        prop: "multiple",
        width: 100
      }, {
        label: "更新时间",
        prop: "updateTime",
        width: 100
      }, {
        label: "更新人",
        prop: "updateBy",
        width: 100
      }, {
        label: "创建时间",
        prop: "createTime",
        width: 100
      }, {
        label: "创建人",
        prop: "creator",
        width: 100
      }]
    };

    function getOperations(data) {
      return [OPERATIONS.check, OPERATIONS.modify, OPERATIONS.del];
    }

    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      route.push({
        name: "memberGradePowerDetails",
        query: {
          type: "show",
          code: data.equityCode
        }
      });
    });
    opHander.install(OP_KEY.modify, data => {
      route.push({
        name: "memberGradePowerDetails",
        query: {
          type: "modify",
          code: data.equityCode
        }
      });
    });
    opHander.install(OP_KEY.delete, data => {
      $box.open("删除", "确认删除\"" + data.name + "\"", () => {
        api.del(data.equityCode, () => {
          $msg.succ("删除成功");
          getData();
        });
      });
    });

    function add() {
      route.push({
        name: 'memberGradePowerDetails',
        query: {
          type: 'add'
        }
      });
    }

    getData();

    function getData() {
      api.list({}, res => {
        data.value = res.equityItemList;
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_main = _resolveComponent("el-main");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          onClick: _cache[0] || (_cache[0] = $event => add())
        }, {
          default: _withCtx(() => [_createTextVNode("新增权益")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('ADD')]])]), _createVNode(DataCollectTable, {
          data: data.value,
          config: config,
          total: total.value,
          "is-page": false,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"])]),
        _: 1
      })]);
    };
  }

};