import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import TravelOrder from "@com/order/TravelOrder.vue"; // import TravelGroupOrder from "@com/order/TravelGroupOrder.vue";

import { ref } from "vue";
import auth from "@net/auth";
import useRoute from "@util/router";
export default {
  __name: 'TravelOrder',

  setup(__props) {
    const permission = auth.getTravelOrder();
    const route = useRoute();
    const activeName = ref(route.name());

    function tabChange(val) {
      route.push({
        name: val
      });
    }

    return (_ctx, _cache) => {
      const _component_el_main = _resolveComponent("el-main");

      return _openBlock(), _createBlock(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createVNode(TravelOrder, {
          type: "order",
          routeName: "travelorder"
        })]),
        _: 1
      });
    };
  }

};